import React, { useState, useEffect, useContext } from 'react';
import * as request from 'request';
import { useHistory } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import memoize from 'memoize-one';
import { FirebaseContext } from 'contexts/Firebase';
import { PageLoader, showToastAlert, dateFormate } from 'Services/Utility';
import Delete from '@mui/icons-material/Delete';
import AddModerator from '@mui/icons-material/AddModerator';
import UpdateIcon from '@mui/icons-material/Update';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Navbar from 'components/Navbar';
import Swal from 'sweetalert2';
import CreatePlanModal from 'Modals/CreatePlanModal';
import UpdatePlanModel from 'Modals/UpdatePlanModel';
import DisplayIpsModal from 'Modals/DisplayIpsModal';


const paymentsColumns = [
    {
        name: 'Token',
        selector: row => row.authToken || '-',
        sortable: true,
    },
    {
        name: 'Email',
        selector: row => row.email || '-',
        sortable: true,
    },
    {
        name: 'Order',
        selector: row => row.order || '-',
        sortable: true,
    },
    {
        name: 'Paid',
        selector: row => row.paidAmount || '',
        sortable: true,
    },
    {
        name: 'Discount Code',
        selector: row => row.code || '',
        sortable: true,
    },
    {
        name: 'Stripe',
        cell: (row) => {
            if (row.paymentId !== 'pi_flipd_extra' && row.paymentId !== 'pi_manual_payment' && row.paymentId !== 'pi_migrated') {
                const href = 'https://dashboard.stripe.com/payments/' + row.paymentId;
                return <a href={href} target="_blank">{row.paymentId}</a>
            }
            return row.paymentId;
        },
        sortable: false,
    },
    {
        name: 'Date',
        selector: row => row.createdAt ? dateFormate(row.createdAt) : '',
        sortable: true,
        sortFunction: (rowA, rowB) => {
            if (rowA.createdAt && rowB.createdAt) {
                if (rowA.createdAt < rowB.createdAt) {
                    return -1;
                }
                else if (rowA.createdAt > rowB.createdAt) {
                    return 1;
                }
            }
            return 0;
        }
    }
];

const proxiesColumns = memoize((displayIpsHandler, updateHandler, deleteHandler) => [
    {
        name: 'Token',
        selector: row => row.authToken || '-',
        sortable: true,
    },
    {
        name: 'Email',
        selector: row => row.email,
        sortable: true,
    },
    {
        name: 'Validity',
        selector: row => parseInt(parseInt(row.validity) / 24) + ' Days',
        sortable: true,
    },
    {
        name: 'Quantity',
        selector: row => row.proxyLen,
        sortable: true,
    },
    {
        name: 'Status',
        selector: row => row.status || '',
        sortable: true,
    },
    {
        name: 'Date',
        selector: row => dateFormate(row.start),
        sortable: true,
        sortFunction: (rowA, rowB) => {
            if (rowA.start < rowB.start) {
                return -1;
            }
            else if (rowA.start > rowB.start) {
                return 1;
            }
            return 0;
        }
    },
    {
        name: 'Action',
        cell: (row) => (<><button className='icon-btn blue' onClick={e => displayIpsHandler(row.proxies, row.username, row.password, row.authToken)}><VisibilityIcon /></button> <button className='icon-btn add' onClick={e => updateHandler(row.authToken, row.validity)}><UpdateIcon /></button> <button className='icon-btn delete' onClick={e => deleteHandler(row.authToken)}><Delete /></button></>),
        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
        sortable: false,
    },
]);


const ISP = (props) => {

    const { user, firebase } = useContext(FirebaseContext);
    const history = useHistory();
    const [filterPaymentText, setFilterPaymentText] = useState('');
    const [filterProxyText, setFilterProxyText] = useState('');
    const [pending, setPending] = useState(true);
    const [loading, setLoading] = useState(true);
    const [basicNetSales, setBasicNetSales] = useState(0);
    const [basicNetDiscounts, setBasicNetDiscounts] = useState(0);
    const [allBasicPayments, setAllBasicPayments] = useState([]);
    const [allBasicProxies, setAllBasicProxies] = useState([]);
    const [resiPlansOptions, setResiPlansOptions] = useState([]);
    const [ispModals, setIspModals] = useState([]);
    const [dcAllocationLeft, setDcAllocationLeft] = useState({});

    const filteredPayments = allBasicPayments.filter(
        item => (item.authToken && item.authToken.toLowerCase().includes(filterPaymentText.toLowerCase())) || (item.email && item.email.toLowerCase().includes(filterPaymentText.toLowerCase())) || (item.paymentId && item.paymentId.toLowerCase().includes(filterPaymentText.toLowerCase())) || (item.code && item.code.toLowerCase().includes(filterPaymentText.toLowerCase()))
    );
    const filteredProxies = allBasicProxies.filter(
        item => (item.authToken && item.authToken.toLowerCase().includes(filterProxyText.toLowerCase())) || (item.email && item.email.toLowerCase().includes(filterProxyText.toLowerCase())) || (item.status && item.status.toLowerCase().includes(filterProxyText.toLowerCase()))
    );

    useEffect(() => {
        document.body.classList.add('dash-bg');
        document.body.classList.add('plan-price-bg');
    }, []);

    const getDCData = (idToken, cache) => {
        return new Promise((resolve, reject) => {
            let options = {
                method: 'POST',
                url: `${process.env.REACT_APP_API_URL}/isp/getPaymentDetails?cache=${cache}`,
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    'idToken': idToken
                })
            };

            return request(options, (error, response, body) => {
                let resData = body && JSON.parse(body);
                if (error || response.statusCode !== 200) {
                    let errorMessage = error ? error : resData ? resData.message : 'A server error occurred while fetching basic plan data!';
                    showToastAlert('Error', errorMessage);
                    resolve({});
                } else {
                    return resolve(resData);
                }
            });
        });
    }

    const getAllocationLeft = (idToken) => {
        return new Promise((resolve, reject) => {
            let options = {
                method: 'POST',
                url: `${process.env.REACT_APP_API_URL}/isp/allocationleft`,
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    'idToken': idToken
                })
            };
            return request(options, (error, response, body) => {
                let resData = body && JSON.parse(body);
                if (error || response.statusCode !== 200) {
                    const errorMessage = error ? error : resData ? resData.message : 'Error while getting inStock proxies allocation for ISP';
                    showToastAlert('Error', errorMessage);
                    resolve({});
                } else {
                    return resolve(resData.data || {});
                }
            });
        });
    }

    const fetchPlans = async () => {
        let plans = (await firebase.db.ref('plans/isp').once('value')).val();
        plans = { ...plans };
        const categories = plans['categories'];
        delete plans['categories'];
        delete plans['active'];
        let planOptions = [];
        for (const planId in plans) {
            const isBulk = plans[planId].bulk ? true : false;
            const isDaily = plans[planId].daily ? true : false;
            let appendType = '';
            if (isBulk && isDaily) appendType = ' - Daily Bulk';
            if (isBulk && !isDaily) appendType = ' - Bulk';
            if (!isBulk && isDaily) appendType = ' - Daily';
            const subPlans = plans[planId]['subPlans'] || {};
            const servername = plans[planId]['servername'];
            const name = plans[planId]['name'];
            Object.keys(subPlans).sort((subP1, subP2) => subPlans[subP1]['numberOfProxies'] - subPlans[subP2]['numberOfProxies']).map((subPlanID) => {
                let detail = subPlans[subPlanID];
                let catID = detail['category'];
                let categoryName = categories[catID]['name'];
                planOptions.push({ 'label': `${name} - ${detail['numberOfProxies']} Proxies - ${servername} - ${categoryName}${appendType}`, 'value': `${subPlanID}:${planId}:${servername}` });
            });
        }
        setResiPlansOptions(planOptions);
    }

    const updatePaymentTable = (payments) => {
        let paymentDetails = [], netSales = 0.0, netDiscounts = 0.0;
        // setUsers(userData || []);
        payments.forEach((item, i) => {
            // let userDetail = userData.find(detail => detail.uid === item['uid']);
            // let email = userDetail ? userDetail['email'] : '-';
            let email = item['userEmail'] || '';
            let authToken = item['authToken'];
            let paymentId = item['paymentToken'];
            let order = `${item['numberOfProxies']} ${item['servername']} - ${parseInt(parseInt(item['validityHours']) / 24)} Days`;
            order = item['renewEntry'] ? order + ' Topup' : order;

            let code = item['couponCode'] ? item['couponCode'] : '-';
            let planPrice = parseFloat(item['planPrice']);
            let paidAmount = planPrice;
            if (item['paid']) {
                paidAmount = parseFloat(item['paid']);
            }

            let discontAmount = parseFloat(planPrice) - parseFloat(paidAmount);
            discontAmount = discontAmount < 0 ? 0 : discontAmount;

            if (paymentId && paymentId !== 'pi_flipd_extra' && paymentId !== 'pi_manual_payment' && paymentId !== 'pi_migrated') {
                netSales = parseFloat(netSales) + paidAmount;
                netDiscounts = parseFloat(netDiscounts) + parseFloat(discontAmount);
            }

            if (paymentId && (paymentId === 'pi_flipd_extra' || paymentId === 'pi_manual_payment')) {
                paidAmount = 0;
            }

            const isBulk = `${item.bulk}` === 'true';
            const isDaily = `${item.daily}` === 'true';
            let appendType = '';
            if (isBulk && isDaily) appendType = ' - Daily Bulk';
            if (isBulk && !isDaily) appendType = ' - Bulk';
            if (!isBulk && isDaily) appendType = ' - Daily';

            let createdAt = new Date(parseInt(item['createdAt']) * 1000);
            paymentDetails.push({
                authToken,
                email: email ? email : '-',
                order: order + appendType,
                paidAmount: paidAmount.toFixed(2),
                code,
                paymentId,
                createdAt
            });
        });
        setAllBasicPayments(paymentDetails);
        setBasicNetSales(parseFloat(netSales).toFixed(2));
        setBasicNetDiscounts(parseFloat(netDiscounts).toFixed(2));
    }

    const updateProxyDetails = (proxies) => {
        let proxyDetails = [];
        proxies.forEach((item, i) => {
            if (item) {
                proxyDetails.push({
                    authToken: item.authtoken,
                    email: item.email ? item.email : '-',
                    proxyLen: item.proxies.length,
                    validity: item.expiryhours,
                    status: item.status,
                    start: new Date(parseInt(item['starttime']) * 1000),
                    proxies: item.proxies ? item.proxies : [],
                    username: item.username,
                    password: item.password
                })
            }
        });
        setAllBasicProxies(proxyDetails);
    }

    const refreshList = async () => {
        const idToken = await firebase.auth.currentUser.getIdToken(true);
        const [dcData, allocationData] = await Promise.all([getDCData(idToken, false), getAllocationLeft(idToken)]);
        updatePaymentTable(dcData.data || []);
        updateProxyDetails(dcData.allProxies || []);
        setDcAllocationLeft(allocationData);
    }

    const removeProxyToken = (idToken, token) => {
        return new Promise((resolve, reject) => {
            let options = {
                method: 'POST',
                url: `${process.env.REACT_APP_API_URL}/isp/deletePlan`,
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    'idToken': idToken,
                    'authToken': token
                })
            };
            return request(options, (error, response, body) => {
                let resData = body && JSON.parse(body);
                if (error || response.statusCode !== 200) {
                    let errorMessage = error ? error : resData ? resData.message : 'Error occurred while delete plan!';
                    reject(errorMessage);
                } else {
                    return resolve(resData || {});
                }
            });
        });
    }

    const displayIpsHandler = (proxies, username, password, authToken) => {
        setIspModals([]);
        setIspModals([
            <DisplayIpsModal
                showModal={true}
                key={Math.random()}
                firebase={firebase}
                ips={proxies}
                username={username}
                password={password}
                authToken={authToken}
                planType={'isp'}
                setLoading={setLoading}
                successCallback={swapIpsCallback}
            />
        ]);
    };

    const swapIpsCallback = (response) => {
        updateProxyDetails(response.allProxies || []);
    };

    const handleDeleteClick = (token) => {
        Swal.fire({
            html: 'Are you sure you want to delete <strong>' + token + '</strong> ?<br /><sub>You will not be able to revert this</sub>',
            icon: 'question',
            color: '#103a8d',
            showCancelButton: true,
            confirmButtonText: 'Delete',
            reverseButtons: true
        }).then(async (result) => {
            if (result.value) {
                try {
                    setLoading(true);
                    const idToken = await firebase.auth.currentUser.getIdToken(true);
                    const response = await removeProxyToken(idToken, token);
                    updatePaymentTable(response.data || []);
                    updateProxyDetails(response.allProxies || []);
                    showToastAlert('success', 'Proxy deleted successfully');
                }
                catch (err) {
                    showToastAlert('Error', err.message ? err.message : err);
                }
                setLoading(false);
            }
        });
    };

    const handleUpdateClick = (token, validity) => {
        setIspModals([]);
        setIspModals([
            <UpdatePlanModel
                showModal={true}
                key={Math.random()}
                firebase={firebase}
                setLoading={setLoading}
                successCallback={planCreateUpdateCallback}
                authToken={token}
                validity={validity}
                provider={'isp'}
                planType={'isp'}
            />
        ]);
    };

    const handleAddProxyClick = () => {
        if (Object.keys(dcAllocationLeft).length > 0) {
            setIspModals([]);
            setIspModals([
                <CreatePlanModal
                    showModal={true}
                    key={Math.random()}
                    firebase={firebase}
                    setLoading={setLoading}
                    successCallback={planCreateUpdateCallback}
                    resiPlansOptions={resiPlansOptions}
                    provider={'isp'}
                    planType={'isp'}
                    dcAllocationLeft={dcAllocationLeft}
                />
            ]);
        }
    };

    const planCreateUpdateCallback = (response) => {
        updatePaymentTable(response.data || []);
        updateProxyDetails(response.allProxies || []);
    }

    useEffect(() => {
        (async function () {
            firebase.auth.onAuthStateChanged(async (user) => {
                try {
                    if (!user) {
                        history.push('/login');
                        return;
                    }
                    setLoading(false);
                    fetchPlans();

                    const idToken = await firebase.auth.currentUser.getIdToken(true);
                    const [dcData, allocationData] = await Promise.all([getDCData(idToken, true), getAllocationLeft(idToken)]);

                    updatePaymentTable(dcData.data || []);
                    updateProxyDetails(dcData.allProxies || []);
                    setDcAllocationLeft(allocationData);

                    setPending(false);

                } catch (error) {
                    console.log(error);
                    showToastAlert('Error', error.message ? error.message : error);
                    setPending(false);
                    setLoading(false);
                }
            });
        })();
    }, [history, firebase.auth]);

    return (
        <>
            {loading && <PageLoader />}
            <div id="page-content-wrapper" className="extra-p">
                <Navbar toggleSideMenu={props.toggleSideMenu} refreshList={refreshList} />

                <div className="container">
                    <div className='saleDiv mb-4'>
                        <div className='mt-0 mb-2'>
                            <div className="dash-box d-flex" style={{ flexDirection: 'column' }}>
                                <h5 className="my-0 text-left w-100" style={{ 'color': 'var(--main-blue)' }}>Net Sales</h5>
                                <h3 className="mt-2 mb-1 text-center w-100 font-weight-bold" style={{ 'color': '#52D987' }}>$ {basicNetSales}</h3>
                            </div>
                        </div>
                        <div className='mt-0 mb-2'>
                            <div className="dash-box d-flex" style={{ flexDirection: 'column' }}>
                                <h5 className="my-0 text-left w-100" style={{ 'color': 'var(--main-blue)' }}>Net Discounts</h5>
                                <h3 className="mt-2 mb-1 text-center w-100 font-weight-bold" style={{ 'color': '#FF7170' }}>$ {basicNetDiscounts}</h3>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-12 mb-2'>
                            <div className="dash-box position-relative">
                                <div className="row" style={{ 'paddingTop': '30px' }}>
                                    <div className="col-12 dash-head my-0">
                                        <div className='d-flex' style={{ 'gap': '20px' }}>
                                            <h4 className="my-0" style={{ 'color': 'var(--main-blue)' }}>ISP Orders</h4>
                                        </div>
                                        <div className="search-box"> <input className="search" type="text" placeholder='Search Record...' value={filterPaymentText} onChange={e => setFilterPaymentText(e.target.value)} /><img src={require("assets/images/search.svg")} alt="" /></div>
                                    </div>
                                    <div className="col-12 mt-3">
                                        <div className="table-responsive">
                                            <DataTable columns={paymentsColumns} data={filteredPayments} defaultSortFieldId={7} defaultSortAsc={false} persistTableHead pagination paginationRowsPerPageOptions={[10, 20, 50, 100]} paginationComponentOptions={{ rowsPerPageText: 'Show entries' }} progressPending={pending} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-12'>
                            <div className="dash-box position-relative">
                                <div className="row" style={{ 'paddingTop': '30px' }}>
                                    <div className="col-12 dash-head my-0">
                                        <div className='d-flex' style={{ 'gap': '20px' }}>
                                            <h4 className="my-0" style={{ 'color': 'var(--main-blue)' }}>ISP Proxies</h4>
                                            <button className='icon-btn add' onClick={e => handleAddProxyClick()}><AddModerator /></button>
                                        </div>
                                        <div className="search-box"> <input className="search" type="text" placeholder='Search Record...' value={filterProxyText} onChange={e => setFilterProxyText(e.target.value)} /><img src={require("assets/images/search.svg")} alt="" /></div>
                                    </div>
                                    <div className="col-12 mt-3">
                                        <div className="table-responsive">
                                            <DataTable columns={proxiesColumns(displayIpsHandler, handleUpdateClick, handleDeleteClick)} data={filteredProxies} defaultSortFieldId={6} defaultSortAsc={false} persistTableHead pagination paginationRowsPerPageOptions={[10, 20, 50, 100]} paginationComponentOptions={{ rowsPerPageText: 'Show entries' }} progressPending={pending} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {ispModals.map(modal => (modal))}
        </>
    )
}

export default ISP;