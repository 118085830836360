import React, { Component } from 'react';
import { Modal } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import * as request from 'request';
import { showToastAlert } from '../Services/Utility';


class UpdatePlanModel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: props.showModal || false,
            authToken: props.authToken,
            dataLimit: props.dataLimit,
            validity: props.validity
        };
        this.planType = props.planType;
        this.closeModal = this.closeModal.bind(this);
    }

    closeModal = () => {
        this.setState({
            showModal: false
        });
    }

    onChange = (e) => {
        e.persist();
        this.setState({
            [e.target.id]: e.target.value
        });
    }

    cancelClicked = async (event) => {
        this.closeModal();
    }

    saveClicked = async (event) => {
        event.preventDefault();

        if (this.planType === 'residential') {
            if (this.state.dataLimit === '') {
                return showToastAlert('Error', 'Please provide data limit');
            }
        }
        if (this.state.validity === '') {
            return showToastAlert('Error', 'Please provide validity hours');
        }

        const validityHours = parseInt(this.state.validity);
        const idToken = await this.props.firebase.auth.currentUser.getIdToken(true);

        let data = {
            idToken: idToken,
            validityHours: validityHours,
            authToken: this.state.authToken
        }
        if (this.planType === 'residential') {
            const dataLimitMB = parseInt(this.state.dataLimit);
            data['dataLimitMB'] = dataLimitMB;
        }

        this.props.setLoading(true);
        const planRes = await this.updatePlan(data, this.props.provider);
        this.props.setLoading(false);
        if (planRes !== null) {
            this.props.successCallback(planRes);
            showToastAlert('success', 'Plan updated successfully');
            this.closeModal();
        }
    }

    updatePlan = async (data, plantype) => {
        return new Promise((resolve, reject) => {
            let options = {
                method: 'POST',
                url: `${process.env.REACT_APP_API_URL}/${plantype}/updatePlan`,
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            };
            return request(options, (error, response, body) => {
                let resData = body && JSON.parse(body);
                if (error || response.statusCode !== 200) {
                    let errorMessage = error ? error : resData ? resData.message : 'Error occurred while updating plan!';
                    showToastAlert('Error', errorMessage);
                    return resolve(null);
                } else {
                    return resolve(resData || {});
                }
            });
        });
    }

    render() {
        const { showModal } = this.state;
        return (
            <Modal
                isOpen={showModal}
                toggle={this.closeModal}
                onClosed={this.closeModal}
                className=""
                size="md"
                backdrop="static"
                centered
            >
                <div className="modal-body">
                    <div className="pop-bg-img">
                        <img src={require("assets/images/pop-bg.svg")} alt="" />
                    </div>
                    <button
                        type="button"
                        className="btn-close pop-close-btn"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        onClick={this.closeModal}
                    />
                    <div className="dash-head">
                        <img src={require("assets/images/purchase-head.svg")} alt="" /> <h4 className="mb-0">Update Plan</h4>
                    </div>

                    <div className="form-field px-2">
                        <div className="form-group row mb-3 d-flex align-items-center">
                            <label htmlFor="authToken" className="col-sm-5 col-form-label py-0">Token</label>
                            <div className="col-sm-7">
                                <input type="text" className="form-control" id="authToken" value={this.state.authToken} readOnly disabled />
                            </div>
                        </div>
                        {
                            this.planType === 'residential' &&
                            <div className="form-group row mb-3 d-flex align-items-center">
                                <label htmlFor="dataLimit" className="col-sm-5 col-form-label py-0">Data Limit (MB) <span className='text-danger'>*</span></label>
                                <div className="col-sm-7">
                                    <input type="number" min={1} className="form-control" id="dataLimit" onChange={this.onChange} value={this.state.dataLimit} placeholder='Enter data limit' />
                                </div>
                            </div>
                        }
                        <div className="form-group row mb-2 d-flex align-items-center">
                            <label htmlFor="validity" className="col-sm-5 col-form-label py-0">{this.planType !== 'residential' ? 'Add Validity Hours' : 'Validity Hours'} <span className='text-danger'>*</span></label>
                            <div className="col-sm-7">
                                <input type="number" min={1} className="form-control" id="validity" onChange={this.onChange} value={this.state.validity} placeholder='Enter hour' />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12 text-end">
                                <div className="d-inline-block mt-4 me-2">
                                    <a href="#" className="mb-3 px-5 cta-white" onClick={this.cancelClicked}>Cancel</a>
                                </div>
                                <div className="form-cta-btn d-inline-block mt-4">
                                    <a href="#" className="mb-3 px-5 text-light" style={{ 'boxShadow': '0px 6px 8px 3px #2c3d7926' }} onClick={this.saveClicked}>Update</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </Modal>
        );
    }
}

export default withRouter(UpdatePlanModel);