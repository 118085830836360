import React, { useContext } from 'react';
import { FirebaseContext } from '../contexts';
import Refresh from '@mui/icons-material/Refresh';


const Navbar = (props) => {

    const { user, firebase } = useContext(FirebaseContext);

    return (
        <div className="top-nav-part top-nav">
            <div>
                <button id="menu-toggle" className="navbar-toggler shadow-none border-0" type="button" data-toggle="collapse"
                    data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false"
                    aria-label="Toggle navigation" onClick={() => props.toggleSideMenu(null)}>
                    <svg width="26" height="20" viewBox="0 0 26 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect x="0.462402" y="0.582031" width="31.1333" height="3.63241" rx="1.8162" fill="#359BEC" />
                        <rect x="0.462402" y="9.19141" width="31.1333" height="3.63241" rx="1.8162" fill="#359BEC" />
                        <rect x="0.462402" y="17.8027" width="31.1333" height="3.63241" rx="1.8162" fill="#359BEC" />
                    </svg>
                </button>
            </div>
            <div className="top-nav-part mb-0">
                {
                    props.refreshList &&
                    <button style={{ border: 'none', backgroundColor: 'transparent' }} onClick={() => props.refreshList()}>
                        <Refresh />
                    </button>
                }
                <div className="profile">
                    {
                        user && user.displayName &&
                        <span>{user.displayName}</span>
                    }
                </div>
            </div>
        </div>
    )
}

export default Navbar;