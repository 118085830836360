import React, { Component } from 'react';
import { Modal } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import DateTime from 'react-datetime';
import Select from 'react-select';
import * as request from 'request';
import { showToastAlert } from '../Services/Utility';
import moment from 'moment';
import 'react-datetime/css/react-datetime.css';

const yesterday = moment().subtract(1, 'day');

class CreateCouponModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: props.showModal || false,
            code: '',
            discount: '',
            quantity: '',
            expiry: moment().endOf('month'),
            appliedOn: [],
            durationInMonths: 1,
            duration: {}
        };
        this.products = props.products;
        this.durationOptions = ['Once', 'Forever', 'Repeating'].map(x => ({ 'label': x, 'value': x.toLowerCase() }));
        this.closeModal = this.closeModal.bind(this);
    }


    isYesterday = (current) => {
        return current.isAfter(yesterday);
    };

    closeModal = () => {
        this.setState({
            showModal: false
        });
    }

    onChange = (e) => {
        e.persist();
        this.setState({
            [e.target.id]: e.target.value
        });
    }

    onExpiryChange = (e) => {
        this.setState({
            ['expiry']: e
        });
    }

    handleAppliedOnChange = (selectedOption) => {
        this.setState({ ['appliedOn']: selectedOption || [] })
    };
    handleDurationOnChange = (selectedOption) => {
        this.setState({ ['duration']: selectedOption || {} })
    };

    cancelClicked = async (event) => {
        this.closeModal();
    }

    saveClicked = async (event) => {

        if (this.state.code.trim() === '') {
            return showToastAlert('Error', 'Unique code is required');
        }
        if (this.state.discount.trim() === '') {
            return showToastAlert('Error', 'Valid discount is required');
        }
        if (this.state.quantity.trim() === '') {
            return showToastAlert('Error', 'Valid quantity is required');
        }
        if (!this.state.duration.value) {
            return showToastAlert('Error', 'Please select duration');
        }

        const appliedOn = this.state.appliedOn.map(x => x.value);
        const duration = this.state.duration.value || '';
        const idToken = await this.props.firebase.auth.currentUser.getIdToken(true);
        const createdBy = await this.props.firebase.auth.currentUser.email;

        if (duration === 'repeating' && parseInt(this.state.durationInMonths) <= 0) {
            return showToastAlert('Error', 'Duration in month should be minimum 1');
        }

        const data = {
            couponDiscount: parseInt(this.state.discount.trim()),
            couponCode: this.state.code.trim(),
            couponType: 'productType',
            couponExpiry: this.state.expiry !== '' ? this.state.expiry.unix() * 1000 : '',
            idToken: idToken,
            createdBy: createdBy,
            couponQuantity: parseInt(this.state.quantity.trim()),
            couponAppliedOn: appliedOn,
            duration: duration,
            duration_in_months: parseInt(this.state.durationInMonths)
        }

        this.props.setLoading(true);
        const couponData = await this.createCoupon(data);
        this.props.setLoading(false);

        if (couponData !== null) {
            this.props.successCallback(couponData);
            showToastAlert('success', 'Coupon created successfully');
            this.closeModal();
        }
    }

    createCoupon = async (data) => {
        return new Promise((resolve, reject) => {
            let options = {
                method: 'POST',
                url: `${process.env.REACT_APP_API_URL}/coupon/create`,
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            };
            return request(options, (error, response, body) => {
                let resData = body && JSON.parse(body);
                if (error || response.statusCode !== 200) {
                    let errorMessage = error ? error : resData ? resData.message : 'Error occurred while creating coupon!';
                    showToastAlert('Error', errorMessage);
                    return resolve(null);
                } else {
                    return resolve(resData.data.data || []);
                }
            });
        });
    }

    render() {
        const { showModal, duration } = this.state;
        return (
            <Modal
                isOpen={showModal}
                toggle={this.closeModal}
                onClosed={this.closeModal}
                className=""
                size="md"
                backdrop="static"
                centered
            >
                <div className="modal-body">
                    <div className="pop-bg-img">
                        <img src={require("assets/images/pop-bg.svg")} alt="" />
                    </div>
                    <button
                        type="button"
                        className="btn-close pop-close-btn"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        onClick={this.closeModal}
                    />
                    <div className="dash-head">
                        <img src={require("assets/images/proxy-gen.svg")} alt="" /> <h4 className="mb-0">Create Coupon</h4>
                    </div>

                    <div className="form-field px-2">
                        <div className="form-group row mb-3 d-flex align-items-center">
                            <label htmlFor="code" className="col-sm-5 col-form-label py-0">Unique Code <span className='text-danger'>*</span></label>
                            <div className="col-sm-7">
                                <input type="text" className="form-control" id="code" onChange={this.onChange} value={this.state.code} placeholder='Enter code' />
                            </div>
                        </div>
                        <div className="form-group row mb-3 d-flex align-items-center">
                            <label htmlFor="discount" className="col-sm-5 col-form-label py-0">Coupon Discount (%) <span className='text-danger'>*</span></label>
                            <div className="col-sm-7">
                                <input type="number" max={100} min={0} className="form-control" id="discount" onChange={this.onChange} value={this.state.discount} placeholder='Enter discount' />
                            </div>
                        </div>
                        <div className="form-group row mb-3 d-flex align-items-center">
                            <label htmlFor="quantity" className="col-sm-5 col-form-label py-0">Coupon Quantity <span className='text-danger'>*</span></label>
                            <div className="col-sm-7">
                                <input type="number" min={1} className="form-control" id="quantity" onChange={this.onChange} value={this.state.quantity} placeholder='Enter quantity' />
                            </div>
                        </div>
                        <div className="form-group row mb-2 d-flex align-items-center">
                            <label htmlFor="duration" className="col-sm-5 col-form-label py-0">Duration</label>
                            <div className="col-sm-7">
                                <Select className="select" id="duration" options={this.durationOptions} onChange={this.handleDurationOnChange} placeholder="Select duration" />
                            </div>
                        </div>
                        {
                            duration.value && duration.value === 'repeating' &&
                            <div className="form-group row mb-3 d-flex align-items-center">
                                <label htmlFor="durationInMonths" className="col-sm-5 col-form-label py-0">Duration in Months <span className='text-danger'>*</span></label>
                                <div className="col-sm-7">
                                    <input type="number" min={1} className="form-control" id="durationInMonths" onChange={this.onChange} value={this.state.durationInMonths} placeholder='Enter duration in months' />
                                </div>
                            </div>
                        }
                        <div className="form-group row mb-3 d-flex align-items-center">
                            <label htmlFor="expiry" className="col-sm-5 col-form-label py-0">Coupon Expiry <span className='text-danger'>*</span></label>
                            <DateTime className="col-sm-7" name="expiry" onChange={this.onExpiryChange} value={this.state.expiry} isValidDate={this.isYesterday} />
                        </div>
                        <div className="form-group row mb-2 d-flex align-items-center">
                            <label htmlFor="applied" className="col-sm-5 col-form-label py-0">Applied On</label>
                            <div className="col-sm-7">
                                <Select className="select" id="applied" options={this.products} isMulti onChange={this.handleAppliedOnChange} placeholder="Select product" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12 text-end">
                                <div className="d-inline-block mt-4 me-2">
                                    <a href="#" className="mb-3 px-5 cta-white" onClick={this.cancelClicked}>Cancel</a>
                                </div>
                                <div className="form-cta-btn d-inline-block mt-4">
                                    <a href="#" className="mb-3 px-5 text-light" style={{ 'boxShadow': '0px 6px 8px 3px #2c3d7926' }} onClick={this.saveClicked}>Create</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </Modal>
        );
    }
}

export default withRouter(CreateCouponModal);