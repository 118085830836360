import React, { Component } from 'react';
import { Modal } from 'reactstrap';
import { withRouter } from 'react-router-dom';

class DisplayAccountsModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: props.showModal || false,
            accounts: props.accounts || [],
        };
        this.closeModal = this.closeModal.bind(this);
    }

    closeModal = () => {
        this.setState({
            showModal: false
        });
    }

    cancelClicked = async (event) => {
        this.closeModal();
    }

    render() {
        const { showModal, accounts } = this.state;
        return (
            <Modal
                isOpen={showModal}
                toggle={this.closeModal}
                onClosed={this.closeModal}
                className=""
                size="lg"
                backdrop="static"
                centered
            >
                <div className="modal-body">
                    <div className="pop-bg-img">
                        <img src={require("assets/images/pop-bg.svg")} alt="" />
                    </div>
                    <button
                        type="button"
                        className="btn-close pop-close-btn"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        onClick={this.closeModal}
                    />
                    <div className="dash-head mb-3">
                        <img src={require("assets/images/purchase-head.svg")} alt="" /> <h4 className="mb-0">Accounts</h4>
                    </div>

                    <div className={"form-field px-2"}>
                        <div className='col-12 mb-3 mt-1'>
                            <textarea type="text" rows={10} className="form-control" value={accounts.join('\r\n')} ></textarea>
                        </div>
                        <div className="row">
                            <div className="col-sm-12 text-end">
                                <div className="d-inline-block mt-4 mb-3 me-2 ">
                                    <a href="#" className="mb-3 px-5 cta-white" onClick={this.cancelClicked}>Cancel</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </Modal>
        );
    }
}

export default withRouter(DisplayAccountsModal);