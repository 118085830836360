import React, { useState } from "react";
import moment from "moment";
import DateTime from "react-datetime";
import Select from "react-select";

import { showToastAlert } from '../Services/Utility';

const PeriodTypes = {
  TODAY: "Today",
  LAST_7_DAYS: "Last 7 Days",
  CURRENT_MONTH: "Current Month",
  LAST_MONTH: "Last Month",
  THIS_YEAR: "This Year",
  LAST_YEAR: "Last Year",
}

const DateRangeFilter = ({onSubmit}) => {
    const [customFilter, setCustomFilter] = useState(undefined);
    const [period, setPeriod] = useState();

    const durationOptions = Object.keys(PeriodTypes).map(key => ({ 'label': PeriodTypes[key], 'value': key }));

    const handleDurationOnChange = (selectedOption) => {
        switch (selectedOption.value) {
            case "TODAY":
                setCustomFilter({ from: moment().utc().startOf('day').format("X"), to: moment().utc().endOf('day').format("X") });
            break;
            case "CURRENT_MONTH":
                setCustomFilter({ from: moment().utc().startOf('month').format("X"), to: moment().utc().endOf('month').format("X") });
            break;
            case "LAST_7_DAYS":
                setCustomFilter({ from: moment().utc().subtract(7, 'days').startOf('day').format("X"), to: moment().utc().endOf('day').format("X") });
            break;
            case "LAST_MONTH":
                setCustomFilter({ from: moment().utc().subtract(1, 'month').startOf('month').format("X"), to: moment().utc().subtract(1, 'month').endOf('month').format("X") });
            break;
            case "THIS_YEAR":
                setCustomFilter({ from: moment().utc().startOf('year').format("X"), to: moment().utc().endOf('year').format("X") });
            break;
            case "LAST_YEAR":
                setCustomFilter({ from: moment().utc().subtract(1, 'year').startOf('year').format("X"), to: moment().utc().subtract(1, 'year').endOf('year').format("X") });
            break;
            default:
            break;
        }
        setPeriod(selectedOption);
    };

    const handleClick = (e) => { 
        e.preventDefault();

        if (!(customFilter && customFilter.from && customFilter.to)) {
            return showToastAlert('Error', 'From and To dates are required');
        }

        if (customFilter.from > customFilter.to) {
            return showToastAlert('Error', 'From date cannot be greater than To date');
        }

        onSubmit(customFilter);
    };

    return (
        <div className="dash-box d-flex" style={{ flexDirection: "row" }}>
            <div className="form-field">
                <div className="form-row">
                    <div className="col-md-4 mb-3">
                        <label className="w-100 col-form-label pt-0 pb-1" htmlFor="duration">Duration</label>
                        <Select
                            className="select"
                            id="duration"
                            name="duration"
                            placeholder="Select period"
                            options={durationOptions} 
                            value={period}
                            onChange={handleDurationOnChange} 
                        />
                    </div>
                    <div className="col-md-4 mb-3">
                        <label className="w-100 col-form-label pt-0 pb-1" htmlFor="dateFrom">From</label>
                        <DateTime 
                            id="dateFrom" 
                            name="dateFrom" 
                            value={customFilter?.from ? new Date(customFilter?.from * 1000) : undefined} 
                            onChange={(e) => {
                                setCustomFilter({ ...customFilter, from: e.format("X") });
                                setPeriod('');
                            }} 
                            utc
                        />
                    </div>
                    <div className="col-md-4 mb-3">
                        <label className="w-100 col-form-label pt-0 pb-1" htmlFor="dateTo">To</label>
                        <DateTime 
                            id="dateTo" 
                            name="dateTo" 
                            value={customFilter?.to ? new Date(customFilter?.to * 1000) : undefined} 
                            onChange={(e) => {
                                setCustomFilter({ ...customFilter, to: e.format("X") });
                                setPeriod('');
                            }} 
                            utc 
                        />
                    </div>
                    <div className="col-md-4 mb-3">
                        <div className="form-cta-btn">
                            <a href="#" className="px-4" onClick={handleClick}>
                                Get Report
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DateRangeFilter;
