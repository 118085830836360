import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Switch, withRouter, Redirect } from "react-router-dom";
import { Container } from 'reactstrap';

import Header from 'components/Header';
import Login from 'views/Login';
import Administrator from 'views/Administrator';
import Users from 'views/Users';
import Basic from 'views/Basic';
import Pro from 'views/Pro';
import Premium from 'views/Premium';
import Datacenter from 'views/Datacenter';
import ISP from 'views/ISP';
import Coupon from 'views/Coupon';
import Plans from 'views/Plans';
import Reports from 'views/Reports';
import Analytics from 'views/Analytics';
import PoolServers from 'views/PoolServers';
import CaptchaProxies from 'views/Captcha';
import Stats from 'views/Stats';
import Gmail from 'views/Gmail';
import Nike from 'views/Nike';
import Server from 'views/Server';


const Main = withRouter(({ location }) => {

  const [isOpen, setIsOpen] = useState(false);

  const toggleSideMenu = (open) => {
    if (open === null) {
      setIsOpen(!isOpen);
    }
    else {
      setIsOpen(open);
    }
  }

  return (
    <>
      <div className={`d-flex ${isOpen ? 'toggled' : ''}`} id="wrapper" >
        {
          location.pathname !== '/login' &&
          <Header toggleSideMenu={toggleSideMenu} />
        }
        <Container fluid className="p-0" id='mainContainer'>
          <main>
            <Switch>
              <Route
                exact
                path="/"
                render={props => <Administrator {...props} toggleSideMenu={toggleSideMenu} />}
              />
              <Route
                path="/login"
                render={props => <Login {...props} toggleSideMenu={toggleSideMenu} />}
              />
              <Route
                path="/users"
                render={props => <Users {...props} toggleSideMenu={toggleSideMenu} />}
              />
              <Route
                path="/basic"
                render={props => <Basic {...props} toggleSideMenu={toggleSideMenu} />}
              />
              <Route
                path="/pro"
                render={props => <Pro {...props} toggleSideMenu={toggleSideMenu} />}
              />
              <Route
                path="/premium"
                render={props => <Premium {...props} toggleSideMenu={toggleSideMenu} />}
              />
              <Route
                path="/datacenter"
                render={props => <Datacenter {...props} toggleSideMenu={toggleSideMenu} />}
              />
              <Route
                path="/isp"
                render={props => <ISP {...props} toggleSideMenu={toggleSideMenu} />}
              />
              <Route
                path="/gmail"
                render={props => <Gmail {...props} toggleSideMenu={toggleSideMenu} />}
              />
              <Route
                path="/nike"
                render={props => <Nike {...props} toggleSideMenu={toggleSideMenu} />}
              />
              <Route
                path="/server"
                render={props => <Server {...props} toggleSideMenu={toggleSideMenu} />}
              />
              <Route
                path="/captcha"
                render={props => <CaptchaProxies {...props} toggleSideMenu={toggleSideMenu} />}
              />
              <Route
                path="/stats"
                render={props => <Stats {...props} toggleSideMenu={toggleSideMenu} />}
              />
              <Route
                path="/coupon"
                render={props => <Coupon {...props} toggleSideMenu={toggleSideMenu} />}
              />
              <Route
                path="/plans"
                render={props => <Plans {...props} toggleSideMenu={toggleSideMenu} />}
              />
              <Route
                path="/reports"
                render={props => <Reports {...props} toggleSideMenu={toggleSideMenu} />}
              />
              {/* <Route
                path="/analytics"
                render={props => <Analytics {...props} toggleSideMenu={toggleSideMenu} />}
              /> */}
              <Route
                path="/pools"
                render={props => <PoolServers {...props} toggleSideMenu={toggleSideMenu} />}
              />

              <Redirect to='/404' />
            </Switch>
          </main>
        </Container>
      </div>
    </>
  )
});

const App = () => {
  return (
    <Router>
      <Main />
    </Router>
  )
}

export default App;