import React from 'react';
import ReactDOM from 'react-dom';

// Styles
// import 'bootstrap/dist/css/bootstrap.css';
import "toastr/build/toastr.min.css";
import 'assets/css/material-dashboard.css'
import "assets/css/style.css";

import App from 'App';
import { FirebaseProvider } from 'contexts';
import { DOM } from 'Services/Utility';
new DOM().init();


ReactDOM.render(
  <FirebaseProvider>
    <App />
  </FirebaseProvider>,
  document.getElementById('root')
);
