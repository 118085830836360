import React, { useMemo } from 'react';
import { round } from 'lodash';

import ReportTable from './ReportTable';

const ReportProduct = ({product, prices, reports}) => {
    let totalPerPlan = {
        grossSalesCount: 0,
        grossSalesTotal: 0,
        refundedCount: 0,
        refundedTotal: 0,
        disputedCount: 0,
        disputedTotal: 0,
        discountedTotal: 0,
        stripeFeesTotal: 0,
        netSalesTotal: 0
    };

    const renderPrices = useMemo(() => {
        return prices.filter((p) => p.product === product.id).map((price) => 
            {
                const priceReport = reports[price.id];
                if (priceReport){
                    totalPerPlan.grossSalesCount += priceReport.grossSalesCount;
                    totalPerPlan.grossSalesTotal += priceReport.grossSalesTotal;
                    totalPerPlan.refundedCount += priceReport.refundedCount;
                    totalPerPlan.refundedTotal += priceReport.refundedTotal;
                    totalPerPlan.disputedCount += priceReport.disputedCount;
                    totalPerPlan.disputedTotal += priceReport.disputedTotal;
                    totalPerPlan.discountedTotal += priceReport.discountedTotal;
                    totalPerPlan.stripeFeesTotal += priceReport.stripeFeesTotal;
                    totalPerPlan.netSalesTotal += priceReport.netSalesTotal;
                }

                return (
                    <ReportTable
                        key={price.id}
                        title={(price.nickname?.match(/:/g)?.length || 0) >= 2 ? price.nickname.split(":")[2] : price.nickname}
                        col="3"
                        data={priceReport ? [
                            { description: "Gross Sales", count: priceReport.grossSalesCount, total: priceReport.grossSalesTotal },
                            { description: "Refunded", count: priceReport.refundedCount, total: priceReport.refundedTotal },
                            { description: "Disputed", count: priceReport.disputedCount, total: priceReport.disputedTotal },
                            { description: "Discounted", total: priceReport.discountedTotal },
                            { description: "Stripe Fees", total: priceReport.stripeFeesTotal },
                            { description: "Total Net Sales", total: priceReport.netSalesTotal },
                        ] : undefined}
                    />
                )
            }
        );
    }, [product, prices, reports]);

    return (
        <>
            <div className="row">
                <div className="col-12 dash-head my-0">
                    <div className='d-flex' style={{ 'gap': '20px' }}>
                        <h3 className="my-0">
                            {product.name}
                        </h3>
                    </div>
                </div>
                {renderPrices}
            </div>
            <div className="row mt-2">
                <ReportTable title="Plan Total Summary" col="auto" data={[
                    { description: "Gross Sales", count: totalPerPlan.grossSalesCount, total: round(totalPerPlan.grossSalesTotal, 2) },
                    { description: "Refunded", count: totalPerPlan.refundedCount, total: round(totalPerPlan.refundedTotal, 2) },
                    { description: "Disputed", count: totalPerPlan.disputedCount, total: round(totalPerPlan.disputedTotal, 2) },
                    { description: "Discounted", total: round(totalPerPlan.discountedTotal, 2) },
                    { description: "Stripe Fees", total: round(totalPerPlan.stripeFeesTotal, 2) },
                    { description: "Total Net Sales", total: round(totalPerPlan.netSalesTotal, 2) },
                ]} />
            </div>
        </>
    )
}

export default ReportProduct;