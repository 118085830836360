import React, { useState, useEffect, useContext } from 'react';
import * as request from 'request';
import { useHistory } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import memoize from 'memoize-one';
import { FirebaseContext } from 'contexts/Firebase';
import { PageLoader, showToastAlert, dateFormate } from 'Services/Utility';
import Delete from '@mui/icons-material/Delete';
import AddShoppingCart from '@mui/icons-material/AddShoppingCart';
import Swal from 'sweetalert2';
import Navbar from 'components/Navbar';
import CreateCouponModal from 'Modals/CreateCouponModal';


const columns = memoize((deleteHandler) => [
    {
        name: 'Created By',
        selector: row => row.created_by || '',
        sortable: true,
    },
    {
        name: 'Applied On',
        selector: row => row.appliedOn || '',
        sortable: true,
    },
    {
        name: 'Code',
        selector: row => row.id || '',
        sortable: true,
    },
    {
        name: 'Discount',
        selector: row => row.percent_off || '',
        sortable: true,
    },
    {
        name: 'Available',
        selector: row => row.quantity || '',
        sortable: true,
    },
    {
        name: 'Used',
        selector: row => row.used || '',
        sortable: true,
    },
    {
        name: 'Type',
        selector: row => row.duration || '',
        sortable: true,
    },
    {
        name: 'Expiry',
        selector: row => row.expiryTime || '',
        sortable: true,
    },
    {
        name: 'Action',
        cell: (row) => <button className='icon-btn delete' onClick={e => deleteHandler(row.id)}><Delete /></button>,
        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
        sortable: false,
    },
]);


const Coupon = (props) => {
    const { user, firebase } = useContext(FirebaseContext);
    const history = useHistory();
    const [filterText, setFilterText] = useState('');
    const [coupons, setCoupons] = useState([]);
    const [products, setProducts] = useState([]);
    const [pending, setPending] = useState(true);
    const [loading, setLoading] = useState(true);
    const [createCouponModal, setCreateCouponModals] = useState([]);

    const filteredItems = coupons.filter(
        item => (item.created_by && item.created_by.toLowerCase().includes(filterText.toLowerCase())) || (item.id && item.id.toLowerCase().includes(filterText.toLowerCase())) || (item.appliedOn && item.appliedOn.toLowerCase().includes(filterText.toLowerCase()))
    );

    useEffect(() => {
        document.body.classList.add('dash-bg');
        document.body.classList.add('plan-price-bg');
    }, []);

    const getCoupon = (idToken, cache) => {
        return new Promise((resolve, reject) => {
            let options = {
                method: 'POST',
                url: `${process.env.REACT_APP_API_URL}/coupon/getCoupons?cache=${cache}`,
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    'idToken': idToken
                })
            };

            return request(options, (error, response, body) => {
                let resData = body && JSON.parse(body);
                if (error || response.statusCode !== 200) {
                    let errorMessage = error ? error : resData ? resData.message : 'A server error occurred while fetching coupon!';
                    showToastAlert('Error', errorMessage);
                    resolve([]);
                } else {
                    return resolve(resData.data.data || []);
                }
            });
        });
    }

    const getProduct = (idToken, cache) => {
        return new Promise((resolve, reject) => {
            let options = {
                method: 'POST',
                url: `${process.env.REACT_APP_API_URL}/coupon/getProducts?cache=${cache}`,
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    'idToken': idToken
                })
            };

            return request(options, (error, response, body) => {
                let resData = body && JSON.parse(body);
                if (error || response.statusCode !== 200) {
                    let errorMessage = error ? error : resData ? resData.message : 'A server error occurred while fetching coupon!';
                    showToastAlert('Error', errorMessage);
                    resolve([]);
                } else {
                    return resolve(resData.data.data || []);
                }
            });
        });
    }

    const removeCoupon = (idToken, code) => {
        return new Promise((resolve, reject) => {
            let options = {
                method: 'POST',
                url: `${process.env.REACT_APP_API_URL}/coupon/delete`,
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    'idToken': idToken,
                    'couponCode': code
                })
            };
            return request(options, (error, response, body) => {
                let resData = body && JSON.parse(body);
                if (error || response.statusCode !== 200) {
                    let errorMessage = error ? error : resData ? resData.message : 'Error occurred while delete coupon!';
                    reject(errorMessage);
                } else {
                    return resolve(resData.data.data || []);
                }
            });
        });
    }

    const refreshList = async () => {
        const idToken = await firebase.auth.currentUser.getIdToken(true);
        const [couponData, productData] = await Promise.all([getCoupon(idToken, false), getProduct(idToken, false)]);
        updateCoupons(couponData, productData);
    }

    const updateCoupons = (couponsArr, productsArr) => {
        let couponRow = [];
        setProducts(productsArr);

        if (coupons) {
            couponsArr.forEach(code => {
                let quantity = code['max_redemptions'] ? code['max_redemptions'] : -1;
                let used = code['times_redeemed'] ? code['times_redeemed'] : 0;
                let expiryTime = code['redeem_by'] ? dateFormate(code['redeem_by'] * 1000) : 'No Expiry';
                let appliedOn = [];
                if (code['applies_to'] && code['applies_to']['products']) {
                    productsArr.forEach(product => {
                        if (code['applies_to']['products'].indexOf(product.id) >= 0) {
                            appliedOn.push(product.name);
                        }
                    })
                }
                appliedOn = appliedOn.join(', ');
                couponRow.push({
                    created_by: code['metadata']['created_by'] ? code['metadata']['created_by'] : '-',
                    appliedOn,
                    id: code['id'],
                    percent_off: code['percent_off'],
                    quantity,
                    used,
                    duration: code['duration'],
                    expiryTime
                });
            });
        }
        setCoupons(couponRow);
    }

    const handleDeleteClick = (code) => {
        Swal.fire({
            html: 'Are you sure you want to delete <strong>' + code + '</strong> ?',
            icon: 'question',
            color: '#103a8d',
            showCancelButton: true,
            confirmButtonText: 'Delete',
            reverseButtons: true
        }).then(async (result) => {
            if (result.value) {
                try {
                    setLoading(true);
                    const idToken = await firebase.auth.currentUser.getIdToken(true);
                    let couponData = await removeCoupon(idToken, code);
                    updateCoupons(couponData, products);
                    showToastAlert('success', 'Coupon deleted successfully');
                }
                catch (err) {
                    showToastAlert('Error', err.message ? err.message : err);
                }
                setLoading(false);
            }
        });
    };

    const couponCreateCallback = (coupondata) => {
        updateCoupons(coupondata, products);
    }

    const handleAddCouponClick = () => {
        const productArr = products.map(x => ({ 'label': x.name, 'value': x.id }));
        setCreateCouponModals([]);
        setCreateCouponModals([
            <CreateCouponModal
                showModal={true}
                key={Math.random()}
                products={productArr}
                firebase={firebase}
                setLoading={setLoading}
                successCallback={couponCreateCallback}
            />
        ]);
    };

    useEffect(() => {
        (async function () {
            firebase.auth.onAuthStateChanged(async (user) => {
                try {
                    if (!user) {
                        history.push('/login');
                        return;
                    }
                    setLoading(false);
                    const idToken = await firebase.auth.currentUser.getIdToken(true);
                    const [couponData, productData] = await Promise.all([getCoupon(idToken, true), getProduct(idToken, true)]);
                    updateCoupons(couponData, productData);
                    setPending(false);
                } catch (error) {
                    console.log(error);
                    showToastAlert('Error', error.message);
                    setPending(false);
                    setLoading(false);
                }
            });
        })();
    }, [history, firebase.auth]);

    return (
        <>
            {loading && <PageLoader />}

            <div id="page-content-wrapper" className="extra-p">
                <Navbar toggleSideMenu={props.toggleSideMenu} refreshList={refreshList} />

                <div className="container">
                    <div className="row">
                        <div className="col-12 dash-head my-0">
                            <div className='d-flex' style={{ 'gap': '20px' }}>
                                <h3 className="my-0">Coupons</h3>
                                <button className='icon-btn add' onClick={e => handleAddCouponClick()}><AddShoppingCart /></button>
                            </div>
                            <div className="search-box"> <input className="search" type="text" placeholder='Search coupon...' value={filterText} onChange={e => setFilterText(e.target.value)} /><img src={require("assets/images/search.svg")} alt="" /></div>
                        </div>
                        <div className="col-12 mt-3">
                            <div className="dash-box position-relative mt-2">
                                <div className="table-responsive">
                                    <DataTable columns={columns(handleDeleteClick)} data={filteredItems} persistTableHead pagination paginationRowsPerPageOptions={[10, 20, 50, 100]} paginationComponentOptions={{ rowsPerPageText: 'Show entries' }} progressPending={pending} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            {createCouponModal.map(couponModal => (couponModal))}
        </>
    )
}

export default Coupon;