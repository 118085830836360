import React, { useState, useEffect, useContext } from 'react';
import * as request from 'request';
import { useHistory } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import memoize from 'memoize-one';
import { FirebaseContext } from 'contexts/Firebase';
import { PageLoader, showToastAlert } from 'Services/Utility';
import Delete from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/PersonAddAlt1';
import Swal from 'sweetalert2';
import Navbar from 'components/Navbar';
import { Chart, TimeScale, LinearScale, BarElement, Legend, Tooltip } from 'chart.js'
import 'chartjs-adapter-date-fns';
import { Bar } from 'react-chartjs-2';
import zoomPlugin from 'chartjs-plugin-zoom';

// const allPayments = require('./payments.json');

Chart.register(TimeScale, LinearScale, BarElement, Legend, Tooltip, zoomPlugin);

const options = {
    responsive: true,
    scales: {
        x: {
            type: 'time',
            time: {
                unit: 'day'
            },
            ticks: {
                autoSkip: true,
                maxTicksLimit: 10
            },
            grid: {
                drawBorder: true,
            },
        },
        y: {
            beginAtZero: true,
            ticks: {
                autoSkip: true,
                maxTicksLimit: 10
            },
            grid: {
                drawBorder: true,
            },
        }
    },
    plugins: {
        zoom: {
            // zoom: {
            //     pinch: {
            //         enabled: true
            //     },
            //     wheel: {
            //         enabled: true
            //     },
            //     mode: 'x'
            // },
            pan: {
                enabled: true,
                mode: 'x'
            }
        }
    }
}

const Analytics = (props) => {
    const { user, firebase } = useContext(FirebaseContext);
    const history = useHistory();
    const [filterText, setFilterText] = useState('');
    const [users, setUsers] = useState([]);
    const [pending, setPending] = useState(true);
    const [loading, setLoading] = useState(true);

    const [basicDatas, setBasicDatas] = useState([]);
    const [proDatas, setProDatas] = useState([]);
    const [premiumDatas, setPremiumDatas] = useState([]);
    const [ispDatas, setIspDatas] = useState([]);
    const [dcDatas, setDcDatas] = useState([]);

    const [netSaledata, setNetSaledata] = useState({ datasets: [] });

    useEffect(() => {
        document.body.classList.add('dash-bg');
        document.body.classList.add('plan-price-bg');
    }, []);

    const getAllPaymentsAPI = (idToken, cache) => {
        return new Promise((resolve, reject) => {
            let options = {
                method: 'POST',
                url: `${process.env.REACT_APP_API_URL}/analytic/getAllPayments?cache=${cache}`,
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    'idToken': idToken
                })
            };
            return request(options, (error, response, body) => {
                let resData = body && JSON.parse(body);
                if (error || response.statusCode !== 200) {
                    let errorMessage = error ? error : resData ? resData.message : 'A server error occurred while fetching payments!';
                    showToastAlert('Error', errorMessage);
                    resolve({});
                } else {
                    return resolve(resData.data || {});
                }
            });
        });
    }

    const calculateSales = (arr, planType) => {
        let netSales = 0, discountSales = 0, netDict = {}, discountDict = {};
        arr.forEach(x => {
            let paid = parseFloat(x.planPrice || 0);
            if (x.paid) {
                paid = parseFloat(x.paid);
            }
            netSales += paid;
            let discount = parseFloat(x.planPrice) - paid;
            discount = discount < 0 ? 0 : discount;
            discountSales += discount;

            // const createdAt = parseInt(x.createdAt) * 1000;
            const createdAt = new Date(parseInt(x.createdAt) * 1000);
            const dateLbl = createdAt.toISOString().split('T')[0];

            if (!netDict[dateLbl]) netDict[dateLbl] = [];
            netDict[dateLbl].push(paid);

            if (!discountDict[dateLbl]) discountDict[dateLbl] = [];
            discountDict[dateLbl].push(discount);
        })
        return { netSales, discountSales, netDict, discountDict };
    }

    useEffect(() => {
        (async function () {
            firebase.auth.onAuthStateChanged(async (user) => {
                try {
                    if (!user) {
                        history.push('/login');
                        return;
                    }
                    setLoading(false);
                    // const idToken = await firebase.auth.currentUser.getIdToken(true);
                    // const allPayments = await getAllPaymentsAPI(idToken, false);

                    // let basicDatas = [], proDatas = [], premiumDatas = [], ispDatas = [], dcDatas = [];

                    // for (const userId in allPayments) {
                    //     for (const timestamp in allPayments[userId]) {
                    //         let detail = allPayments[userId][timestamp];
                    //         if (detail.paymentToken && detail.paymentToken !== 'pi_flipd_extra' && detail.paymentToken !== 'pi_manual_payment' && detail.paymentToken !== 'pi_migrated') {
                    //             if (detail['renew']) {
                    //                 const renewPayments = detail['renew'];
                    //                 for (const renewTime in renewPayments) {
                    //                     const renewDetail = renewPayments[renewTime];
                    //                     const dict = { userId: userId, createdAt: renewTime, parent: timestamp, ...renewDetail };
                    //                     if (renewDetail.planType === 'residential') {
                    //                         if (renewDetail.provider === 'basic') {
                    //                             basicDatas.push(dict);
                    //                         }
                    //                         else if (renewDetail.provider === 'pro') {
                    //                             proDatas.push(dict);
                    //                         }
                    //                         else if (renewDetail.provider === 'premium') {
                    //                             premiumDatas.push(dict);
                    //                         }
                    //                     }
                    //                     else if (renewDetail.planType === 'isp') {
                    //                         ispDatas.push(dict);
                    //                     }
                    //                     else if (renewDetail.planType === 'datacenter') {
                    //                         dcDatas.push(dict);
                    //                     }
                    //                 }
                    //             }
                    //             delete detail['renew'];
                    //             const dict = { userId: userId, createdAt: timestamp, ...detail };
                    //             if (detail.planType === 'residential') {
                    //                 if (detail.provider === 'basic') {
                    //                     basicDatas.push(dict);
                    //                 }
                    //                 else if (detail.provider === 'pro') {
                    //                     proDatas.push(dict);
                    //                 }
                    //                 else if (detail.provider === 'premium') {
                    //                     premiumDatas.push(dict);
                    //                 }
                    //             }
                    //             else if (detail.planType === 'isp') {
                    //                 ispDatas.push(dict);
                    //             }
                    //             else if (detail.planType === 'datacenter') {
                    //                 dcDatas.push(dict);
                    //             }
                    //         }
                    //     }
                    // }

                    // const basicSales = calculateSales(basicDatas, 'basic');
                    // const netDictBasic = Object.keys(basicSales.netDict).sort().map(dt => {
                    //     return { x: dt, y: basicSales.netDict[dt].reduce((acc, a) => acc + a, 0) };
                    // })

                    // const proSales = calculateSales(proDatas, 'pro');
                    // const netDictPro = Object.keys(proSales.netDict).sort().map(dt => {
                    //     return { x: dt, y: proSales.netDict[dt].reduce((acc, a) => acc + a, 0) };
                    // })

                    // const premiumSales = calculateSales(premiumDatas, 'premium');
                    // const netDictPremium = Object.keys(premiumSales.netDict).sort().map(dt => {
                    //     return { x: dt, y: premiumSales.netDict[dt].reduce((acc, a) => acc + a, 0) };
                    // })

                    // const ispSales = calculateSales(ispDatas, 'isp');
                    // const netDictIsp = Object.keys(ispSales.netDict).sort().map(dt => {
                    //     return { x: dt, y: ispSales.netDict[dt].reduce((acc, a) => acc + a, 0) };
                    // })

                    // const dcSales = calculateSales(dcDatas, 'dc');
                    // const netDictDC = Object.keys(dcSales.netDict).sort().map(dt => {
                    //     return { x: dt, y: dcSales.netDict[dt].reduce((acc, a) => acc + a, 0) };
                    // })

                    // console.log(netDict);

                    // const data = {
                    //     datasets: [
                    //         {
                    //             label: 'Basic',
                    //             data: netDictBasic.slice(-10),
                    //             backgroundColor: 'red'
                    //         },
                    //         {
                    //             label: 'Pro',
                    //             data: netDictPro.slice(-10),
                    //             backgroundColor: 'green'
                    //         },
                    //         {
                    //             label: 'Premium',
                    //             data: netDictPremium.slice(-10),
                    //             backgroundColor: 'blue'
                    //         }
                    //     ]
                    // }
                    // setNetSaledata(data);

                    // let data = {
                    //     datasets: [
                    //         {
                    //             label: 'Baic',
                    //             data: [

                    //             ]
                    //         }
                    //     ]
                    // }
                    // setBasicDatas(basicDatas);
                    // setProDatas(proDatas);
                    // setPremiumDatas(premiumDatas);
                    // setIspDatas(ispDatas);
                    // setDcDatas(dcDatas);

                    setPending(false);
                } catch (error) {
                    console.log(error);
                    showToastAlert('Error', error.message);
                    setPending(false);
                    setLoading(false);
                }
            });
        })();
    }, [history, firebase.auth]);

    return (
        <>
            {loading && <PageLoader />}

            <div id="page-content-wrapper" className="extra-p">
                {/* <Navbar toggleSideMenu={props.toggleSideMenu} refreshList={refreshList} /> */}

                <div className="container">
                    <div className='row'>
                        <div className='col-6'>
                            <Bar options={options} data={netSaledata} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Analytics;
