import React, { useState, useEffect, useContext } from 'react';
import * as request from 'request';
import { useHistory } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import memoize from 'memoize-one';
import { FirebaseContext } from 'contexts/Firebase';
import { PageLoader, showToastAlert } from 'Services/Utility';
import Delete from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/PersonAddAlt1';
import Swal from 'sweetalert2';
import Navbar from 'components/Navbar';


const columns = memoize(deleteHandler => [
    {
        name: 'User Id',
        selector: row => row.id || '-',
        sortable: true,
    },
    {
        name: 'Email',
        selector: row => row.email || '-',
        sortable: true,
    },
    {
        name: 'Action',
        cell: (row) => <button className='icon-btn delete' onClick={e => deleteHandler(row.id, row.email)}><Delete /></button>,
        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
        sortable: false,
    },
]);


const Administrator = (props) => {
    const { user, firebase } = useContext(FirebaseContext);
    const history = useHistory();
    const [filterText, setFilterText] = useState('');
    const [users, setUsers] = useState([]);
    const [pending, setPending] = useState(true);
    const [loading, setLoading] = useState(true);

    const filteredItems = users.filter(
        item => (item.id && item.id.toLowerCase().includes(filterText.toLowerCase())) || (item.email && item.email.toLowerCase().includes(filterText.toLowerCase()))
    );

    useEffect(() => {
        document.body.classList.add('dash-bg');
        document.body.classList.add('plan-price-bg');
    }, []);

    const getAdmins = (idToken) => {
        return new Promise((resolve, reject) => {
            let options = {
                method: 'POST',
                url: `${process.env.REACT_APP_API_URL}/admin/getAdmins`,
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    'idToken': idToken
                })
            };

            return request(options, (error, response, body) => {
                let resData = body && JSON.parse(body);
                if (error || response.statusCode !== 200) {
                    let errorMessage = error ? error : resData ? resData.message : 'A server error occurred while fetching server!';
                    showToastAlert('Error', errorMessage);
                    resolve({});
                } else {
                    return resolve(resData.data || {});
                }
            });
        });
    }

    const removeAdmin = (idToken, uid) => {
        return new Promise((resolve, reject) => {
            let options = {
                method: 'POST',
                url: `${process.env.REACT_APP_API_URL}/admin/removeAdmin`,
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    'idToken': idToken,
                    'uid': uid
                })
            };
            return request(options, (error, response, body) => {
                let resData = body && JSON.parse(body);
                if (error || response.statusCode !== 200) {
                    let errorMessage = error ? error : resData ? resData.message : 'A server error occurred while revoking admin access!';
                    reject(errorMessage);
                } else {
                    return resolve(resData.data || {});
                }
            });
        });
    }

    const addAdmin = (idToken, email) => {
        return new Promise((resolve, reject) => {
            let options = {
                method: 'POST',
                url: `${process.env.REACT_APP_API_URL}/admin/addAdmin`,
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    'idToken': idToken,
                    'email': email
                })
            };
            return request(options, (error, response, body) => {
                let resData = body && JSON.parse(body);
                if (error || response.statusCode !== 200) {
                    let errorMessage = error ? error : resData ? resData.message : 'Error occurred while adding a new admin!';
                    reject(errorMessage);
                } else {
                    return resolve(resData.data || {});
                }
            });
        });
    }

    const refreshList = async () => {
        const idToken = await firebase.auth.currentUser.getIdToken(true);
        const users = await getAdmins(idToken);
        const arr = Object.keys(users).map(id => ({ 'id': id, 'email': users[id].emailId || '-' }));
        setUsers(arr || []);
    }

    const handleDeleteClick = (uid, email) => {
        Swal.fire({
            html: 'Are you sure you want to remove <strong>' + email + '</strong> as admin?',
            icon: 'question',
            color: '#103a8d',
            showCancelButton: true,
            confirmButtonText: 'Delete',
            reverseButtons: true
        }).then(async (result) => {
            if (result.value) {
                try {
                    setLoading(true);
                    const idToken = await firebase.auth.currentUser.getIdToken(true);
                    let admins = await removeAdmin(idToken, uid);
                    admins = Object.keys(admins).map(id => ({ 'id': id, 'email': admins[id].emailId || '-' }));
                    setUsers(admins);
                    showToastAlert('success', 'Admin removed successfully');
                }
                catch (err) {
                    showToastAlert('Error', err.message ? err.message : err);
                }
                setLoading(false);
            }
        });
    };

    const handleAddAdminClick = () => {
        Swal.fire({
            title: 'Enter email address',
            color: '#103a8d',
            input: 'email',
            inputPlaceholder: 'example@example.com',
            inputAttributes: {
                autocapitalize: 'off'
            },
            showCancelButton: true,
            confirmButtonText: 'Add',
            showLoaderOnConfirm: true,
            reverseButtons: true,
            preConfirm: async (email) => {
                try {
                    setLoading(true);
                    const idToken = await firebase.auth.currentUser.getIdToken(true);
                    let admins = await addAdmin(idToken, email.trim());
                    admins = Object.keys(admins).map(id => ({ 'id': id, 'email': admins[id].emailId || '-' }));
                    setUsers(admins);
                    showToastAlert('success', 'Admin added successfully');
                }
                catch (err) {
                    showToastAlert('Error', err.message ? err.message : err);
                }
                setLoading(false);
            },
            allowOutsideClick: () => !Swal.isLoading()
        }).then((result) => {
        });
    };

    useEffect(() => {
        (async function () {
            firebase.auth.onAuthStateChanged(async (user) => {
                try {
                    if (!user) {
                        history.push('/login');
                        return;
                    }
                    setLoading(false);
                    const idToken = await firebase.auth.currentUser.getIdToken(true);
                    const users = await getAdmins(idToken);
                    const arr = Object.keys(users).map(id => ({ 'id': id, 'email': users[id].emailId || '-' }));
                    setUsers(arr);
                    setPending(false);
                } catch (error) {
                    console.log(error);
                    showToastAlert('Error', error.message);
                    setPending(false);
                    setLoading(false);
                }
            });
        })();
    }, [history, firebase.auth]);

    return (
        <>
            {loading && <PageLoader />}
            <div id="page-content-wrapper" className="extra-p">
                <Navbar toggleSideMenu={props.toggleSideMenu} refreshList={refreshList} />

                <div className="container">
                    <div className="row">
                        <div className="col-12 dash-head my-0">
                            <div className='d-flex' style={{ 'gap': '20px' }}>
                                <h3 className="my-0">Administrators</h3>
                                <button className='icon-btn add' onClick={e => handleAddAdminClick()}><AddIcon /></button>
                            </div>
                            <div className="search-box"> <input className="search" type="text" placeholder='Search User...' value={filterText} onChange={e => setFilterText(e.target.value)} /><img src={require("assets/images/search.svg")} alt="" /></div>
                        </div>
                        <div className="col-12 mt-3">
                            <div className="dash-box position-relative mt-2">
                                <div className="table-responsive">
                                    <DataTable columns={columns(handleDeleteClick)} data={filteredItems} persistTableHead pagination paginationRowsPerPageOptions={[10, 20, 50, 100]} paginationComponentOptions={{ rowsPerPageText: 'Show entries' }} progressPending={pending} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}

export default Administrator;