import React, { useState, useEffect, useContext, useMemo } from 'react';
import { isEmpty } from 'lodash';
import * as request from 'request';
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
import { FirebaseContext } from 'contexts/Firebase';
import { PageLoader, showToastAlert, generateRandomString } from 'Services/Utility';
import Navbar from 'components/Navbar';
import DateRangeFilter from 'components/DateRangeFilter';
import ReportProduct from 'components/ReportProduct';

const Reports = (props) => {
    const { user, firebase } = useContext(FirebaseContext);
    const history = useHistory();
    const [products, setProducts] = useState([]);
    const [prices, setPrices] = useState([]);
    const [planReport, setPlanReport] = useState({});
    const [loading, setLoading] = useState(true);
    const [activeTabKey, setActiveTabKey] = useState();

    useEffect(() => {
        document.body.classList.add('dash-bg');
        document.body.classList.add('plan-price-bg');
    }, []);

    const getPrices = (idToken, cache) => {
        return new Promise((resolve, reject) => {
            let options = {
                method: 'POST',
                url: `${process.env.REACT_APP_API_URL}/report/getPrices?cache=${cache}`,
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    'idToken': idToken
                })
            };

            return request(options, (error, response, body) => {
                let resData = body && JSON.parse(body);
                
                if (error || response.statusCode !== 200) {
                    let errorMessage = error ? error : resData ? resData.message : 'A server error occurred while fetching coupon!';
                    showToastAlert('Error', errorMessage);
                    resolve([]);
                } else {
                    return resolve(resData.data || []);
                }
            });
        });
    }

    const getProduct = (idToken, cache) => {
        return new Promise((resolve, reject) => {
            let options = {
                method: 'POST',
                url: `${process.env.REACT_APP_API_URL}/report/getProducts?cache=${cache}`,
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    'idToken': idToken
                })
            };

            return request(options, (error, response, body) => {
                let resData = body && JSON.parse(body);
                if (error || response.statusCode !== 200) {
                    let errorMessage = error ? error : resData ? resData.message : 'A server error occurred while fetching coupon!';
                    showToastAlert('Error', errorMessage);
                    resolve([]);
                } else {
                    return resolve(resData.data.data || []);
                }
            });
        });
    }

    const getPlanReports = (idToken, dateFrom, dateTo, processId, cache) => {
        return new Promise((resolve, reject) => {
            let options = {
                method: 'POST',
                url: `${process.env.REACT_APP_API_URL}/report/getPlanReports?cache=${cache}`,
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    idToken,
                    dateFrom,
                    dateTo,
                    processId
                })
            };

            return request(options, (error, response, body) => {
                let resData = body && JSON.parse(body);
                if (error || response.statusCode !== 200) {
                    let errorMessage = error ? error : resData ? resData.message : 'A server error occurred while fetching coupon!';
                    showToastAlert('Error', errorMessage);
                    resolve([]);
                } else {
                    return resolve(resData.data || []);
                }
            });
        });
    }

    const getPlanReportLoadingStatus = (idToken, processId) => {
        return new Promise((resolve, reject) => {
            let options = {
                method: 'POST',
                url: `${process.env.REACT_APP_API_URL}/report/getPlanReportLoadingStatus`,
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    idToken,
                    processId
                })
            };

            return request(options, (error, response, body) => {
                let resData = body && JSON.parse(body);
                if (error || response.statusCode !== 200) {
                    let errorMessage = error ? error : resData ? resData.message : 'A server error occurred while fetching coupon!';
                    showToastAlert('Error', errorMessage);
                    resolve("Unexpected error occurred!");
                } else {
                    return resolve(resData.data || "");
                }
            });
        });
    }

    useEffect(() => {
        (async function () {
            firebase.auth.onAuthStateChanged(async (user) => {
                try {
                    if (!user) {
                        history.push('/login');
                        return;
                    }
                    setLoading(true);

                    const idToken = await firebase.auth.currentUser.getIdToken(true);

                    const [productData, priceData] = await Promise.all([getProduct(idToken, true), getPrices(idToken, true)]);

                    setProducts(productData);
                    setActiveTabKey(productData[0].id);
                    setPrices(priceData)

                    setLoading(false);
                } catch (error) {
                    console.log(error);
                    showToastAlert('Error', error.message);
                    setLoading(false);
                }
            });
        })();
    }, [history, firebase.auth]);

    const checkReportLoading = async (processId) => {
        const idToken = await firebase.auth.currentUser.getIdToken(true);

        getPlanReportLoadingStatus(idToken, processId).then((text) => {
            if (text) {
                Swal.fire({
                    title: text,
                    showConfirmButton: false,
                    allowOutsideClick: false,
                    isLoading: true,
                    showClass: {
                        popup: '',
                        backdrop: 'swal2-noanimation',
                        icon: ''
                    }
                });
                Swal.showLoading();

                setTimeout(() => checkReportLoading(processId), 10000);
            };
        });
    };

    const handleSubmit = async (dateRange) => {
        const idToken = await firebase.auth.currentUser.getIdToken(true);
        
        Swal.fire({
            title: 'Loading',
            showConfirmButton: false,
            allowOutsideClick: false,
            isLoading: true,
            showClass: {
                popup: '',
                backdrop: 'swal2-noanimation',
                icon: ''
            }
        });
        Swal.showLoading();

        const processId = generateRandomString(10);
        getPlanReports(idToken, dateRange.from, dateRange.to, processId, true).then((result) => {
            Swal.close();

            setPlanReport(result);
        });

        setTimeout(() => checkReportLoading(processId), 1000);
    };

    const renderProduct = useMemo(() => {
        if (!activeTabKey) return undefined;

        const prod = products.find((product) => product.id === activeTabKey);

        return (<ReportProduct key={prod.id} product={prod} prices={prices} reports={planReport} />);
    }, [products, prices, planReport, activeTabKey]);

    return (
        <>
            {loading && <PageLoader />}

            <div id="page-content-wrapper" className="extra-p">
                <Navbar toggleSideMenu={props.toggleSideMenu} />

                <div className="container">

                    <div className="row">
                        <div className="col-8 mb-5">
                            <div className="mt-0 mb-2">
                                <DateRangeFilter onSubmit={handleSubmit} />
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12 dash-head my-0">
                            <div className='plan-nav'>
                                <ul className="nav nav-pills mt-0 mb-5 px-0" id="pills-tab" role="tablist">
                                    {
                                        !isEmpty(products) && Object.values(products).map(product => {
                                            return (
                                                <li className="nav-item" role="presentation" key={`topBarPlan-${product.id}`}>
                                                    <button 
                                                        key={product.id + '-btn'} 
                                                        className={`nav-link px-4 ${activeTabKey === product.id ? 'active' : ''}`} 
                                                        onClick={() => setActiveTabKey(product.id)} 
                                                        data-key={product.name} 
                                                        id={'pills-home-tab-' + product.id}
                                                        data-bs-toggle="pill" 
                                                        data-bs-target="#pills-home" 
                                                        type="button" 
                                                        role="tab" 
                                                        aria-controls="pills-home" 
                                                        aria-selected="true">
                                                            {product.name}
                                                    </button>
                                                </li>
                                            )
                                        })
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>

                    {renderProduct}

                </div>

            </div>
        </>
    )
}

export default Reports;