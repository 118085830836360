import React, { useState, useEffect, useContext, useRef } from 'react';
import * as request from 'request';
import { useHistory } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import memoize from 'memoize-one';
import { FirebaseContext } from 'contexts/Firebase';
import { PageLoader, showToastAlert, dateFormate } from 'Services/Utility';
import DeleteIcon from '@mui/icons-material/Delete';
import ImportIcon from '@mui/icons-material/BrowserUpdated';
import AssignIcon from '@mui/icons-material/AssignmentInd';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Navbar from 'components/Navbar';
import DisplayAccountsModal from 'Modals/DisplayAccountsModal';
import AssignAccountModal from 'Modals/AssignAccountModal';
import Tooltip from '@mui/material/Tooltip';
import Zoom from '@mui/material/Zoom';


const paymentsColumns = memoize((displayAccountsHandler) =>[
    {
        name: 'Token',
        selector: row => row.authToken || '-',
        sortable: true,
    },
    {
        name: 'Email',
        selector: row => row.email || '-',
        sortable: true,
    },
    {
        name: 'Order',
        selector: row => row.order || '-',
        sortable: true,
    },
    {
        name: 'Paid',
        selector: row => row.paidAmount || '',
        sortable: true,
    },
    {
        name: 'Discount Code',
        selector: row => row.code || '',
        sortable: true,
    },
    {
        name: 'Stripe',
        cell: (row) => {
            if (row.paymentId !== 'pi_flipd_extra' && row.paymentId !== 'pi_manual_payment' && row.paymentId !== 'pi_migrated') {
                const href = 'https://dashboard.stripe.com/payments/' + row.paymentId;
                return <a href={href} target="_blank">{row.paymentId}</a>
            }
            return row.paymentId;
        },
        sortable: false,
    },
    {
        name: 'Date',
        selector: row => row.createdAt ? dateFormate(row.createdAt) : '',
        sortable: true,
        sortFunction: (rowA, rowB) => {
            if (rowA.createdAt && rowB.createdAt) {
                if (rowA.createdAt < rowB.createdAt) {
                    return -1;
                }
                else if (rowA.createdAt > rowB.createdAt) {
                    return 1;
                }
            }
            return 0;
        }
    },
    {
        name: 'Action',
        cell: (row) => (
            <>
                <button className='icon-btn blue' onClick={e => displayAccountsHandler(row.accounts)}><VisibilityIcon /></button>
            </>
        ),
        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
        sortable: false,
    },
]);

const accountsColumns = [
    {
        name: 'Username',
        selector: row => row.username,
        sortable: true,
    },
    {
        name: 'Password',
        selector: row => row.password,
        sortable: true,
    },
    {
        name: 'Outlook Email',
        selector: row => row.outlookEmail,
        sortable: true,
    },
    {
        name: 'Outlook Password',
        selector: row => row.outlookPassword,
        sortable: true,
    },
    {
        name: 'Outlook First Name',
        selector: row => row.outlookFirstName,
        sortable: true,
    },
    {
        name: 'Outlook Last Name',
        selector: row => row.outlookLastName,
        sortable: true,
    },
    {
        name: 'Outlook DOB',
        selector: row => row.outlookDoB,
        sortable: true,
    },
];


const Nike = (props) => {

    const { user, firebase } = useContext(FirebaseContext);
    const history = useHistory();
    const [filterPaymentText, setFilterPaymentText] = useState('');
    const [filterProxyText, setFilterProxyText] = useState('');
    const [pending, setPending] = useState(true);
    const [loading, setLoading] = useState(true);
    const [basicNetSales, setNetSales] = useState(0);
    const [basicNetDiscounts, setNetDiscounts] = useState(0);
    const [allPayments, setAllPayments] = useState([]);
    const [allAccounts, setAllAccounts] = useState([]);
    const [nikePlansOptions, setNikePlansOptions] = useState([]);
    const [nikeModals, setNikeModals] = useState([]);
    const [selectedAccounts, setSelectedAccounts] = useState([]);
    const importFileRef = useRef(null);

    const filteredPayments = allPayments.filter(
        item => (item.authToken && item.authToken.toLowerCase().includes(filterPaymentText.toLowerCase())) || (item.email && item.email.toLowerCase().includes(filterPaymentText.toLowerCase())) || (item.paymentId && item.paymentId.toLowerCase().includes(filterPaymentText.toLowerCase())) || (item.code && item.code.toLowerCase().includes(filterPaymentText.toLowerCase()))
    );
    const filteredAccounts = allAccounts.filter(
        item => (item.category && item.category.toLowerCase().includes(filterProxyText.toLowerCase())) || (item.username && item.username.toLowerCase().includes(filterProxyText.toLowerCase())) || (item.recovery && item.recovery.toLowerCase().includes(filterProxyText.toLowerCase()))
    );

    useEffect(() => {
        document.body.classList.add('dash-bg');
        document.body.classList.add('plan-price-bg');
    }, []);

    const getNikeData = (idToken, cache) => {
        return new Promise((resolve, reject) => {
            let options = {
                method: 'POST',
                url: `${process.env.REACT_APP_API_URL}/nike/getPaymentDetails`,
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ 'idToken': idToken })
            };
            return request(options, (error, response, body) => {
                let resData = body && JSON.parse(body);
                if (error || response.statusCode !== 200) {
                    let errorMessage = error ? error : resData ? resData.message : 'A server error occurred while fetching basic plan data!';
                    showToastAlert('Error', errorMessage);
                    resolve({});
                } else {
                    resolve(resData);
                }
            });
        });
    }

    const fetchPlans = async () => {
        let plans = (await firebase.db.ref('plans/nike').once('value')).val();

        console.log('plans', plans);

        plans = { ...plans };
        delete plans['categories'];
        delete plans['active'];
        delete plans['hide'];
        let planOptions = [];
        for (const planId in plans) {
            const name = plans[planId]['name'];
            planOptions.push({ 'label': `${name}`, 'value': `${planId}` });
        }
        setNikePlansOptions(planOptions);
    }

    const updatePaymentTable = (payments) => {
        let paymentDetails = [], netSales = 0.0, netDiscounts = 0.0;
        payments.forEach((item, i) => {
            let email = item['userEmail'] || '';
            let authToken = item['authToken'];
            let paymentId = item['paymentToken'];
            let quantity = item['quantity'] ? parseInt(item['quantity']) : 0;
            let order = quantity + ' - ' + item['planName'];
            order = item['renew'] ? order + ' Topup' : order;

            let code = item['couponCode'] ? item['couponCode'] : '-';
            let planPrice = parseFloat(item['planPrice']);
            let paidAmount = planPrice;
            if (item['paid']) {
                paidAmount = parseFloat(item['paid']);
            }

            let discountAmount = parseFloat(planPrice) - parseFloat(paidAmount);
            discountAmount = discountAmount < 0 ? 0 : discountAmount;

            if (paymentId && paymentId !== 'pi_flipd_extra' && paymentId !== 'pi_manual_payment' && paymentId !== 'pi_migrated') {
                netSales = parseFloat(netSales) + paidAmount;
                netDiscounts = parseFloat(netDiscounts) + parseFloat(discountAmount);
            }

            if (paymentId && (paymentId === 'pi_flipd_extra' || paymentId === 'pi_manual_payment')) {
                paidAmount = 0;
            }

            let createdAt = new Date(parseInt(item['createdAt']) * 1000);
            let accounts = item['accounts'] ? item['accounts'].map(acc => `${acc.username},${acc.password},${acc.outlookEmail},${acc.outlookPassword},${acc.outlookFirstName},${acc.outlookLastName},${acc.outlookDoB}`) : [];
            paymentDetails.push({
                authToken,
                email: email ? email : '-',
                order: order,
                paidAmount: paidAmount.toFixed(2),
                code,
                paymentId,
                createdAt,
                accounts
            });
        });
        setAllPayments(paymentDetails);
        setNetSales(parseFloat(netSales).toFixed(2));
        setNetDiscounts(parseFloat(netDiscounts).toFixed(2));
    }

    const updateAccountsDetails = (accounts) => {
        let arr = [];
        Object.keys(accounts).forEach(planId => {
            Object.keys(accounts[planId]).forEach(key => {
                arr.push({ ...accounts[planId][key], 'planId': planId, 'key': key });
            })
        })
        setAllAccounts(arr);
    }

    const refreshList = async () => {
        const idToken = await firebase.auth.currentUser.getIdToken(true);
        const [nikeData] = await Promise.all([getNikeData(idToken, false)]);
        updatePaymentTable(nikeData.data || []);
        updateAccountsDetails(nikeData.accounts || {});
    }

    const importNewAccountsAPI = (idToken, accounts) => {
        return new Promise((resolve, reject) => {
            let options = {
                method: 'POST',
                url: `${process.env.REACT_APP_API_URL}/nike/import`,
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    'idToken': idToken,
                    'accounts': accounts
                })
            };
            return request(options, (error, response, body) => {
                let resData = body && JSON.parse(body);
                if (error || response.statusCode !== 200) {
                    let errorMessage = error ? error : resData ? resData.message : 'Error occurred while importing account!';
                    reject(errorMessage);
                } else {
                    resolve(resData || {});
                }
            });
        });
    }

    const deleteAccountsAPI = (idToken, accounts, deleteall) => {
        return new Promise((resolve, reject) => {
            let options = {
                method: 'POST',
                url: `${process.env.REACT_APP_API_URL}/nike/delete`,
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    'idToken': idToken,
                    'accounts': accounts,
                    'deleteAll': deleteall
                })
            };
            return request(options, (error, response, body) => {
                let resData = body && JSON.parse(body);
                if (error || response.statusCode !== 200) {
                    let errorMessage = error ? error : resData ? resData.message : 'Error occurred while delete account!';
                    reject(errorMessage);
                } else {
                    resolve(resData || {});
                }
            });
        });
    }

    const displayAccountsHandler = (accounts) => {
        setNikeModals([]);
        setNikeModals([
            <DisplayAccountsModal
                showModal={true}
                key={Math.random()}
                firebase={firebase}
                accounts={accounts}
                setLoading={setLoading}
                successCallback={swapIpsCallback}
            />
        ]);
    };

    const swapIpsCallback = (response) => {
        updateAccountsDetails(response.accounts || {});
    };

    const assignAccountBtnClick = (e) => {
        e.preventDefault();
        setNikeModals([]);
        setNikeModals([
            <AssignAccountModal
                showModal={true}
                key={Math.random()}
                firebase={firebase}
                setLoading={setLoading}
                successCallback={accountsUpdateCallback}
                gmailPlansOptions={nikePlansOptions}
                planType={'nike'}
            />
        ]);
    };

    const changeFilehandler = (e) => {
        e.preventDefault();
        const reader = new FileReader();
        reader.onload = async (e) => {
            const texts = e.target.result.split('\r\n');
            texts.shift();
            if (texts.length) {
                try {
                    setLoading(true);
                    const idToken = await firebase.auth.currentUser.getIdToken(true);
                    const resp = await importNewAccountsAPI(idToken, texts);
                    updateAccountsDetails(resp.accounts || {});
                    showToastAlert('success', 'Account imported successfully');
                }
                catch (err) {
                    showToastAlert('Error', err.message ? err.message : err);
                }
                setLoading(false);
            }
        }
        reader.readAsText(e.target.files[0]);
        e.target.value = '';
    };

    const accountsUpdateCallback = (response) => {
        updatePaymentTable(response.data || []);
        updateAccountsDetails(response.accounts || {});
    }

    const selectedAccountRowChange = (e) => {
        setSelectedAccounts(e.selectedRows)
    };

    const deleteAccountBtnClick = async (e) => {
        e.preventDefault()
        if (!selectedAccounts.length) {
            return showToastAlert('Info', 'Please select accounts you want to delete');
        }
        try {
            const accounts = selectedAccounts.flatMap(x => `${x.planId}/${x.key}/`)
            setLoading(true);
            const idToken = await firebase.auth.currentUser.getIdToken(true);
            const resp = await deleteAccountsAPI(idToken, accounts, selectedAccounts.length === allAccounts.length);
            updateAccountsDetails(resp.accounts || {});
            setSelectedAccounts([]);
            showToastAlert('success', 'Account deleted successfully');
        }
        catch (err) {
            showToastAlert('Error', err.message ? err.message : err);
        }
        setLoading(false);
    }

    useEffect(() => {
        (async function () {
            firebase.auth.onAuthStateChanged(async (user) => {
                try {
                    if (!user) {
                        history.push('/login');
                        return;
                    }
                    setLoading(false);
                    fetchPlans();

                    const idToken = await firebase.auth.currentUser.getIdToken(true);
                    const [nikeData] = await Promise.all([getNikeData(idToken, true)]);

                    updatePaymentTable(nikeData.data || []);
                    updateAccountsDetails(nikeData.accounts || {});
                    setPending(false);

                } catch (error) {
                    console.log(error);
                    showToastAlert('Error', error.message ? error.message : error);
                    setPending(false);
                    setLoading(false);
                }
            });
        })();
    }, [history, firebase.auth]);

    return (
        <>
            {loading && <PageLoader />}
            <div id="page-content-wrapper" className="extra-p">
                <Navbar toggleSideMenu={props.toggleSideMenu} refreshList={refreshList} />

                <div className="container">
                    <div className='saleDiv mb-4'>
                        <div className='mt-0 mb-2'>
                            <div className="dash-box d-flex" style={{ flexDirection: 'column' }}>
                                <h5 className="my-0 text-left w-100" style={{ 'color': 'var(--main-blue)' }}>Net Sales</h5>
                                <h3 className="mt-2 mb-1 text-center w-100 font-weight-bold" style={{ 'color': '#52D987' }}>$ {basicNetSales}</h3>
                            </div>
                        </div>
                        <div className='mt-0 mb-2'>
                            <div className="dash-box d-flex" style={{ flexDirection: 'column' }}>
                                <h5 className="my-0 text-left w-100" style={{ 'color': 'var(--main-blue)' }}>Net Discounts</h5>
                                <h3 className="mt-2 mb-1 text-center w-100 font-weight-bold" style={{ 'color': '#FF7170' }}>$ {basicNetDiscounts}</h3>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-12 mb-2'>
                            <div className="dash-box position-relative">
                                <div className="row" style={{ 'paddingTop': '30px' }}>
                                    <div className="col-12 dash-head my-0">
                                        <div className='d-flex' style={{ 'gap': '20px' }}>
                                            <h4 className="my-0" style={{ 'color': 'var(--main-blue)' }}>Nike Orders</h4>
                                        </div>
                                        <div className="search-box"> <input className="search" type="text" placeholder='Search Record...' value={filterPaymentText} onChange={e => setFilterPaymentText(e.target.value)} /><img src={require("assets/images/search.svg")} alt="" /></div>
                                    </div>
                                    <div className="col-12 mt-3">
                                        <div className="table-responsive">
                                            <DataTable columns={paymentsColumns(displayAccountsHandler)} data={filteredPayments} defaultSortFieldId={7} defaultSortAsc={false} persistTableHead pagination paginationRowsPerPageOptions={[10, 20, 50, 100]} paginationComponentOptions={{ rowsPerPageText: 'Show entries' }} progressPending={pending} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-12'>
                            <div className="dash-box position-relative">
                                <div className="row" style={{ 'paddingTop': '30px' }}>
                                    <div className="col-12 dash-head my-0">
                                        <div className='d-flex' style={{ 'gap': '20px' }}>
                                            <h4 className="my-0" style={{ 'color': 'var(--main-blue)' }}>Nike Accounts</h4>
                                            <Tooltip TransitionComponent={Zoom} arrow title="Assign accounts to user" placement="top"><button className='icon-btn add' onClick={assignAccountBtnClick}><AssignIcon /></button></Tooltip>
                                            <Tooltip TransitionComponent={Zoom} arrow title="Import accounts from csv only" placement="top"><button className='icon-btn blue' onClick={e => importFileRef.current.click()}><ImportIcon /></button></Tooltip>
                                            <Tooltip TransitionComponent={Zoom} arrow title="Delete selected accounts" placement="top"><button className='icon-btn delete' onClick={deleteAccountBtnClick}><DeleteIcon /></button></Tooltip>
                                            <input type='file' ref={importFileRef} style={{ display: 'none' }} onChange={changeFilehandler} accept='.csv' hidden />
                                        </div>
                                        <div className="search-box"> <input className="search" type="text" placeholder='Search Record...' value={filterProxyText} onChange={e => setFilterProxyText(e.target.value)} /><img src={require("assets/images/search.svg")} alt="" /></div>
                                    </div>
                                    <div className="col-12 mt-3">
                                        <div className="table-responsive">
                                            <DataTable columns={accountsColumns} data={filteredAccounts} defaultSortFieldId={0} defaultSortAsc={false} selectableRows onSelectedRowsChange={selectedAccountRowChange} persistTableHead pagination paginationRowsPerPageOptions={[10, 20, 50, 100]} paginationComponentOptions={{ rowsPerPageText: 'Show entries' }} progressPending={pending} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {nikeModals.map(modal => (modal))}
        </>
    )
}

export default Nike;