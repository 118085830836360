import React, { Component } from 'react';
import { Modal } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import Select from 'react-select';
import { generateRandomString, showToastAlert } from '../Services/Utility';
import Tooltip from '@mui/material/Tooltip';
import Zoom from '@mui/material/Zoom';
import InfoIcon from '@mui/icons-material/InfoOutlined';


class AddPlanModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            planType: props.planType,
            isUpdate: props.isUpdate,
            showModal: props.showModal || false,
            active: props.isUpdate ? props.planData['active'] : false,
            hide: props.isUpdate ? props.planData['hide'] : false,
            topup: props.isUpdate ? props.planData['topup'] : false,
            daily: props.isUpdate ? props.planData['daily'] : false,
            weekly: props.isUpdate ? props.planData['weekly'] : false,
            bulk: props.isUpdate ? props.planData['bulk'] : false,
            planName: props.isUpdate ? props.planData['name'] : '',
            description: props.isUpdate ? props.planData['description'] : '',
            features: props.isUpdate ? props.planData['features'] : '',
            onlybot: props.isUpdate ? props.planData['onlybot'] : false,
            productId: props.isUpdate ? props.planData['productId'] : '',
            order: props.isUpdate ? props.planData['order'] : undefined,
            serverProducts: props.serverProducts || [],
            serverProductsSelect: [],
            selectedServerProduct: null,
            priceId: props.isUpdate ? props.planData['priceId'] : '',
            price: props.isUpdate ? props.planData['price'] : '',
            categories: props.categories || {},
            iscombo: props.isUpdate ? props.planData['isCombo'] : false
        };

        this.state.hide = this.state.hide === undefined ? false : this.state.hide;
        this.state.topup = this.state.topup === undefined ? false : this.state.topup;
        this.state.daily = this.state.daily === undefined ? false : this.state.daily;
        this.state.weekly = this.state.weekly === undefined ? false : this.state.weekly;
        this.state.bulk = this.state.bulk === undefined ? false : this.state.bulk;
        this.state.onlybot = this.state.onlybot === undefined ? false : this.state.onlybot;
        this.state.iscombo = this.state.iscombo === undefined ? false : this.state.iscombo;

        this.state.serverProductsSelect = this.state.serverProducts.map(x => ({ 'label': `${x.name}`, 'value': x.id }))

        if (props.isUpdate) {
            this.state.planId = props.planId;
            if (this.state.planType === 'datacenter' || this.state.planType === 'isp' || this.state.planType === 'captchaProxies') {
                this.state.serverName = props.planData['servername'];
            }
            else if (this.state.planType === 'server') {
                this.state.selectedServerProduct = this.state.serverProductsSelect.find(x => x.value === props.planData['serverId']);
            }
            else if (this.state.planType !== 'gmail' && this.state.planType !== 'nike') {
                this.state.serverName = props.planData['provider'];
            }
        }
        else {
            // let count = 1;
            // for (const key in props.planData) {
            //     if (key.includes('plan_')) count += 1;
            // }
            this.state.planId = `plan_${generateRandomString(3)}`;
        }
        this.closeModal = this.closeModal.bind(this);
    }

    closeModal = () => {
        this.setState({
            showModal: false
        });
    }

    onChange = (e) => {
        e.persist();
        this.setState({
            [e.target.id]: e.target.value
        });
    }

    handleSwitchChange = async (e) => {
        e.persist();
        const key = e.target.dataset.key;
        this.setState({ [key]: e.target.checked });
    }

    handleServerProductChange = (selectedOption) => {
        this.setState({ ['selectedServerProduct']: selectedOption || null })
    }

    cancelClicked = async (event) => {
        this.closeModal();
    }

    saveClicked = async (event) => {

        if (this.state.planName.trim() === '') {
            return showToastAlert('Error', 'Please provide plan name');
        }
        if (this.state.planType !== 'gmail' && this.state.planType !== 'server' && this.state.planType !== 'nike') {
            if (this.state.serverName.trim() === '') {
                if (this.state.planType === 'datacenter' || this.state.planType === 'isp' || this.state.planType === 'captchaProxies') {
                    return showToastAlert('Error', 'Please provide server name');
                }
                else {
                    return showToastAlert('Error', 'Please enter provider name');
                }
            }
        }
        if (this.state.description.trim() === '') {
            return showToastAlert('Error', 'Please provide description');
        }
        if (this.state.features.trim() === '' && !this.state.iscombo) {
            return showToastAlert('Error', 'Please provide features');
        }
        if ((this.state.planType === 'residential' || this.state.planType === 'gmail' || this.state.planType === 'nike') && this.state.productId.trim() === '') {
            return showToastAlert('Error', 'Please provide productId');
        }
        if ((this.state.planType === 'datacenter' || this.state.planType === 'isp' || this.state.planType === 'captchaProxies') && (this.state.order === undefined || this.state.order === '')) {
            return showToastAlert('Error', 'Please provide order');
        }
        if (this.state.planType === 'server') {
            if (this.state.selectedServerProduct === null) {
                return showToastAlert('Error', 'Select Server Product');
            }
            if (this.state.price.trim() === '') {
                return showToastAlert('Error', 'Enter price');
            }
            if (this.state.priceId.trim() === '') {
                return showToastAlert('Error', 'Enter priceId from stripe');
            }
        }
        let data = {
            'description': this.state.description.trim(),
            'features': this.state.features.trim(),
            'name': this.state.planName.trim(),
            'active': this.state.active,
            'hide': this.state.hide,
            'onlybot': this.state.onlybot
        }
        const not_gmail = {
            'topup': this.state.topup,
            'bulk': this.state.bulk,
            'daily': this.state.daily,
            'weekly': this.state.weekly
        }

        if (this.state.planType === 'isp' || this.state.planType === 'nike') {
            data['isCombo'] = this.state.iscombo;
        }

        if (this.state.planType === 'datacenter' || this.state.planType === 'isp' || this.state.planType === 'captchaProxies') {
            data['servername'] = this.state.serverName.trim();
            data['order'] = parseInt(this.state.order);
            data = { ...data, ...not_gmail }
        }
        else if (this.state.planType === 'gmail' || this.state.planType === 'nike') {
            data['productId'] = this.state.productId.trim();
            // this.state.planId = data.name.toLowerCase().replace(' ', '_');
        }
        else if (this.state.planType === 'server') {
            data['priceId'] = this.state.priceId.trim();
            data['price'] = this.state.price.trim();
            data['daily'] = this.state.daily;
            const product = this.state.serverProducts.find(x => x.id === this.state.selectedServerProduct.value);
            data['serverId'] = product.id;
            data['actions'] = product.actions || null;
            if (product.billingCycles) {
                if (product.billingCycles.includes('monthly')) {
                    data['category'] = Object.keys(this.state.categories).find(key => this.state.categories[key].validityHours === 720);
                }
                else if (product.billingCycles.includes('onetime')) {
                    data['category'] = Object.keys(this.state.categories).find(key => this.state.categories[key].validityHours === 24);
                }
            }
        }
        else {
            data['provider'] = this.state.serverName.trim();
            data['productId'] = this.state.productId.trim();
            data = { ...data, ...not_gmail }
        }

        await this.props.firebase.db.ref(`plans/${this.state.planType}/${this.state.planId}`).update(data);
        this.props.successCallback();
        this.closeModal();
    }

    render() {
        const { showModal, serverProductsSelect } = this.state;
        return (
            <Modal
                isOpen={showModal}
                toggle={this.closeModal}
                onClosed={this.closeModal}
                className=""
                size="md"
                backdrop="static"
                centered
            >
                <div className="modal-body">
                    <div className="pop-bg-img">
                        <img src={require("assets/images/pop-bg.svg")} alt="" />
                    </div>
                    <button
                        type="button"
                        className="btn-close pop-close-btn"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        onClick={this.closeModal}
                    />
                    <div className="dash-head">
                        <img src={require("assets/images/proxy-gen.svg")} alt="" /> <h4 className="mb-0">{this.state.isUpdate ? 'Update Plan' : 'Create Plan'}</h4>
                    </div>

                    <div className="form-field px-2">
                        <div className="form-group row">
                            <div className='col-sm-6 mb-3 mt-0'>
                                <label htmlFor="planName" className="w-100 col-form-label pt-0 pb-1">Plan Name <span className='text-danger'>*</span></label>
                                <input type="text" className="form-control" id="planName" onChange={this.onChange} value={this.state.planName} placeholder='Enter name' required />
                            </div>
                            {
                                (this.state.planType === 'datacenter' || this.state.planType === 'isp' || this.state.planType === 'captchaProxies') &&
                                <div className='col-sm-6 mb-3 mt-0'>
                                    <label htmlFor="serverName" className="w-100 col-form-label pt-0 pb-1">Server Name <span className='text-danger'>* </span><Tooltip TransitionComponent={Zoom} arrow title="Case sensitive" placement="top"><InfoIcon fontSize='small' /></Tooltip></label>
                                    <input type="text" className="form-control" id="serverName" onChange={this.onChange} value={this.state.serverName} placeholder='Enter name' required />
                                </div>
                            }
                            {
                                this.state.planType === 'server' &&
                                <div className='col-sm-6 mb-3 mt-0'>
                                    <label htmlFor="serverProduct" className="w-100 col-form-label pt-0 pb-1">Product <span className='text-danger'>* </span></label>
                                    <Select className="select" id="serverProduct" options={serverProductsSelect} onChange={this.handleServerProductChange} value={this.state.selectedServerProduct} placeholder="Select product" required />
                                </div>
                            }
                            {
                                this.state.planType === 'residential' &&
                                <div className='col-sm-6 mb-3 mt-0'>
                                    <label htmlFor="serverName" className="w-100 col-form-label pt-0 pb-1">Provider Name <span className='text-danger'>* </span><Tooltip TransitionComponent={Zoom} arrow title="This should be basic, pro or premium" placement="top"><InfoIcon fontSize='small' /></Tooltip></label>
                                    <input type="text" className="form-control" id="serverName" onChange={this.onChange} value={this.state.serverName} placeholder='Enter name' required />
                                </div>
                            }
                            {
                                (this.state.planType === 'gmail' || this.state.planType === 'residential' || this.state.planType === 'nike') &&
                                <>
                                    <div className='col-12 mb-3 mt-1'>
                                        <label htmlFor="productId" className="w-100 col-form-label pt-0 pb-1">product Id <span className='text-danger'>* </span><Tooltip TransitionComponent={Zoom} arrow title="Enter product Id from stripe product" placement="top"><InfoIcon fontSize='small' /></Tooltip></label>
                                        <input type="text" className="form-control" id="productId" onChange={this.onChange} value={this.state.productId} placeholder='Write a product Id...' required />
                                    </div>
                                    <div className='col-12 mb-3 mt-1'>
                                        <label htmlFor="description" className="w-100 col-form-label pt-0 pb-1">Description <span className='text-danger'>* </span><Tooltip TransitionComponent={Zoom} arrow title="Enter few words only" placement="top"><InfoIcon fontSize='small' /></Tooltip></label>
                                        <input type="text" className="form-control" id="description" onChange={this.onChange} value={this.state.description} placeholder='Write a description...' required />
                                    </div>
                                </>
                            }
                            {
                                this.state.planType === 'server' &&
                                <>
                                    <div className='col-sm-6 mb-3 mt-1'>
                                        <label htmlFor="price" className="w-100 col-form-label pt-0 pb-1">Price <span className='text-danger'>* </span></label>
                                        <input type="number" className="form-control" id="price" min={1} onChange={this.onChange} value={this.state.price} placeholder='Enter price' required />
                                    </div>
                                    <div className='col-sm-6 mb-3 mt-1'>
                                        <label htmlFor="priceId" className="w-100 col-form-label pt-0 pb-1">Price Id <span className='text-danger'>* </span></label>
                                        <input type="text" className="form-control" id="priceId" onChange={this.onChange} value={this.state.priceId} placeholder='Enter Price Id' required />
                                    </div>
                                    <div className='col-12 mb-3 mt-1'>
                                        <label htmlFor="description" className="w-100 col-form-label pt-0 pb-1">Description <span className='text-danger'>* </span><Tooltip TransitionComponent={Zoom} arrow title="Enter few words only" placement="top"><InfoIcon fontSize='small' /></Tooltip></label>
                                        <input type="text" className="form-control" id="description" onChange={this.onChange} value={this.state.description} placeholder='Write a description...' required />
                                    </div>
                                </>
                            }
                            {
                                (this.state.planType === 'datacenter' || this.state.planType === 'isp' || this.state.planType === 'captchaProxies') &&
                                <>
                                    <div className='col-sm-6 mb-3 mt-1'>
                                        <label htmlFor="description" className="w-100 col-form-label pt-0 pb-1">Description <span className='text-danger'>* </span><Tooltip TransitionComponent={Zoom} arrow title="Enter few words only" placement="top"><InfoIcon fontSize='small' /></Tooltip></label>
                                        <input type="text" className="form-control" id="description" onChange={this.onChange} value={this.state.description} placeholder='Write a description...' required />
                                    </div>
                                    <div className='col-sm-6 mb-3 mt-1'>
                                        <label htmlFor="order" className="w-100 col-form-label pt-0 pb-1">Order <span className='text-danger'>* </span><Tooltip TransitionComponent={Zoom} arrow title="Order to display on website" placement="top"><InfoIcon fontSize='small' /></Tooltip></label>
                                        <input type="number" className="form-control" id="order" min={0} onChange={this.onChange} value={this.state.order} placeholder='Enter Order' required />
                                    </div>
                                </>
                            }
                            <div className='col-12 mb-3 mt-1'>
                                <label htmlFor="features" className="w-100 col-form-label pt-0 pb-1">Features <span className='text-danger'>* </span><Tooltip TransitionComponent={Zoom} arrow title="This will be displayed on website's pricing page. Provide HTML text" placement="top"><InfoIcon fontSize='small' /></Tooltip></label>
                                <textarea type="text" rows={6} className="form-control" id="features" onChange={this.onChange} value={this.state.features} placeholder='Write a features (HTML)...' required ></textarea>
                            </div>
                        </div>
                        <div className='d-flex w-100 mb-3 justify-content-space-evenly' style={{ 'gap': '5px', flexWrap: 'wrap', justifyContent: 'space-between' }}>
                            <div className="togglebutton">
                                <label>
                                    <input type="checkbox" data-key={'active'} onChange={this.handleSwitchChange} checked={this.state.active} />
                                    <span className="toggle"></span> Active
                                </label>
                            </div>
                            <div className="togglebutton">
                                <label>
                                    <input type="checkbox" data-key={'hide'} onChange={this.handleSwitchChange} checked={this.state.hide} />
                                    <span className="toggle"></span> Hide
                                </label>
                            </div>
                            <div className="togglebutton">
                                <label>
                                    <input type="checkbox" data-key={'onlybot'} onChange={this.handleSwitchChange} checked={this.state.onlybot} />
                                    <span className="toggle"></span> In-Bot Only
                                </label>
                            </div>
                            {
                                (this.state.planType === 'isp' || this.state.planType === 'nike') &&
                                <div className="togglebutton">
                                    <label>
                                        <input type="checkbox" data-key={'iscombo'} onChange={(this.state.isUpdate && this.state.planType === 'nike') ? null : this.handleSwitchChange} checked={this.state.iscombo} disabled={(this.state.isUpdate && this.state.planType === 'nike') ? true : false} />
                                        <span className="toggle"></span> Is Combo
                                    </label>
                                </div>
                            }
                            {
                                this.state.planType === 'server' &&
                                <div className="togglebutton">
                                    <label>
                                        <input type="checkbox" data-key={'daily'} onChange={this.handleSwitchChange} checked={this.state.daily} />
                                        <span className="toggle"></span> Daily
                                    </label>
                                </div>
                            }
                            {
                                this.state.planType !== 'gmail' && this.state.planType !== 'server' && this.state.planType !== 'nike' &&
                                <>
                                    <div className="togglebutton">
                                        <label>
                                            <input type="checkbox" data-key={'bulk'} onChange={this.handleSwitchChange} checked={this.state.bulk} />
                                            <span className="toggle"></span> Bulk
                                        </label>
                                    </div>
                                    <div className="togglebutton">
                                        <label>
                                            <input type="checkbox" data-key={'daily'} onChange={this.handleSwitchChange} checked={this.state.daily} />
                                            <span className="toggle"></span> Daily
                                        </label>
                                    </div>
                                    <div className="togglebutton">
                                        <label>
                                            <input type="checkbox" data-key={'weekly'} onChange={this.handleSwitchChange} checked={this.state.weekly} />
                                            <span className="toggle"></span> Weekly
                                        </label>
                                    </div>
                                </>
                            }
                            {
                                this.state.planType === 'residential' &&
                                <div className="togglebutton">
                                    <label>
                                        <input type="checkbox" data-key={'topup'} onChange={this.handleSwitchChange} checked={this.state.topup} />
                                        <span className="toggle"></span> Topup
                                    </label>
                                </div>
                            }
                        </div>
                        {
                            !this.state.isUpdate &&
                            <div className='w-100 text-danger text-center'>
                                <h6 style={{ textTransform: 'none' }}>Please confirm your plan information with developer after submit</h6>
                            </div>
                        }
                        <div className="row">
                            <div className="col-sm-12 text-end">
                                <div className="d-inline-block mt-4 me-2">
                                    <a href="#" className="mb-3 px-5 cta-white" onClick={this.cancelClicked}>Cancel</a>
                                </div>
                                <div className="form-cta-btn d-inline-block mt-4">
                                    <a href="#" className="mb-3 px-5 text-light" style={{ 'boxShadow': '0px 6px 8px 3px #2c3d7926' }} onClick={this.saveClicked}>{this.state.isUpdate ? 'Update' : 'Create'}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </Modal>
        );
    }
}

export default withRouter(AddPlanModal);