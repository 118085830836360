import React, { Component } from 'react';
import { Modal } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import Select from 'react-select';
import { showToastAlert } from '../Services/Utility';


class AddSubPlanModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            planType: props.planType,
            allPlans: props.allPlans,
            isUpdate: props.isUpdate,
            showModal: props.showModal || false,
            subplanId: props.isUpdate ? props.subplanData['subplanId'] : '',
            price: props.isUpdate ? props.subplanData['price'] : '',
            category: props.isUpdate ? props.subplanData['category'] : '',
            active: props.isUpdate ? props.subplanData['active'] : false,
            hide: props.isUpdate ? props.subplanData['hide'] : false,
            priceId: props.isUpdate ? (props.subplanData['priceId'] || '') : '',
            selectedCateogry: null,
            quantity: '',
            dataLimit: '',
            selectedNikeIsp: null
        };

        if (this.state.planType !== 'gmail' && this.state.planType !== 'nike') {
            this.categories = Object.keys(props.categories).map(x => ({ 'label': `${props.categories[x].name} - ${props.categories[x].validityHours} Hrs`, 'value': x }))
        }
        if (this.state.planType === 'nike') {
            this.nikeIspOptions = Object.keys(this.state.allPlans.isp).filter(key => this.state.allPlans.isp[key].isCombo).flatMap(key => Object.keys(this.state.allPlans.isp[key].subPlans).map(subKey => ({ 'label': `${this.state.allPlans.isp[key].name} - ${key} - ${subKey}`, 'value': `${key}:${subKey}` })));
        }

        if (props.isUpdate) {
            this.state.planId = props.subplanData['planId'];
            if (this.state.planType !== 'gmail' && this.state.planType !== 'nike') {
                this.state.selectedCateogry = this.categories.find(x => x.value === this.state.category) || {};
                if (props.planType === 'datacenter' || props.planType === 'isp' || props.planType === 'captchaProxies') {
                    this.state.dataLimit = props.subplanData['numberOfProxies'];
                }
                else {
                    this.state.dataLimit = props.subplanData['dataLimitMB'];
                }
                this.state.isRenewable = props.subplanData['isRenewable'] !== undefined ? props.subplanData['isRenewable'] : true;
            }
            else {
                this.state.quantity = props.subplanData['subplanId'];
                if (props.subplanData.isp) {
                    this.state.selectedNikeIsp = { 'label': `${this.state.allPlans.isp[props.subplanData.isp.planId].name} - ${props.subplanData.isp.planId} - ${props.subplanData.isp.subPlanId}`, 'value': `${props.subplanData.isp.planId}:${props.subplanData.isp.subPlanId}` };
                }
            }
        }
        else {
            this.state.isRenewable = true;
            this.state.planId = props.planId;
        }
        this.closeModal = this.closeModal.bind(this);
    }

    closeModal = () => {
        this.setState({
            showModal: false
        });
    }

    onChange = (e) => {
        e.persist();
        this.setState({
            [e.target.id]: e.target.value
        });
    }

    handleCategoryChange = (selectedOption) => {
        this.setState({ ['category']: selectedOption.value || '', ['selectedCateogry']: selectedOption || {} });
    };

    handleNikeIspChange = (selectedOption) => {
        this.setState({ ['selectedNikeIsp']: selectedOption || null });
    };

    handleSwitchChange = async (e) => {
        e.persist();
        const key = e.target.dataset.key;
        this.setState({ [key]: e.target.checked });
    }

    cancelClicked = async (event) => {
        this.closeModal();
    }

    saveClicked = async (event) => {

        if (this.state.planType === 'gmail' || this.state.planType === 'nike') {
            const qty = parseInt(this.state.quantity) || 0;
            if (isNaN(qty) || qty === 0) {
                return showToastAlert('Error', 'Please enter valid quantity');
            }
            this.state.quantity = parseInt(this.state.quantity);
        }
        else {
            if (this.state.subplanId.trim() === '') {
                return showToastAlert('Error', 'Please provide id');
            }
            if (this.state.dataLimit === '') {
                if (this.state.planType === 'datacenter' || this.state.planType === 'isp' || this.state.planType === 'captchaProxies') {
                    return showToastAlert('Error', 'Please enter number of proxies');
                }
                else {
                    return showToastAlert('Error', 'Please enter data limit');
                }
            }
            if (this.state.category === '') {
                return showToastAlert('Error', 'Please select validity');
            }
            if ((this.state.planType === 'datacenter' || this.state.planType === 'isp' || this.state.planType === 'captchaProxies') && this.state.priceId === '') {
                return showToastAlert('Error', 'Please provide priceId created in stripe');
            }
        }
        if (this.state.price === '') {
            return showToastAlert('Error', 'Please provide price');
        }

        if (this.state.planType !== 'gmail' && this.state.planType !== 'nike') {
            const subplanId = this.state.subplanId.trim().split(' ').join('_').toLowerCase();
            let data = {
                'price': parseFloat(this.state.price),
                'active': this.state.active,
                'hide': this.state.hide,
                'isRenewable': this.state.isRenewable,
                'subplanId': subplanId,
                'category': this.state.category
            }
            if (this.state.planType === 'datacenter' || this.state.planType === 'isp' || this.state.planType === 'captchaProxies') {
                data['numberOfProxies'] = parseInt(this.state.dataLimit);
                data['priceId'] = this.state.priceId;
            }
            else {
                data['dataLimitMB'] = parseInt(this.state.dataLimit);
            }
            await this.props.firebase.db.ref(`plans/${this.state.planType}/${this.state.planId}/subPlans/${subplanId}`).update(data);
        }
        else {
            let data = { 'price': parseFloat(this.state.price) }
            if (this.state.planType === 'nike' && this.state.allPlans.nike[this.state.planId].isCombo) {
                if (!this.state.selectedNikeIsp) {
                    return showToastAlert('Error', 'Please select ISP Plan for combo proxies');
                }
                const keys = this.state.selectedNikeIsp.value.split(':');
                data['isp'] = { 'planId': keys[0], 'subPlanId': keys[1] }
            }
            else {
                data['isp'] = null;
            }
            await this.props.firebase.db.ref(`plans/${this.state.planType}/${this.state.planId}/quantity/${this.state.quantity}`).update(data);
        }
        this.props.successCallback();
        this.closeModal();
    }

    render() {
        const { showModal } = this.state;
        return (
            <Modal
                isOpen={showModal}
                toggle={this.closeModal}
                onClosed={this.closeModal}
                className=""
                size="md"
                backdrop="static"
                centered
            >
                <div className="modal-body">
                    <div className="pop-bg-img">
                        <img src={require("assets/images/pop-bg.svg")} alt="" />
                    </div>
                    <button
                        type="button"
                        className="btn-close pop-close-btn"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        onClick={this.closeModal}
                    />
                    <div className="dash-head">
                        <img src={require("assets/images/proxy-gen.svg")} alt="" /> <h4 className="mb-0">{this.state.isUpdate ? 'Update Subplan' : 'Create Subplan'}</h4>
                    </div>

                    <div className="form-field px-2">
                        {
                            (this.state.planType === 'gmail' || this.state.planType === 'nike') &&
                            <div className="form-group row mb-3 d-flex align-items-center">
                                <label htmlFor="quantity" className="col-sm-5 col-form-label py-0">Quantity <span className='text-danger'>*</span></label>
                                <div className="col-sm-7">
                                    <input type="number" min={1} className="form-control" id="quantity" onChange={this.state.isUpdate ? null : this.onChange} value={this.state.quantity} placeholder='Enter Quantity' required disabled={this.state.isUpdate} />
                                </div>
                            </div>
                        }
                        {
                            this.state.planType !== 'gmail' && this.state.planType !== 'nike' &&
                            <>
                                <div className="form-group row mb-3 d-flex align-items-center">
                                    <label htmlFor="subplanId" className="col-sm-5 col-form-label py-0">Subplan Id <span className='text-danger'>*</span></label>
                                    <div className="col-sm-7">
                                        <input type="text" className="form-control" id="subplanId" onChange={this.state.isUpdate ? null : this.onChange} value={this.state.subplanId} placeholder='Enter id' required disabled={this.state.isUpdate} />
                                    </div>
                                </div>
                                <div className="form-group row mb-3 d-flex align-items-center">
                                    <label htmlFor="dataLimit" className="col-sm-5 col-form-label py-0">{(this.state.planType === 'datacenter' || this.state.planType === 'isp' || this.state.planType === 'captchaProxies') ? 'Number of Proxies' : 'Data Limit (MB) '}<span className='text-danger'>*</span></label>
                                    <div className="col-sm-7">
                                        <input type="number" min={1} className="form-control" id="dataLimit" onChange={this.onChange} value={this.state.dataLimit} placeholder={(this.state.planType === 'datacenter' || this.state.planType === 'isp' || this.state.planType === 'captchaProxies') ? 'Enter no. of proxy limit' : 'Enter data limit in MB '} required />
                                    </div>
                                </div>
                                <div className="form-group row mb-2 d-flex align-items-center">
                                    <label htmlFor="validity" className="col-sm-5 col-form-label py-0">Validity <span className='text-danger'>*</span></label>
                                    <div className="col-sm-7">
                                        <Select className="select" id="validity" options={this.categories} onChange={this.handleCategoryChange} value={this.state.selectedCateogry} placeholder="Select validity" required />
                                    </div>
                                </div>
                            </>
                        }
                        <div className="form-group row mb-3 d-flex align-items-center">
                            <label htmlFor="price" className="col-sm-5 col-form-label py-0">Price <span className='text-danger'>*</span></label>
                            <div className="col-sm-7">
                                <input type="number" min={1} className="form-control" id="price" onChange={this.onChange} value={this.state.price} placeholder='Enter price' required />
                            </div>
                        </div>
                        {
                            (this.state.planType === 'datacenter' || this.state.planType === 'isp' || this.state.planType === 'captchaProxies') &&
                            <div className="form-group row mb-3 d-flex align-items-center">
                                <label htmlFor="priceId" className="col-sm-5 col-form-label py-0">price Id <span className='text-danger'>*</span></label>
                                <div className="col-sm-7">
                                    <input type="text" className="form-control" id="priceId" onChange={this.onChange} value={this.state.priceId} placeholder='Enter price id' required />
                                </div>
                            </div>
                        }
                        {
                            (this.state.planType === 'nike' && this.state.allPlans.nike[this.state.planId].isCombo) &&
                            <div className="form-group row mb-3 d-flex align-items-center">
                                <label htmlFor="quantity" className="col-sm-5 col-form-label py-0">ISP Plan <span className='text-danger'>*</span></label>
                                <div className="col-sm-7">
                                    <Select className="select" id="nikeIsp" options={this.nikeIspOptions} onChange={this.handleNikeIspChange} value={this.state.selectedNikeIsp} placeholder="Select ISP Sub Plan" required />
                                </div>
                            </div>
                        }
                        {
                            this.state.planType !== 'gmail' && this.state.planType !== 'nike' &&
                            <div className='d-flex right-switch mb-3' style={{ 'gap': '10px' }}>
                                <div className="togglebutton">
                                    <label>
                                        <input type="checkbox" data-key={'active'} onChange={this.handleSwitchChange} checked={this.state.active} />
                                        <span className="toggle"></span> Active
                                    </label>
                                </div>
                                <div className="togglebutton">
                                    <label>
                                        <input type="checkbox" data-key={'hide'} onChange={this.handleSwitchChange} checked={this.state.hide} />
                                        <span className="toggle"></span> Hide
                                    </label>
                                </div>
                                <div className="togglebutton">
                                    <label>
                                        <input type="checkbox" data-key={'isRenewable'} onChange={this.handleSwitchChange} checked={this.state.isRenewable} />
                                        <span className="toggle"></span> Renewable
                                    </label>
                                </div>
                            </div>
                        }
                        <div className="row">
                            <div className="col-sm-12 text-end">
                                <div className="d-inline-block mt-4 me-2">
                                    <a href="#" className="mb-3 px-5 cta-white" onClick={this.cancelClicked}>Cancel</a>
                                </div>
                                <div className="form-cta-btn d-inline-block mt-4">
                                    <a href="#" className="mb-3 px-5 text-light" style={{ 'boxShadow': '0px 6px 8px 3px #2c3d7926' }} onClick={this.saveClicked}>{this.state.isUpdate ? 'Update' : 'Create'}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </Modal>
        );
    }
}

export default withRouter(AddSubPlanModal);