import React, { Component } from 'react';
import { Modal } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import * as request from 'request';
import { showToastAlert } from '../Services/Utility';


class DisplayIpsModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: props.showModal || false,
            ips: props.ips || [],
            username: props.username || '',
            password: props.password || '',
            planType: props.planType,
            authToken: props.authToken,
            data: props.data || {}
        };
        this.closeModal = this.closeModal.bind(this);
    }

    closeModal = () => {
        this.setState({
            showModal: false
        });
    }

    cancelClicked = async (event) => {
        this.closeModal();
    }

    swapClicked = async (event) => {
        const idToken = await this.props.firebase.auth.currentUser.getIdToken(true);
        const data = { idToken: idToken, authToken: this.state.authToken }

        this.props.setLoading(true);
        const apiData = await this.swapAPICall(data);
        this.props.setLoading(false);

        if (apiData !== null) {
            this.props.successCallback(apiData);
            showToastAlert('success', 'Ips swapped successfully');
            this.closeModal();
        }
    }

    swapAPICall = async (data) => {
        return new Promise((resolve, reject) => {
            let options = {
                method: 'POST',
                url: `${process.env.REACT_APP_API_URL}/${this.state.planType}/refresh`,
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            };
            return request(options, (error, response, body) => {
                let resData = body && JSON.parse(body);
                if (error || response.statusCode !== 200) {
                    let errorMessage = error ? error : resData ? resData.message : 'Error occurred while swapping Ips!';
                    showToastAlert('Error', errorMessage);
                    return resolve(null);
                } else {
                    return resolve(resData || {});
                }
            });
        });
    }

    render() {
        const { showModal, ips, username, password, planType, data } = this.state;
        return (
            <Modal
                isOpen={showModal}
                toggle={this.closeModal}
                onClosed={this.closeModal}
                className=""
                size="md"
                backdrop="static"
                centered
            >
                <div className="modal-body">
                    <div className="pop-bg-img">
                        <img src={require("assets/images/pop-bg.svg")} alt="" />
                    </div>
                    <button
                        type="button"
                        className="btn-close pop-close-btn"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        onClick={this.closeModal}
                    />
                    <div className="dash-head mb-3">
                        <img src={require("assets/images/purchase-head.svg")} alt="" /> <h4 className="mb-0">{planType === 'server' ? 'Server' : 'IPs'}</h4>
                    </div>

                    <div className={"form-field px-2"}>
                        <div className='col-12 mb-3 mt-1'>
                            {
                                planType === 'server' &&
                                <div style={{ fontWeight: '700', color: '#103A8D' }}>
                                    <p>Username: <span style={{ color: 'black' }}>{data.username}</span></p>
                                    <p>Password: <span style={{ color: 'black' }}>{data.password}</span></p>
                                    <p>Hostname: <span style={{ color: 'black' }}>{data.hostname}</span></p>
                                    <p>Dedicated IP: <span style={{ color: 'black' }}>{data.dedicatedip}</span></p>
                                </div>
                            }
                            {
                                planType !== 'server' &&
                                <textarea type="text" rows={10} className="form-control" value={ips.map(x => x + ':' + username + ':' + password).join('\r\n')} ></textarea>
                            }
                        </div>
                        <div className="row">
                            <div className="col-sm-12 text-end">
                                <div className="d-inline-block mt-4 me-2">
                                    <a href="#" className="mb-3 px-5 cta-white" onClick={this.cancelClicked}>Cancel</a>
                                </div>
                                {
                                    planType !== 'server' &&
                                    <div className="form-cta-btn d-inline-block mt-4">
                                        <a href="#" className="mb-3 px-5 text-light" onClick={this.swapClicked}>Swap</a>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>

            </Modal>
        );
    }
}

export default withRouter(DisplayIpsModal);