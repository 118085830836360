import React, { useState, useEffect, useContext } from 'react';
import * as request from 'request';
import { useHistory } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import memoize from 'memoize-one';
import { FirebaseContext } from 'contexts/Firebase';
import { PageLoader, showToastAlert, dateFormate } from 'Services/Utility';
import Delete from '@mui/icons-material/Delete';
import AddModerator from '@mui/icons-material/AddModerator';
import UpdateIcon from '@mui/icons-material/Update';
import Navbar from 'components/Navbar';
import Swal from 'sweetalert2';
import CreatePlanModal from 'Modals/CreatePlanModal';
import UpdatePlanModel from 'Modals/UpdatePlanModel';


const paymetnsColumns = [
    {
        name: 'Token',
        selector: row => row.authToken || '-',
        sortable: true,
    },
    {
        name: 'Email',
        selector: row => row.email || '-',
        sortable: true,
    },
    {
        name: 'Order',
        selector: row => row.order || '-',
        sortable: true,
    },
    {
        name: 'Paid',
        selector: row => row.paidAmount || '',
        sortable: true,
    },
    {
        name: 'Discount',
        selector: row => row.discontAmount || '',
        sortable: true,
    },
    {
        name: 'Stripe',
        cell: (row) => {
            if (row.paymentId !== 'pi_flipd_extra' && row.paymentId !== 'pi_manual_payment' && row.paymentId !== 'pi_migrated') {
                const href = 'https://dashboard.stripe.com/payments/' + row.paymentId;
                return <a href={href} target="_blank">{row.paymentId}</a>
            }
            return row.paymentId;
        },
        sortable: false,
    },
    {
        name: 'Date',
        selector: row => row.createdAt ? dateFormate(row.createdAt) : '',
        sortable: true,
        sortFunction: (rowA, rowB) => {
            if (rowA.createdAt && rowB.createdAt) {
                if (rowA.createdAt < rowB.createdAt) {
                    return -1;
                }
                else if (rowA.createdAt > rowB.createdAt) {
                    return 1;
                }
            }
            return 0;
        }
    },
];

const proxiesColumns = memoize((updateHandler, deleteHandler) => [
    {
        name: 'Token',
        selector: row => row.name || '-',
        sortable: true,
    },
    {
        name: 'Email',
        selector: row => row.email || '-',
        sortable: true,
    },
    {
        name: 'Data Limit',
        selector: row => row.limitGB ? row.limitGB + ' GB' : '-',
        sortable: true,
    },
    {
        name: 'Data Used',
        selector: row => row.dataUsage ? row.dataUsage + ' GB' : '-',
        sortable: true,
    },
    {
        name: 'Validity',
        selector: row => row.validity ? row.validity + ' Days' : '',
        sortable: true,
    },
    {
        name: 'Status',
        selector: row => row.status || '',
        sortable: true,
    },
    {
        name: 'Created',
        selector: row => dateFormate(row.createDate),
        sortable: true,
        sortFunction: (rowA, rowB) => {
            if (rowA.createDate < rowB.createDate) {
                return -1;
            }
            else if (rowA.createDate > rowB.createDate) {
                return 1;
            }
            return 0;
        }
    },
    {
        name: 'Action',
        cell: (row) => (<><button className='icon-btn add' onClick={e => updateHandler(row.name, row.dataLimitMegaBytes, row.validityHours)}><UpdateIcon /></button> <button className='icon-btn delete' onClick={e => deleteHandler(row.name)}><Delete /></button></>),
        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
        sortable: false,
    },
]);


const Premium = (props) => {

    const { user, firebase } = useContext(FirebaseContext);
    const history = useHistory();
    const [filterPaymentText, setFilterPaymentText] = useState('');
    const [filterProxyText, setFilterProxyText] = useState('');
    const [pending, setPending] = useState(true);
    const [loading, setLoading] = useState(true);
    const [basicNetSales, setBasicNetSales] = useState(0);
    const [basicNetDiscounts, setBasicNetDiscounts] = useState(0);
    const [allBasicPayments, setAllBasicPayments] = useState([]);
    const [allBasicProxies, setAllBasicProxies] = useState([]);
    const [resiPlansOptions, setResiPlansOptions] = useState([]);
    const [createPlanModals, setCreatePlanModals] = useState([]);
    const [updatePlanModels, setUpdatePlanModels] = useState([]);

    const filteredPayments = allBasicPayments.filter(
        item => (item.authToken && item.authToken.toLowerCase().includes(filterPaymentText.toLowerCase())) || (item.email && item.email.toLowerCase().includes(filterPaymentText.toLowerCase())) || (item.paymentId && item.paymentId.toLowerCase().includes(filterPaymentText.toLowerCase()))
    );
    const filteredProxies = allBasicProxies.filter(
        item => (item.name && item.name.toLowerCase().includes(filterProxyText.toLowerCase())) || (item.email && item.email.toLowerCase().includes(filterProxyText.toLowerCase())) || (item.status && item.status.toLowerCase().includes(filterProxyText.toLowerCase()))
    );

    useEffect(() => {
        document.body.classList.add('dash-bg');
        document.body.classList.add('plan-price-bg');
    }, []);

    const getBasicData = (idToken, cache) => {
        return new Promise((resolve, reject) => {
            let options = {
                method: 'POST',
                url: `${process.env.REACT_APP_API_URL}/premium/getPaymentDetails?cache=${cache}`,
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    'idToken': idToken
                })
            };

            return request(options, (error, response, body) => {
                let resData = body && JSON.parse(body);
                if (error || response.statusCode !== 200) {
                    let errorMessage = error ? error : resData ? resData.message : 'A server error occurred while fetching premium plan data!';
                    showToastAlert('Error', errorMessage);
                    resolve({});
                } else {
                    return resolve(resData);
                }
            });
        });
    }

    const fetchPlans = async () => {
        let plans = (await firebase.db.ref('plans/residential').once('value')).val();
        plans = { ...plans };
        const categories = plans['categories'];
        delete plans['categories'];
        delete plans['active'];
        let planOptions = [];
        for (const planId in plans) {
            const isBulk = plans[planId].bulk ? true : false;
            const isDaily = plans[planId].daily ? true : false;
            let appendType = '';
            if (isBulk && isDaily) appendType = ' - Daily Bulk';
            if (isBulk && !isDaily) appendType = ' - Bulk';
            if (!isBulk && isDaily) appendType = ' - Daily';
            if (plans[planId]['provider'] === 'premium') {
                const subPlans = plans[planId]['subPlans'] || {};
                for (const subPlanId in subPlans) {
                    const detail = subPlans[subPlanId];
                    const catId = detail['category'];
                    const categoryName = categories[catId]['name'];
                    planOptions.push({ 'label': `${detail['dataLimitMB'] / 1000} GB - ${categoryName}${appendType}`, 'value': `${subPlanId}:${planId}` });
                }
            }
        }
        setResiPlansOptions(planOptions);
    }

    const updatePaymentTable = (payments) => {
        let paymentDetails = [], netSales = 0.0, netDiscounts = 0.0;
        // setUsers(userData || []);
        payments.forEach((item, i) => {
            if (item.planType === 'residential' && item.provider === 'premium') {
                // let userDetail = userData.find(detail => detail.uid === item['uid']);
                // let email = userDetail ? userDetail['email'] : '-';
                let email = item['userEmail'] || '-';
                let authToken = item['authToken'];
                let paymentId = item['paymentToken'];
                let order = item['dataLimit'] + ' GB';
                order = item['renewEntry'] ? order + ' Topup' : order;

                let planPrice = parseFloat(item['planPrice']);
                let paidAmount = planPrice;
                if (item['paid']) {
                    paidAmount = parseFloat(item['paid']);
                }

                let discontAmount = parseFloat(planPrice) - parseFloat(paidAmount);
                discontAmount = discontAmount < 0 ? 0 : discontAmount;

                if (paymentId && paymentId !== 'pi_flipd_extra' && paymentId !== 'pi_manual_payment' && paymentId !== 'pi_migrated') {
                    netSales = parseFloat(netSales) + paidAmount;
                    netDiscounts = parseFloat(netDiscounts) + parseFloat(discontAmount);
                }

                if (paymentId && (paymentId === 'pi_flipd_extra' || paymentId === 'pi_manual_payment')) {
                    paidAmount = 0;
                }

                const isBulk = `${item.bulk}` === 'true';
                const isDaily = `${item.daily}` === 'true';
                let appendType = '';
                if (isBulk && isDaily) appendType = ' - Daily Bulk';
                if (isBulk && !isDaily) appendType = ' - Bulk';
                if (!isBulk && isDaily) appendType = ' - Daily';

                let createdAt = new Date(parseInt(item['createdAt']) * 1000);
                paymentDetails.push({
                    authToken,
                    email: email ? email : '-',
                    order: order + appendType,
                    paidAmount: paidAmount.toFixed(2),
                    discontAmount: discontAmount.toFixed(2),
                    paymentId,
                    createdAt
                });
            }
        });
        setAllBasicPayments(paymentDetails);
        setBasicNetSales(parseFloat(netSales).toFixed(2));
        setBasicNetDiscounts(parseFloat(netDiscounts).toFixed(2));
    }

    const updateProxyDetails = (proxies) => {
        let proxyDetails = [];
        proxies.forEach((item, i) => {
            if (item) {
                proxyDetails.push({
                    name: item.name,
                    email: item.email ? item.email : '-',
                    dataLimitMegaBytes: item.dataLimitMegaBytes,
                    validityHours: item.validityHours,
                    limitGB: parseFloat((parseInt(item.dataLimitMegaBytes) / 1000).toFixed(2)),
                    dataUsage: parseFloat((parseInt(item.dataUsage) / 1000000000).toFixed(2)),
                    validity: parseInt(parseInt(item.validityHours) / 24),
                    status: item.status,
                    createDate: new Date(item.createDate)
                })
            }
        });
        setAllBasicProxies(proxyDetails);
    }

    const refreshList = async () => {
        const idToken = await firebase.auth.currentUser.getIdToken(true);
        const basicData = await getBasicData(idToken, false);
        updatePaymentTable(basicData.data || []);
        updateProxyDetails(basicData.allProxies || []);
    }

    const removeProxyToken = (idToken, token) => {
        return new Promise((resolve, reject) => {
            let options = {
                method: 'POST',
                url: `${process.env.REACT_APP_API_URL}/premium/deletePlan`,
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    'idToken': idToken,
                    'authToken': token
                })
            };
            return request(options, (error, response, body) => {
                let resData = body && JSON.parse(body);
                if (error || response.statusCode !== 200) {
                    let errorMessage = error ? error : resData ? resData.message : 'Error occurred while delete plan!';
                    reject(errorMessage);
                } else {
                    return resolve(resData.allProxies || []);
                }
            });
        });
    }

    const handleDeleteClick = (token) => {
        Swal.fire({
            html: 'Are you sure you want to delete <strong>' + token + '</strong> ?<br /><sub>You will not be able to revert this</sub>',
            icon: 'question',
            color: '#103a8d',
            showCancelButton: true,
            confirmButtonText: 'Delete',
            reverseButtons: true
        }).then(async (result) => {
            if (result.value) {
                try {
                    setLoading(true);
                    const idToken = await firebase.auth.currentUser.getIdToken(true);
                    const allProxies = await removeProxyToken(idToken, token);
                    updateProxyDetails(allProxies || []);
                    showToastAlert('success', 'Proxy deleted successfully');
                }
                catch (err) {
                    showToastAlert('Error', err.message ? err.message : err);
                }
                setLoading(false);
            }
        });
    };

    const handleUpdateClick = (token, dataLimit, validity) => {
        setUpdatePlanModels([]);
        setUpdatePlanModels([
            <UpdatePlanModel
                showModal={true}
                key={Math.random()}
                firebase={firebase}
                setLoading={setLoading}
                successCallback={planUpdateCallback}
                authToken={token}
                dataLimit={dataLimit}
                validity={validity}
                provider={'premium'}
                planType={'residential'}
            />
        ]);
    };

    const handleAddProxyClick = () => {
        if (resiPlansOptions.length > 0) {
            setCreatePlanModals([]);
            setCreatePlanModals([
                <CreatePlanModal
                    showModal={true}
                    key={Math.random()}
                    firebase={firebase}
                    setLoading={setLoading}
                    successCallback={planCreateCallback}
                    resiPlansOptions={resiPlansOptions}
                    provider={'premium'}
                    planType={'residential'}
                />
            ]);
        }
    };

    const planCreateCallback = (response) => {
        updatePaymentTable(response.data || []);
        updateProxyDetails(response.allProxies || []);
    }

    const planUpdateCallback = (response) => {
        updateProxyDetails(response.allProxies || []);
    }

    useEffect(() => {
        (async function () {
            firebase.auth.onAuthStateChanged(async (user) => {
                try {
                    if (!user) {
                        history.push('/login');
                        return;
                    }
                    setLoading(false);

                    fetchPlans();
                    const idToken = await firebase.auth.currentUser.getIdToken(true);
                    const basicData = await getBasicData(idToken, true);

                    updatePaymentTable(basicData.data || []);
                    updateProxyDetails(basicData.allProxies || []);

                    setPending(false);

                } catch (error) {
                    console.log(error);
                    showToastAlert('Error', error.message ? error.message : error);
                    setPending(false);
                    setLoading(false);
                }
            });
        })();
    }, [history, firebase.auth]);

    return (
        <>
            {loading && <PageLoader />}
            <div id="page-content-wrapper" className="extra-p">
                <Navbar toggleSideMenu={props.toggleSideMenu} refreshList={refreshList} />

                <div className="container">
                    <div className='saleDiv mb-4'>
                        <div className='mt-0 mb-2'>
                            <div className="dash-box d-flex" style={{ flexDirection: 'column' }}>
                                <h5 className="my-0 text-left w-100" style={{ 'color': 'var(--main-blue)' }}>Net Sales</h5>
                                <h3 className="mt-2 mb-1 text-center w-100 font-weight-bold" style={{ 'color': '#52D987' }}>$ {basicNetSales}</h3>
                            </div>
                        </div>
                        <div className='mt-0 mb-2'>
                            <div className="dash-box d-flex" style={{ flexDirection: 'column' }}>
                                <h5 className="my-0 text-left w-100" style={{ 'color': 'var(--main-blue)' }}>Net Discounts</h5>
                                <h3 className="mt-2 mb-1 text-center w-100 font-weight-bold" style={{ 'color': '#FF7170' }}>$ {basicNetDiscounts}</h3>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-12 mb-2'>
                            <div className="dash-box position-relative">
                                <div className="row" style={{ 'paddingTop': '30px' }}>
                                    <div className="col-12 dash-head my-0">
                                        <div className='d-flex' style={{ 'gap': '20px' }}>
                                            <h4 className="my-0" style={{ 'color': 'var(--main-blue)' }}>Premium Orders</h4>
                                        </div>
                                        <div className="search-box"> <input className="search" type="text" placeholder='Search Record...' value={filterPaymentText} onChange={e => setFilterPaymentText(e.target.value)} /><img src={require("assets/images/search.svg")} alt="" /></div>
                                    </div>
                                    <div className="col-12 mt-3">
                                        <div className="table-responsive">
                                            <DataTable columns={paymetnsColumns} data={filteredPayments} defaultSortFieldId={7} defaultSortAsc={false} persistTableHead pagination paginationRowsPerPageOptions={[10, 20, 50, 100]} paginationComponentOptions={{ rowsPerPageText: 'Show entries' }} progressPending={pending} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-12'>
                            <div className="dash-box position-relative">
                                <div className="row" style={{ 'paddingTop': '30px' }}>
                                    <div className="col-12 dash-head my-0">
                                        <div className='d-flex' style={{ 'gap': '20px' }}>
                                            <h4 className="my-0" style={{ 'color': 'var(--main-blue)' }}>Premium Proxies</h4>
                                            <button className='icon-btn add' onClick={e => handleAddProxyClick()}><AddModerator /></button>
                                        </div>
                                        <div className="search-box"> <input className="search" type="text" placeholder='Search Record...' value={filterProxyText} onChange={e => setFilterProxyText(e.target.value)} /><img src={require("assets/images/search.svg")} alt="" /></div>
                                    </div>
                                    <div className="col-12 mt-3">
                                        <div className="table-responsive">
                                            <DataTable columns={proxiesColumns(handleUpdateClick, handleDeleteClick)} data={filteredProxies} defaultSortFieldId={7} defaultSortAsc={false} persistTableHead pagination paginationRowsPerPageOptions={[10, 20, 50, 100]} paginationComponentOptions={{ rowsPerPageText: 'Show entries' }} progressPending={pending} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {createPlanModals.map(modal => (modal))}
            {updatePlanModels.map(modal => (modal))}
        </>
    )
}

export default Premium;