import React, { useState, useEffect, useContext } from 'react';
import * as request from 'request';
import { useHistory } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import memoize from 'memoize-one';
import { FirebaseContext } from 'contexts/Firebase';
import { PageLoader, showToastAlert, dateFormate } from 'Services/Utility';
import Navbar from 'components/Navbar';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DisplayIpsModal from 'Modals/DisplayIpsModal';

const paymentsColumns = memoize((displayIpsHandler) => [
    {
        name: 'Token',
        selector: row => row.authToken || '-',
        sortable: true,
    },
    {
        name: 'Email',
        selector: row => row.email || '-',
        sortable: true,
    },
    {
        name: 'Order',
        selector: row => row.order || '-',
        sortable: true,
    },
    {
        name: 'Paid',
        selector: row => row.paidAmount || '',
        sortable: true,
    },
    {
        name: 'Discount Code',
        selector: row => row.code || '',
        sortable: true,
    },
    {
        name: 'Stripe',
        cell: (row) => {
            if (row.paymentId !== 'pi_flipd_extra' && row.paymentId !== 'pi_manual_payment' && row.paymentId !== 'pi_migrated') {
                const href = 'https://dashboard.stripe.com/payments/' + row.paymentId;
                return <a href={href} target="_blank">{row.paymentId}</a>
            }
            return row.paymentId;
        },
        sortable: false,
    },
    {
        name: 'Date',
        selector: row => row.createdAt ? dateFormate(row.createdAt) : '',
        sortable: true,
        sortFunction: (rowA, rowB) => {
            if (rowA.createdAt && rowB.createdAt) {
                if (rowA.createdAt < rowB.createdAt) {
                    return -1;
                }
                else if (rowA.createdAt > rowB.createdAt) {
                    return 1;
                }
            }
            return 0;
        }
    },
    {
        name: 'Action',
        cell: (row) => (<><button className='icon-btn blue' onClick={e => displayIpsHandler(row)}><VisibilityIcon /></button></>),
        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
        sortable: false,
    },
]);

const Server = (props) => {

    const { user, firebase } = useContext(FirebaseContext);
    const history = useHistory();
    const [filterPaymentText, setFilterPaymentText] = useState('');
    const [pending, setPending] = useState(true);
    const [loading, setLoading] = useState(true);
    const [serverNetSales, setServerNetSales] = useState(0);
    const [serverNetDiscounts, setServerNetDiscounts] = useState(0);
    const [allServerPayments, setAllServerPayments] = useState([]);
    const [serverModals, setServerModals] = useState([]);

    const filteredPayments = allServerPayments.filter(
        item => (item.authToken && item.authToken.toLowerCase().includes(filterPaymentText.toLowerCase())) || (item.email && item.email.toLowerCase().includes(filterPaymentText.toLowerCase())) || (item.paymentId && item.paymentId.toLowerCase().includes(filterPaymentText.toLowerCase())) || (item.code && item.code.toLowerCase().includes(filterPaymentText.toLowerCase()))
    );

    useEffect(() => {
        document.body.classList.add('dash-bg');
        document.body.classList.add('plan-price-bg');
    }, []);

    const getServerPayments = (idToken, cache) => {
        return new Promise((resolve, reject) => {
            let options = {
                method: 'POST',
                url: `${process.env.REACT_APP_API_URL}/server/getPaymentDetails?cache=${cache}`,
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    'idToken': idToken
                })
            };

            return request(options, (error, response, body) => {
                let resData = body && JSON.parse(body);
                if (error || response.statusCode !== 200) {
                    let errorMessage = error ? error : resData ? resData.message : 'A server error occurred while fetching servers data!';
                    showToastAlert('Error', errorMessage);
                    resolve({});
                } else {
                    return resolve(resData);
                }
            });
        });
    }

    const updatePaymentTable = (payments) => {
        let paymentDetails = [], netSales = 0.0, netDiscounts = 0.0;
        payments.forEach((item, i) => {
            let email = item['userEmail'] || '';
            let authToken = item['authToken'];
            let paymentId = item['paymentToken'];
            let order = item['planName'];
            order = item['renewEntry'] ? order + ' Topup' : order;

            let code = item['couponCode'] ? item['couponCode'] : '-';
            let planPrice = parseFloat(item['planPrice']);
            let paidAmount = planPrice;
            if (item['paid']) {
                paidAmount = parseFloat(item['paid']);
            }

            let discontAmount = parseFloat(planPrice) - parseFloat(paidAmount);
            discontAmount = discontAmount < 0 ? 0 : discontAmount;

            if (paymentId && paymentId !== 'pi_flipd_extra' && paymentId !== 'pi_manual_payment' && paymentId !== 'pi_migrated') {
                netSales = parseFloat(netSales) + paidAmount;
                netDiscounts = parseFloat(netDiscounts) + parseFloat(discontAmount);
            }

            if (paymentId && (paymentId === 'pi_flipd_extra' || paymentId === 'pi_manual_payment')) {
                paidAmount = 0;
            }

            const isDaily = `${item.daily}` === 'true';
            if (isDaily) order = order + ' - Daily';

            let createdAt = new Date(parseInt(item['createdAt']) * 1000);
            paymentDetails.push({
                authToken,
                email: email ? email : '-',
                order: order,
                paidAmount: paidAmount.toFixed(2),
                code,
                paymentId,
                createdAt,
                username: item.username,
                hostname: item.hostname,
                password: item.password,
                dedicatedip: item.dedicatedip
            });
        });
        setAllServerPayments(paymentDetails);
        setServerNetSales(parseFloat(netSales).toFixed(2));
        setServerNetDiscounts(parseFloat(netDiscounts).toFixed(2));
    }

    const displayIpsHandler = (data) => {
        setServerModals([]);
        setServerModals([
            <DisplayIpsModal
                showModal={true}
                key={Math.random()}
                firebase={firebase}
                data={data}
                planType={'server'}
            />
        ]);
    };

    useEffect(() => {
        (async function () {
            firebase.auth.onAuthStateChanged(async (user) => {
                try {
                    if (!user) {
                        history.push('/login');
                        return;
                    }
                    setLoading(false);

                    const idToken = await firebase.auth.currentUser.getIdToken(true);
                    const data = await getServerPayments(idToken, true);

                    updatePaymentTable(data.data || []);
                    setPending(false);

                } catch (error) {
                    console.log(error);
                    showToastAlert('Error', error.message ? error.message : error);
                    setPending(false);
                    setLoading(false);
                }
            });
        })();
    }, [history, firebase.auth]);

    return (
        <>
            {loading && <PageLoader />}
            <div id="page-content-wrapper" className="extra-p">
                <Navbar toggleSideMenu={props.toggleSideMenu} />

                <div className="container">
                    <div className='saleDiv mb-4'>
                        <div className='mt-0 mb-2'>
                            <div className="dash-box d-flex" style={{ flexDirection: 'column' }}>
                                <h5 className="my-0 text-left w-100" style={{ 'color': 'var(--main-blue)' }}>Net Sales</h5>
                                <h3 className="mt-2 mb-1 text-center w-100 font-weight-bold" style={{ 'color': '#52D987' }}>$ {serverNetSales}</h3>
                            </div>
                        </div>
                        <div className='mt-0 mb-2'>
                            <div className="dash-box d-flex" style={{ flexDirection: 'column' }}>
                                <h5 className="my-0 text-left w-100" style={{ 'color': 'var(--main-blue)' }}>Net Discounts</h5>
                                <h3 className="mt-2 mb-1 text-center w-100 font-weight-bold" style={{ 'color': '#FF7170' }}>$ {serverNetDiscounts}</h3>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-12 mb-2'>
                            <div className="dash-box position-relative">
                                <div className="row" style={{ 'paddingTop': '30px' }}>
                                    <div className="col-12 dash-head my-0">
                                        <div className='d-flex' style={{ 'gap': '20px' }}>
                                            <h4 className="my-0" style={{ 'color': 'var(--main-blue)' }}>Server Orders</h4>
                                        </div>
                                        <div className="search-box"> <input className="search" type="text" placeholder='Search Record...' value={filterPaymentText} onChange={e => setFilterPaymentText(e.target.value)} /><img src={require("assets/images/search.svg")} alt="" /></div>
                                    </div>
                                    <div className="col-12 mt-3">
                                        <div className="table-responsive">
                                            <DataTable columns={paymentsColumns(displayIpsHandler)} data={filteredPayments} defaultSortFieldId={7} defaultSortAsc={false} persistTableHead pagination paginationRowsPerPageOptions={[10, 20, 50, 100]} paginationComponentOptions={{ rowsPerPageText: 'Show entries' }} progressPending={pending} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {serverModals.map(modal => (modal))}
        </>
    )
}

export default Server;