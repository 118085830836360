import React, { Component } from 'react';
import { Modal } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import Select from 'react-select';
import * as request from 'request';
import { showToastAlert } from '../Services/Utility';


class AssignAccountModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: props.showModal || false,
            selectedPlan: {},
            email: '',
            quantity: '',
            gmailPlansOptions: props.gmailPlansOptions,
            planType: props.planType
        };
        this.closeModal = this.closeModal.bind(this);
    }

    closeModal = () => {
        this.setState({
            showModal: false
        });
    }

    onChange = (e) => {
        e.persist();
        this.setState({
            [e.target.id]: e.target.value
        });
    }

    planSelectionChange = (selectedOption) => {
        this.setState({ ['selectedPlan']: selectedOption || {} })
    };

    cancelClicked = async (event) => {
        this.closeModal();
    }

    saveClicked = async (event) => {
        event.preventDefault();

        if (!this.state.email.trim()) {
            return showToastAlert('Error', 'Please provide user email');
        }
        if (!this.state.selectedPlan.value) {
            return showToastAlert('Error', 'Please select plan');
        }

        const qty = parseInt(this.state.quantity) || 0;
        if (isNaN(qty) || qty === 0) {
            return showToastAlert('Error', 'Please enter valid quantity');
        }
        this.state.quantity = parseInt(this.state.quantity);

        const idToken = await this.props.firebase.auth.currentUser.getIdToken(true);
        const data = {
            idToken: idToken,
            email: this.state.email.trim(),
            quantity: this.state.quantity,
            planId: this.state.selectedPlan.value
        }

        this.props.setLoading(true);
        const planRes = await this.assignAccountAPI(data, this.state.planType);
        this.props.setLoading(false);
        if (planRes !== null) {
            this.props.successCallback(planRes);
            showToastAlert('success', 'Account assigned successfully');
            this.closeModal();
        }
    }

    assignAccountAPI = async (data, plantype) => {
        return new Promise((resolve, reject) => {
            let options = {
                method: 'POST',
                url: `${process.env.REACT_APP_API_URL}/${plantype}/assign`,
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(data)
            };
            return request(options, (error, response, body) => {
                let resData = body && JSON.parse(body);
                if (error || response.statusCode !== 200) {
                    let errorMessage = error ? error : resData ? resData.message : 'Error occurred while creating plan!';
                    showToastAlert('Error', errorMessage);
                    resolve(null);
                } else {
                    resolve(resData || {});
                }
            });
        });
    }

    render() {
        const { showModal } = this.state;
        return (
            <Modal
                isOpen={showModal}
                toggle={this.closeModal}
                onClosed={this.closeModal}
                className=""
                size="md"
                backdrop="static"
                centered
            >
                <div className="modal-body">
                    <div className="pop-bg-img">
                        <img src={require("assets/images/pop-bg.svg")} alt="" />
                    </div>
                    <button
                        type="button"
                        className="btn-close pop-close-btn"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        onClick={this.closeModal}
                    />
                    <div className="dash-head">
                        <img src={require("assets/images/purchase-head.svg")} alt="" /> <h4 className="mb-0">Assign Account</h4>
                    </div>

                    <div className={"form-field px-2 " + this.planType}>
                        <div className="form-group row mb-3 d-flex align-items-center">
                            <label htmlFor="email" className="col-sm-5 col-form-label py-0">User Email Id <span className='text-danger'>*</span></label>
                            <div className="col-sm-7">
                                <input type="email" className="form-control" id="email" onChange={this.onChange} value={this.state.email} placeholder='Enter email address' required />
                            </div>
                        </div>
                        <div className="form-group row mb-3 d-flex align-items-center">
                            <label htmlFor="plan" className="col-sm-5 col-form-label py-0">Select Plan <span className='text-danger'>*</span></label>
                            <div className="col-sm-7">
                                <Select className="select" id="plan" options={this.state.gmailPlansOptions} onChange={this.planSelectionChange} placeholder="Select plan" />
                            </div>
                        </div>
                        <div className="form-group row mb-2 d-flex align-items-center">
                            <label htmlFor="validity" className="col-sm-5 col-form-label py-0">Quantity <span className='text-danger'>*</span></label>
                            <div className="col-sm-7">
                                <input type="number" min={1} className="form-control" id="quantity" onChange={this.onChange} value={this.state.quantity} placeholder='Enter quantity' />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12 text-end">
                                <div className="d-inline-block mt-4 me-2">
                                    <a href="#" className="mb-3 px-5 cta-white" onClick={this.cancelClicked}>Cancel</a>
                                </div>
                                <div className="form-cta-btn d-inline-block mt-4">
                                    <a href="#" className="mb-3 px-5 text-light" style={{ 'boxShadow': '0px 6px 8px 3px #2c3d7926' }} onClick={this.saveClicked}>Assign</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </Modal>
        );
    }
}

export default withRouter(AssignAccountModal);