import React, { Component } from 'react';
import { Modal } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import * as request from 'request';
import { showToastAlert } from '../Services/Utility';

class PoolOrderModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: props.showModal || false,
        };
        this.planType = props.planType;
        this.allPools = props.allPools;
        this.closeModal = this.closeModal.bind(this);
        this.orders = {}
        this.allPools.forEach((pool, idx) => {
            this.orders[pool.myCountry] = `${idx + 1}`;
        })
    }

    closeModal = () => {
        this.setState({
            showModal: false
        });
    }

    onChange = (e) => {
        e.persist();
        this.orders[e.target.id] = e.target.value;
    }

    cancelClicked = async (event) => {
        this.closeModal();
    }

    saveClicked = async (event) => {

        for (const key in this.orders) {
            if (this.orders[key].trim() == '') {
                return showToastAlert('Error', 'Provide index to all pool');
            }
        }

        let newData = {};

        const sortOrders = Object.values(this.orders).map(x => parseInt(x));
        const duplicate = sortOrders.filter((e, i, a) => a.indexOf(e) !== i);
        if (duplicate.length > 0) return showToastAlert('Error', 'Order should be unique');

        let newArr = [...Array(sortOrders.length)];

        sortOrders.forEach((nbr, idx) => {
            const oldPool = this.allPools[idx];
            let dict = {
                'myCountry': oldPool.myCountry,
                'continent': oldPool.continent,
                'tag': oldPool.tag,
                'cc3': oldPool.cc3,
                'flag': oldPool.flag,
                'nports': oldPool.nports,
                'dawnDomain': oldPool.dawnDomain || '',
                'dawnFormat': oldPool.dawnFormat || '',
                'gangaDomain': oldPool.gangaDomain || '',
                'gangaFormat': oldPool.gangaFormat || '',
                'yamunaDomain': oldPool.yamunaDomain || '',
                'yamunaFormat': oldPool.yamunaFormat || ''
            }
            if (oldPool.cc2) {
                dict['cc2'] = oldPool.cc2;
            }
            newArr[nbr - 1] = dict;
        })
        newArr.forEach(pool => {
            newData[pool.myCountry] = pool;
        })

        const idToken = await this.props.firebase.auth.currentUser.getIdToken(true);
        const data1 = { idToken: idToken, pools: newData }

        this.props.setLoading(true);
        const poolsData = await this.createPool(data1);
        this.props.setLoading(false);

        if (poolsData !== null) {
            this.props.successCallback(poolsData);
            showToastAlert('success', 'Pools order updated successfully');
            this.closeModal();
        }
    }

    createPool = async (data) => {
        return new Promise((resolve, reject) => {
            let options = {
                method: 'POST',
                url: `${process.env.REACT_APP_API_URL}/${this.planType}/createPool`,
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            };
            return request(options, (error, response, body) => {
                let resData = body && JSON.parse(body);
                if (error || response.statusCode !== 200) {
                    let errorMessage = error ? error : resData ? resData.message : 'Error occurred while creating pool!';
                    showToastAlert('Error', errorMessage);
                    return resolve(null);
                } else {
                    return resolve(resData || {});
                }
            });
        });
    }

    render() {
        const { showModal } = this.state;
        return (
            <Modal
                isOpen={showModal}
                toggle={this.closeModal}
                onClosed={this.closeModal}
                className=""
                size="md"
                backdrop="static"
                centered
            >
                <div className="modal-body">
                    <button
                        type="button"
                        className="btn-close pop-close-btn"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        onClick={this.closeModal}
                    />
                    <div className="dash-head">
                        <img src={require("assets/images/proxy-gen.svg")} alt="" /> <h4 className="mb-0">{this.isUpdate ? 'Update Pool' : 'Set Pool Order'}</h4>
                    </div>

                    <div className="form-field px-2 pool-order-div">
                        <div className='row' style={{ overflowY: 'scroll', overflowX: 'hidden', maxHeight: '300px' }}>
                            {
                                this.allPools.map((pool, idx) => {
                                    return (
                                        <>
                                            <label htmlFor={pool.myCountry} className="col-8 col-sm-4 col-form-label my-0 pl-2 pr-0 d-flex align-items-center">{pool.myCountry}</label>
                                            <div className="col-4 col-sm-2 my-2 px-2">
                                                <input type="text" className="form-control p-1 text-center" id={pool.myCountry} onChange={this.onChange} defaultValue={this.orders[pool.myCountry]} required />
                                            </div>
                                        </>
                                    )
                                })
                            }
                        </div>

                        <div className="form-field">
                            <div className="row">
                                <div className="col-sm-12 text-end">
                                    <div className="d-inline-block mt-4 me-2">
                                        <a href="#" className="mb-3 px-5 cta-white" onClick={this.cancelClicked}>Cancel</a>
                                    </div>
                                    <div className="form-cta-btn d-inline-block mt-4">
                                        <a href="#" className="mb-3 px-5 text-light" style={{ 'boxShadow': '0px 6px 8px 3px #2c3d7926' }} onClick={this.saveClicked}>Update</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </Modal>
        );
    }
}

export default withRouter(PoolOrderModal);