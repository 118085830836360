import React, { useState, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { FirebaseContext } from 'contexts';
import Email from '@mui/icons-material/Email';
import LockOutlined from '@mui/icons-material/LockOutlined';
import {
    PageLoader,
    showToastAlert
} from '../Services/Utility';


const Login = (props) => {
    const { firebase, setUser } = useContext(FirebaseContext);
    const history = useHistory();
    const [values, setValues] = useState({ email: '', password: '' });
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        (async function () {
            firebase.auth.onAuthStateChanged(async (user) => {
                const cardLogin = document.querySelector('.card-login');
                if (cardLogin) cardLogin.classList.remove('card-hidden');
                if (user) {
                    let isAdmin = (await firebase.db.ref(`users/${user.uid}/admin`).once('value')).val();
                    if (isAdmin) {
                        document.body.classList.remove('login-page');
                        history.push('/', {});
                    }
                    else {
                        firebase.doSignOut();
                        showToastAlert('error', 'You are not an admin');
                    }
                } else {
                    setLoading(false);
                }
            });
        })();

        props.toggleSideMenu(false);
        document.body.classList.add('login-page');
        document.body.classList.remove('dash-bg');

    }, [history, firebase.auth]);

    const handleChange = (event) => {
        event.persist();
        setValues(values => ({
            ...values,
            [event.target.name]: event.target.value
        }));
    }

    const googleLogin = (event) => {
        props.toggleSideMenu(false);
        firebase.doGoogleLogin().then(resUser => {
            // showToastAlert('Success', "Account loggedin successfully.");
        })
            .catch(error => {
                showToastAlert('Error', error.message);
            });
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        props.toggleSideMenu(false);
        firebase.doSignInWithEmailAndPassword(values.email, values.password)
            .then(resUser => {
                // console.log(resUser);
                // setUser(resUser);
            })
            .catch(error => {
                showToastAlert('Error', error.message);
            });
    }

    return (
        <>
            {loading && <PageLoader />}
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 text-center mb-5">
                        <img src={require("assets/images/logo.png")} alt="" style={{ width: '5rem' }} />
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-4 col-md-6 col-sm-8 ml-auto mr-auto">
                        <form className="form loginForm" action="">
                            <div className="card card-login card-hidden">
                                <div className="card-header card-header-primary text-center">
                                    <h4 className="card-title">Login</h4>
                                </div>
                                <div>
                                    <center>
                                        <span onClick={googleLogin}><img src={require("assets/images/google.png")} id="gLogin" alt="" style={{ width: '6%' }} /></span>
                                    </center>
                                </div>
                                <hr style={{ height: '0' }} />
                                <div className="card-body ">
                                    <span className="bmd-form-group">
                                        <div className="input-group">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text">
                                                    <Email />
                                                </span>
                                            </div>
                                            <input type="email" name="email" className="form-control" placeholder="Email..." onChange={handleChange} required />
                                        </div>
                                    </span>
                                    <span className="bmd-form-group">
                                        <div className="input-group">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text">
                                                    <LockOutlined />
                                                </span>
                                            </div>
                                            <input type="password" name="password" className="form-control" placeholder="Password..." onChange={handleChange} required />
                                        </div>
                                    </span>
                                </div>
                                <div className="card-footer justify-content-center">
                                    <button className="btn btn-primary btn-link btn-lg text-secondary font-weight-bold" onClick={handleSubmit}>Log In to your Account</button>
                                    <br />
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Login;