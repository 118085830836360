import React, { useContext, useEffect } from 'react';
import { FirebaseContext } from '../contexts';

const Header = (props) => {

    const { user, firebase } = useContext(FirebaseContext);

    useEffect(() => {
        const current = document.querySelector(`a[href="${window.location.pathname}"]`);
        if (current) current.classList.add('active');
    }, []);

    return (
        <div id="sidebar-wrapper" onMouseEnter={() => props.toggleSideMenu(true)} onMouseLeave={() => props.toggleSideMenu(false)}>
            <div className="sidebar-heading text-center mt-3">
                <img src={require("assets/images/stella.png")} alt="" style={{ width: '2em', marginLeft: '-12px' }} />
            </div>
            <div className="list-group list-group-flush my-3">
                <a href="/" className="list-group-item list-group-item-action">
                    <div>
                        <img src={require("assets/images/nav-home.svg")} alt="" className="dash-mene-img" />
                    </div>
                    <span>Administrators</span>
                </a>

                <a href="/users" className="list-group-item list-group-item-action mt-4">
                    <div>
                        <img src={require("assets/images/users.svg")} alt="" className="dash-mene-img" />
                    </div>
                    <span>Users</span>
                </a>

                <a href="/stats" className="list-group-item list-group-item-action mt-4">
                    <div>
                        <img src={require("assets/images/promotion.svg")} alt="" className="dash-mene-img" />
                    </div>
                    <span>Stats</span>
                </a>

                <a href="/basic" className="list-group-item list-group-item-action mt-4">
                    <div>
                        <img src={require("assets/images/sqaure.svg")} alt="" className="dash-mene-img" />
                    </div>
                    <span>Basic</span>
                </a>

                <a href="/pro" className="list-group-item list-group-item-action mt-4">
                    <div>
                        <img src={require("assets/images/sqaure.svg")} alt="" className="dash-mene-img" />
                    </div>
                    <span>Pro</span>
                </a>

                <a href="/premium" className="list-group-item list-group-item-action mt-4">
                    <div>
                        <img src={require("assets/images/sqaure.svg")} alt="" className="dash-mene-img" />
                    </div>
                    <span>Premium</span>
                </a>

                <a href="/datacenter" className="list-group-item list-group-item-action mt-4" >
                    <div>
                        <img src={require("assets/images/nav-earth.svg")} alt="" className="dash-mene-img" />
                    </div>
                    <span>Datacenter</span>
                </a>

                <a href="/isp" className="list-group-item list-group-item-action mt-4" >
                    <div>
                        <img src={require("assets/images/nav-earth.svg")} alt="" className="dash-mene-img" />
                    </div>
                    <span>ISP</span>
                </a>

                <a href="/captcha" className="list-group-item list-group-item-action mt-4" >
                    <div>
                        <img src={require("assets/images/nav-earth.svg")} alt="" className="dash-mene-img" />
                    </div>
                    <span>Captcha</span>
                </a>

                <a href="/gmail" className="list-group-item list-group-item-action mt-4" >
                    <div>
                        <img src={require("assets/images/nav-email.svg")} alt="" className="dash-mene-img" />
                    </div>
                    <span>Gmail</span>
                </a>

                <a href="/nike" className="list-group-item list-group-item-action mt-4" >
                    <div>
                        <img src={require("assets/images/nav-nike.svg")} alt="" className="dash-mene-img" />
                    </div>
                    <span>Nike</span>
                </a>

                <a href="/server" className="list-group-item list-group-item-action mt-4" >
                    <div>
                        <img src={require("assets/images/nav-server.svg")} alt="" className="dash-mene-img" />
                    </div>
                    <span>Server</span>
                </a>

                <a href="/pools" className="list-group-item list-group-item-action mt-4" >
                    <div>
                        <img src={require("assets/images/server.svg")} alt="" className="dash-mene-img" />
                    </div>
                    <span>Pools</span>
                </a>

                <a href="/coupon" className="list-group-item list-group-item-action mt-4" >
                    <div>
                        <img src={require("assets/images/nav-contact.svg")} alt="" className="dash-mene-img" />
                    </div>
                    <span>Coupon</span>
                </a>

                <a href="/plans" className="list-group-item list-group-item-action mt-4" >
                    <div>
                        <img src={require("assets/images/promotion.svg")} alt="" className="dash-mene-img" />
                    </div>
                    <span>Plans</span>
                </a>

                <a href="/reports" className="list-group-item list-group-item-action mt-4" >
                    <div>
                        <img src={require("assets/images/nav-reports.svg")} alt="" className="dash-mene-img" />
                    </div>
                    <span>Reports</span>
                </a>

                <button onClick={firebase.doSignOut} className="list-group-item list-group-item-action mt-4">
                    <div>
                        <img src={require("assets/images/nav-logout.svg")} alt="" className="dash-mene-img" />
                    </div>
                    <span>Logout</span>
                </button>

            </div>
        </div>
    )
}

export default Header;