import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import { FirebaseContext } from 'contexts/Firebase';
import { PageLoader, showToastAlert } from 'Services/Utility';
import Navbar from 'components/Navbar';
import "rsuite/dist/rsuite.min.css";
import DateRangePicker from 'rsuite/DateRangePicker';


const resiColumns = [
    {
        name: 'Plan',
        selector: row => row.provider || '',
        sortable: false,
    },
    {
        name: 'Usage (GB)',
        selector: row => row.usage && row.usage !== '' ? parseFloat(row.usage / 1000000000).toFixed(2) : '-',
        sortable: false,
    },
    {
        name: 'Bought (GB)',
        selector: row => row.bought ? parseFloat(row.bought).toFixed(2) : '-',
        sortable: false,
    },
    {
        name: 'Orders',
        selector: row => row.orders ? parseInt(row.orders) : '-',
        sortable: false,
    },
    {
        name: 'Discounts ($)',
        selector: row => row.discounts ? parseFloat(row.discounts).toFixed(2) : '-',
        sortable: false,
    },
    {
        name: 'Net Sales ($)',
        selector: row => row.netSales ? parseFloat(row.netSales).toFixed(2) : '-',
        sortable: false,
    }
];

const ispColumns = [
    {
        name: 'Plan',
        selector: row => row.provider || '',
        sortable: false,
    },
    {
        name: 'Orders',
        selector: row => row.orders ? parseInt(row.orders) : '-',
        sortable: false,
    },
    {
        name: 'Discounts ($)',
        selector: row => row.discounts ? parseFloat(row.discounts).toFixed(2) : '-',
        sortable: false,
    },
    {
        name: 'Net Sales ($)',
        selector: row => row.netSales ? parseFloat(row.netSales).toFixed(2) : '-',
        sortable: false,
    }
];

const conditionalRowStyles = [
    {
        when: row => row.total === true,
        style: {
            fontWeight: 'bold',
            fontSize: 'larger'
        }
    },
];



const Stats = (props) => {
    const { user, firebase } = useContext(FirebaseContext);
    const history = useHistory();
    const [payments, setPayments] = useState({});
    const [usages, setUsages] = useState({});
    const [pending, setPending] = useState(true);
    const [loading, setLoading] = useState(true);
    const [resiArr, setResiArr] = useState([]);
    const [ispArr, setIspArr] = useState([]);
    const [resiDateKey, setResiDateKey] = useState('all');
    const [ispDateKey, setIspDateKey] = useState('all');

    let usage = { 'basic': 0.0, 'premium': 0.0, 'pro': 0.0 }
    let bought = { 'basic': 0.0, 'premium': 0.0, 'pro': 0.0, 'isp': 0.0, 'datacenter': 0.0, 'captchaProxies': 0.0 }
    let netSales = { 'basic': 0.0, 'premium': 0.0, 'pro': 0.0, 'isp': 0.0, 'datacenter': 0.0, 'captchaProxies': 0.0 }
    let netDiscounts = { 'basic': 0.0, 'premium': 0.0, 'pro': 0.0, 'isp': 0.0, 'datacenter': 0.0, 'captchaProxies': 0.0 }
    let orders = { 'basic': 0, 'premium': 0, 'pro': 0, 'isp': 0, 'datacenter': 0, 'captchaProxies': 0 }

    const dateHours = { 'd': 24, 'w': 168, 'm': 720, 'all': 0 }

    useEffect(() => {
        document.body.classList.add('dash-bg');
        document.body.classList.add('plan-price-bg');
    }, []);

    const substractData = (payment) => {

        const paymentId = payment.paymentToken || '';

        let planPrice = parseFloat(payment.planPrice);
        let paidAmount = planPrice;
        if (payment.paid) {
            paidAmount = parseFloat(payment.paid);
        }

        let discontAmount = parseFloat(planPrice) - parseFloat(paidAmount);
        discontAmount = discontAmount < 0 ? 0 : discontAmount;

        if (paymentId && paymentId !== 'pi_flipd_extra' && paymentId !== 'pi_manual_payment' && paymentId !== 'pi_migrated') {
            if (payment.provider) {
                if (payment.dataLimitMB) {
                    bought[payment.provider] = parseInt(bought[payment.provider]) + parseInt(payment.dataLimitMB);
                }
                else if (payment.dataLimit) {
                    bought[payment.provider] = parseInt(bought[payment.provider]) + parseInt(parseFloat(payment.dataLimit) * 1000);
                }
                netSales[payment.provider] = parseFloat(netSales[payment.provider]) + paidAmount;
                netDiscounts[payment.provider] = parseFloat(netDiscounts[payment.provider]) + discontAmount;
                orders[payment.provider] = orders[payment.provider] + 1;
            }
            else {
                if (payment.numberOfProxies) {
                    bought[payment.planType] = parseInt(bought[payment.planType]) + parseInt(payment.numberOfProxies);
                }
                netSales[payment.planType] = parseFloat(netSales[payment.planType]) + paidAmount;
                netDiscounts[payment.planType] = parseFloat(netDiscounts[payment.planType]) + discontAmount;
                orders[payment.planType] = orders[payment.planType] + 1;
            }
        }
    }

    useEffect(() => {
        (async function () {
            firebase.auth.onAuthStateChanged(async (user) => {
                try {
                    if (!user) {
                        history.push('/login');
                        return;
                    }
                    setLoading(false);
                    const payments = (await firebase.db.ref('payments').once('value')).val();
                    const usages = (await firebase.db.ref('resiUsage').once('value')).val();
                    setPayments(payments || {});
                    setUsages(usages || {})
                    filterDataForDaterange(payments, usages, resiDateKey, ispDateKey);
                    setPending(false);
                } catch (error) {
                    console.log(error);
                    showToastAlert('Error', error.message);
                    setPending(false);
                    setLoading(false);
                }
            });
        })();
    }, [history, firebase.auth]);

    const changeResiDateRange = async (e) => {
        const key = e.target.getAttribute('data-key');
        setResiDateKey(key);
        filterDataForDaterange(payments, usages, key, ispDateKey);
    }

    const changeIspDateRange = async (e) => {
        const key = e.target.getAttribute('data-key');
        setIspDateKey(key);
        filterDataForDaterange(payments, usages, resiDateKey, key);
    }

    const resiDateRangeOk = (dates, event) => {
        console.log(dates);
    }

    const clearAllData = () => {
        Object.keys(usage).forEach(x => usage[x] = 0.0);
        Object.keys(bought).forEach(x => bought[x] = 0.0);
        Object.keys(netSales).forEach(x => netSales[x] = 0.0);
        Object.keys(netDiscounts).forEach(x => netDiscounts[x] = 0.0);
        Object.keys(orders).forEach(x => orders[x] = 0);
    }

    const filterDataForDaterange = (payments, usages, resiKey, ispKey) => {

        clearAllData();
        let resiCheckTime = new Date();
        let ispCheckTime = new Date();
        resiCheckTime = resiCheckTime.setHours(resiCheckTime.getHours() - dateHours[resiKey]);
        ispCheckTime = ispCheckTime.setHours(ispCheckTime.getHours() - dateHours[ispKey]);
        resiCheckTime = parseInt(resiCheckTime / 1000);
        ispCheckTime = parseInt(ispCheckTime / 1000);

        for (const userId in payments) {
            for (const timestamp in payments[userId]) {
                const payment = payments[userId][timestamp];
                if (payment.planType === 'residential') {
                    if (resiKey === 'all' || parseInt(timestamp) >= resiCheckTime) {
                        substractData(payment);
                    }
                }
                else {
                    if (ispKey === 'all' || parseInt(timestamp) >= ispCheckTime) {
                        substractData(payment);
                    }
                }

                if (payment['renew']) {
                    let renewPlans = payment['renew'];
                    for (const renewTime in renewPlans) {
                        const renewDetail = renewPlans[renewTime];
                        if (renewDetail.planType === 'residential') {
                            if (resiKey === 'all' || parseInt(renewTime) >= resiCheckTime) {
                                substractData(renewDetail);
                            }
                        }
                        else {
                            if (ispKey === 'all' || parseInt(renewTime) >= ispCheckTime) {
                                substractData(renewDetail);
                            }
                        }
                    }
                }
            }
        }

        for (const type in usages) {
            if (resiKey !== 'all') {
                let times = Object.keys(usages[type]).map(x => parseInt(x)).filter(timestamp => timestamp >= resiCheckTime);
                if (times.length >= 2) {
                    const last = times.pop();
                    const first = times.shift();
                    usage[type] = parseInt(usages[type][last].used) - parseInt(usages[type][first].used);
                }
                else if (times.length == 1) {
                    const last = times.pop();
                    usage[type] = parseInt(usages[type][last].used);
                }
            }
            else {
                usage[type] = parseInt(usages[type][Object.keys(usages[type]).pop()].used);
            }
        }

        let resiTotals = { 'usage': 0, 'bought': 0, 'orders': 0, 'discounts': 0, 'netSales': 0 }
        let resiArr = [['premium', 'Premium'], ['pro', 'Pro'], ['basic', 'Basic']].flatMap(arr => {
            const dict = {
                'provider': arr[1],
                'usage': usage[arr[0]],
                'bought': parseFloat(bought[arr[0]] / 1000),
                'orders': orders[arr[0]],
                'discounts': parseFloat(netDiscounts[arr[0]]),
                'netSales': parseFloat(netSales[arr[0]])
            }
            resiTotals['usage'] = parseFloat(resiTotals['usage']) + parseFloat(dict['usage']);
            resiTotals['bought'] = parseFloat(resiTotals['bought']) + parseFloat(dict['bought']);
            resiTotals['orders'] = parseInt(resiTotals['orders']) + parseInt(dict['orders']);
            resiTotals['discounts'] = parseFloat(resiTotals['discounts']) + parseFloat(dict['discounts']);
            resiTotals['netSales'] = parseFloat(resiTotals['netSales']) + parseFloat(dict['netSales']);
            return dict;
        })
        resiArr.unshift({ 'provider': 'Total', 'usage': resiTotals['usage'], 'bought': resiTotals['bought'], 'orders': resiTotals['orders'], 'discounts': resiTotals['discounts'], 'netSales': resiTotals['netSales'], 'total': true })

        let ispTotals = { 'orders': 0, 'discounts': 0, 'netSales': 0 }
        let ispArr = [['isp', 'ISP'], ['datacenter', 'Datacenter'], ['captchaProxies', 'Captcha']].flatMap(arr => {
            const dict = {
                'provider': arr[1],
                'orders': orders[arr[0]],
                'discounts': parseFloat(netDiscounts[arr[0]]),
                'netSales': parseFloat(netSales[arr[0]])
            }
            ispTotals['orders'] = parseInt(ispTotals['orders']) + parseInt(dict['orders']);
            ispTotals['discounts'] = parseFloat(ispTotals['discounts']) + parseFloat(dict['discounts']);
            ispTotals['netSales'] = parseFloat(ispTotals['netSales']) + parseFloat(dict['netSales']);
            return dict;
        })
        ispArr.unshift({ 'provider': 'Total', 'orders': ispTotals['orders'], 'discounts': ispTotals['discounts'], 'netSales': ispTotals['netSales'], 'total': true })

        setResiArr(resiArr);
        setIspArr(ispArr);
    }

    return (
        <>
            {loading && <PageLoader />}
            <div id="page-content-wrapper" className="extra-p">
                <Navbar toggleSideMenu={props.toggleSideMenu} />

                <div className="container">
                    <div className='row'>
                        <div className='col-12 mb-2'>
                            <div className="dash-box position-relative">
                                <div className="row" style={{ 'paddingTop': '30px' }}>
                                    <div className="col-12 dash-head my-0">
                                        <div className='d-flex' style={{ 'gap': '20px' }}>
                                            <h4 className="my-0" style={{ 'color': 'var(--main-blue)' }}>Residential</h4>
                                        </div>
                                        <div className='plan-nav'>
                                            {/* <DateRangePicker format="yyyy-MM-dd HH:mm:ss" onOk={resiDateRangeOk} placement="auto" /> */}
                                            <ul className="nav nav-pills my-0 px-0" id="pills-tab" role="tablist" style={{ gap: '8px' }}>
                                                {
                                                    [['d', '1D'], ['w', '1W'], ['m', '1M'], ['all', 'All']].map(ranges => {
                                                        return (
                                                            <li className="nav-item" role="presentation" key={ranges[0]}>
                                                                <button key={ranges[0] + '-btn'} style={{ minWidth: 'fit-content' }} className={`nav-link circle-date ${resiDateKey === ranges[0] ? 'active' : ''}`} onClick={changeResiDateRange} data-key={ranges[0]} id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">{ranges[1]}</button>
                                                            </li>
                                                        )
                                                    })
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-12 mt-3">
                                        <div className="table-responsive">
                                            <DataTable columns={resiColumns} data={resiArr} persistTableHead progressPending={pending} conditionalRowStyles={conditionalRowStyles} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-12'>
                            <div className="dash-box position-relative">
                                <div className="row" style={{ 'paddingTop': '30px' }}>
                                    <div className="col-12 dash-head my-0">
                                        <div className='d-flex' style={{ 'gap': '20px' }}>
                                            <h4 className="my-0" style={{ 'color': 'var(--main-blue)' }}>ISP</h4>
                                        </div>
                                        <div className='plan-nav'>
                                            <ul className="nav nav-pills my-0 px-0" id="pills-tab" role="tablist" style={{ gap: '8px' }}>
                                                {
                                                    [['d', '1D'], ['w', '1W'], ['m', '1M'], ['all', 'All']].map(ranges => {
                                                        return (
                                                            <li className="nav-item" role="presentation" key={ranges[0]}>
                                                                <button key={ranges[0] + '-btn'} style={{ minWidth: 'fit-content' }} className={`nav-link circle-date ${ispDateKey === ranges[0] ? 'active' : ''}`} onClick={changeIspDateRange} data-key={ranges[0]} id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">{ranges[1]}</button>
                                                            </li>
                                                        )
                                                    })
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-12 mt-3">
                                        <div className="table-responsive">
                                            <DataTable columns={ispColumns} data={ispArr} persistTableHead progressPending={pending} conditionalRowStyles={conditionalRowStyles} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Stats;