import React, { Component } from 'react';
import { Modal } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import Select from 'react-select';
import * as request from 'request';
import { showToastAlert } from '../Services/Utility';


const customStyles = {
    option: (provided, state) => ({
        ...provided,
        backgroundColor: "white"
    }),
    noOptionsMessage: (provided, state) => ({
        ...provided,
        backgroundColor: "white",
        borderRadius: '5px',
        border: "2px solid lightgray"
    }),
    menuList: (provided, state) => ({
        ...provided,
        backgroundColor: "white",
        borderRadius: '5px',
        border: "2px solid lightgray"
    }),
}

class PoolModifyModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: props.showModal || false,
            myCountry: props.isUpdate ? props.myCountry : '',
            continent: null,
            tag: null,
            cc4: null,
            cc4Noc: null,
            cc3: '',
            cc2: '',
            flag: 'random',
            nports: 999,
            selectedProviders: null
        };
        this.showCC4 = false;
        // this.showCC4Noc = false;
        this.isUpdate = props.isUpdate || false;
        this.planType = props.planType;
        this.continents = ['Asia', 'Africa', 'America', 'Europe', 'Australia', 'Antarctica', 'Mexico', 'Germany'].map(x => ({ 'label': x, 'value': x }));
        this.tags = ['usa', 'eu'].map(x => ({ 'label': x, 'value': x }));
        this.providersData = props.providers;
        this.providers = props.providers.map(x => ({ 'label': x.name, 'value': x.id }));;
        this.allPools = props.allPools;
        this.subnetCC4 = props.subnetCountries.map(x => {
            const sub = x.domain.split('.')[0];
            return { 'label': x.name + ' - ' + sub, 'value': `${x.domain}-${x.portStart}-${x.portEnd}` };
        })
        // this.subnetCC4Noc = props.subnetNocCountries.map(x => {
        //     const sub = x.domain.split('.')[0];
        //     return { 'label': x.name + ' - ' + sub, 'value': `${x.domain}-${x.portStart}-${x.portEnd}` };
        // })
        if (this.isUpdate) {
            this.oldPoolData = this.allPools.find(x => x.myCountry === props.myCountry);
            this.state.continent = this.continents.find(x => x.value === this.oldPoolData.continent);
            this.state.tag = this.tags.find(x => x.value === this.oldPoolData.tag);
            this.state.cc3 = this.oldPoolData.cc3;
            this.state.cc2 = this.oldPoolData.cc2 || '';
            this.state.flag = this.oldPoolData.flag;
            this.state.nports = this.oldPoolData.nports;

            let oldPrvs = [];
            if (this.oldPoolData.dawnDomain && this.oldPoolData.dawnDomain.includes('superproxy') || this.oldPoolData.gangaDomain && this.oldPoolData.gangaDomain.includes('superproxy') || this.oldPoolData.yamunaDomain && this.oldPoolData.yamunaDomain.includes('superproxy')) {
                oldPrvs.push('brightdata');
            }
            if (this.oldPoolData.dawnDomain && this.oldPoolData.dawnDomain.includes('packet') || this.oldPoolData.gangaDomain && this.oldPoolData.gangaDomain.includes('packet') || this.oldPoolData.yamunaDomain && this.oldPoolData.yamunaDomain.includes('packet')) {
                oldPrvs.push('packet');
            }
            // if (this.oldPoolData.dawnDomain && this.oldPoolData.dawnDomain.includes('resi4') || this.oldPoolData.gangaDomain && this.oldPoolData.gangaDomain.includes('resi4') || this.oldPoolData.yamunaDomain && this.oldPoolData.yamunaDomain.includes('resi4')) {
            //     oldPrvs.push('subnetnoc');
            //     const domain = this.oldPoolData.dawnDomain && this.oldPoolData.dawnDomain.includes('resi4') ? this.oldPoolData.dawnDomain : (this.oldPoolData.gangaDomain && this.oldPoolData.gangaDomain.includes('resi4') ? this.oldPoolData.gangaDomain : (this.oldPoolData.yamunaDomain && this.oldPoolData.yamunaDomain.includes('resi4') ? this.oldPoolData.yamunaDomain : ''));
            //     this.showCC4Noc = true;
            //     this.state.cc4Noc = this.subnetCC4Noc.find(x => x.value.includes(domain.split('.')[0]));
            //     this.state.cc4Noc = this.state.cc4Noc === undefined ? null : this.state.cc4Noc;
            // }
            // if (this.oldPoolData.dawnDomain && this.oldPoolData.dawnDomain.includes('subnet') || this.oldPoolData.gangaDomain && this.oldPoolData.gangaDomain.includes('subnet') || this.oldPoolData.yamunaDomain && this.oldPoolData.yamunaDomain.includes('subnet')) {
            //     oldPrvs.push('subnet');
            //     const domain = this.oldPoolData.dawnDomain && this.oldPoolData.dawnDomain.includes('subnet') ? this.oldPoolData.dawnDomain : (this.oldPoolData.gangaDomain && this.oldPoolData.gangaDomain.includes('subnet') ? this.oldPoolData.gangaDomain : (this.oldPoolData.yamunaDomain && this.oldPoolData.yamunaDomain.includes('subnet') ? this.oldPoolData.yamunaDomain : ''));
            //     this.showCC4 = true;
            //     this.state.cc4 = this.subnetCC4.find(x => x.value.includes(domain.split('.')[0]));
            //     this.state.cc4 = this.state.cc4 === undefined ? null : this.state.cc4;
            // }
            if (this.oldPoolData.dawnDomain && this.oldPoolData.dawnDomain.includes('oxylabs') || this.oldPoolData.gangaDomain && this.oldPoolData.gangaDomain.includes('oxylabs') || this.oldPoolData.yamunaDomain && this.oldPoolData.yamunaDomain.includes('oxylabs')) {
                oldPrvs.push('oxylabs');
            }
            if (this.oldPoolData.dawnDomain && this.oldPoolData.dawnDomain.includes('smrt') || this.oldPoolData.gangaDomain && this.oldPoolData.gangaDomain.includes('smrt') || this.oldPoolData.yamunaDomain && this.oldPoolData.yamunaDomain.includes('smrt')) {
                oldPrvs.push('Smart-x');
            }
            if (this.oldPoolData.dawnDomain && this.oldPoolData.dawnDomain.includes('smartproxy') || this.oldPoolData.gangaDomain && this.oldPoolData.gangaDomain.includes('smartproxy') || this.oldPoolData.yamunaDomain && this.oldPoolData.yamunaDomain.includes('smartproxy')) {
                oldPrvs.push('smart');
            }
            if (this.oldPoolData.dawnDomain && this.oldPoolData.dawnDomain.includes('ntnt') || this.oldPoolData.gangaDomain && this.oldPoolData.gangaDomain.includes('ntnt') || this.oldPoolData.yamunaDomain && this.oldPoolData.yamunaDomain.includes('ntnt')) {
                oldPrvs.push('netnut');
            }
            if (this.oldPoolData.dawnDomain && this.oldPoolData.dawnDomain.includes('geosurf') || this.oldPoolData.gangaDomain && this.oldPoolData.gangaDomain.includes('geosurf') || this.oldPoolData.yamunaDomain && this.oldPoolData.yamunaDomain.includes('geosurf')) {
                oldPrvs.push('geosurf');
            }
            if (this.oldPoolData.dawnDomain && this.oldPoolData.dawnDomain.includes('iproyal') || this.oldPoolData.gangaDomain && this.oldPoolData.gangaDomain.includes('iproyal') || this.oldPoolData.yamunaDomain && this.oldPoolData.yamunaDomain.includes('iproyal')) {
                oldPrvs.push('iproyal');
            }
            if (oldPrvs.length > 0) {
                this.state.selectedProviders = this.providers.filter(x => oldPrvs.includes(x.value));
            }
        }
        this.closeModal = this.closeModal.bind(this);
    }

    closeModal = () => {
        this.setState({
            showModal: false
        });
    }

    onChange = (e) => {
        e.persist();
        this.setState({
            [e.target.id]: e.target.value
        });
    }

    handleContinentChange = (selectedOption) => {
        this.setState({ ['continent']: selectedOption || null })
    };

    handleTagChange = (selectedOption) => {
        this.setState({ ['tag']: selectedOption || null })
    };

    handleCC4Change = (selectedOption) => {
        this.setState({ ['cc4']: selectedOption || null })
    };
    // handleNocCC4Change = (selectedOption) => {
    //     this.setState({ ['cc4Noc']: selectedOption || null })
    // };

    handleProvidersChange = (selectedOption) => {
        this.setState({ ['selectedProviders']: selectedOption || null })
        this.showCC4 = selectedOption.length > 0 && selectedOption.find(x => x.value === 'subnet') !== undefined;
        if (!this.showCC4) this.setState({ ['cc4']: null });
        // this.showCC4Noc = selectedOption.length > 0 && selectedOption.find(x => x.value === 'subnetnoc') !== undefined;
        // if (!this.showCC4Noc) this.setState({ ['cc4Noc']: null });
    };

    cancelClicked = async (event) => {
        this.closeModal();
    }

    saveClicked = async (event) => {

        if (this.state.myCountry.trim() === '') {
            return showToastAlert('Error', 'Enter pool name');
        }
        if (this.state.continent === null) {
            return showToastAlert('Error', 'Select continent');
        }
        if (this.state.tag === null) {
            return showToastAlert('Error', 'Select tag');
        }
        if (this.state.selectedProviders === null) {
            return showToastAlert('Error', 'Select provider');
        }
        // if (this.showCC4 && this.state.cc4 === null) {
        //     return showToastAlert('Error', 'Select subnet subdomain');
        // }
        // if (this.showCC4Noc && this.state.cc4Noc === null) {
        //     return showToastAlert('Error', 'Select subnet noc subdomain');
        // }
        if (this.state.cc3.trim() === '') {
            return showToastAlert('Error', 'Enter cc3 value');
        }
        if (this.state.cc2.trim() === '') {
            return showToastAlert('Error', 'Enter cc2 value');
        }
        // if (this.state.flag.trim() === '') {
        //     return showToastAlert('Error', 'Enter flag');
        // }
        // if (this.state.nports === '' || parseInt(this.state.nports) <= 0) {
        //     return showToastAlert('Error', 'Enter ports');
        // }

        if (!this.isUpdate) {
            const sameCountry = this.allPools.find(x => x.myCountry.toLowerCase() === this.state.myCountry.trim().toLowerCase());
            if (sameCountry) {
                return showToastAlert('Error', 'Pool name is already created. Enter unique name');
            }
        }

        let dict = {
            'myCountry': this.state.myCountry,
            'continent': this.state.continent.value,
            'tag': this.state.tag.value,
            'cc3': this.state.cc3,
            'cc2': this.state.cc2,
            'flag': this.state.flag,
            'nports': parseInt(this.state.nports),
            'dawnDomain': '',
            'dawnFormat': '',
            'gangaDomain': '',
            'gangaFormat': '',
            'yamunaDomain': '',
            'yamunaFormat': ''
        }

        this.state.selectedProviders.forEach((prv, idx) => {
            const provider = this.providersData.find(x => x.id === prv.value);
            let domain = '', format = '';
            if (provider.id === 'brightdata') {
                domain = 'zproxy.lum-superproxy.io:32222';
                format = `lum-customer-${provider.username}-zone-${provider.zone}-country-${this.state.cc2}-session-glob_%.*s:${provider.password}`;
            }
            else if (provider.id === 'packet') {
                domain = 'proxy.packetstream.io:31112';
                // format = `${provider.username}:${provider.password}_country-${this.state.myCountry.split(' ').join('')}_session-%.*s`;
                format = `${provider.username}:${provider.password}_country-${this.state.cc2}_session-%.*s`;
            }
            else if (provider.id === 'subnet') { //provider.id === 'subnetnoc'
                // let cc4 = provider.id === 'subnet' ? this.state.cc4 : this.state.cc4Noc;
                let cc4 = this.state.cc4;
                cc4 = cc4.value.split('-');
                domain = `${cc4[0]}:${cc4[1]}-${cc4[2]}`;
                format = `${provider.username}:${provider.password}-%.*s`;
                dict['cc4'] = cc4[0].split('.')[0];
                dict['portStart'] = cc4[1];
                dict['portEnd'] = cc4[2];
            }
            else if (provider.id === 'oxylabs') {
                domain = 'pr.oxylabs.io:7777';
                format = `user-${provider.username}-country-${this.state.cc2}-session-glob_%.*s:${provider.password}`;
            }
            else if (provider.id === 'smart') {
                domain = 'gate.smartproxy.com:7000';
                format = `user-${provider.username}-country-${this.state.cc2}-session-%.*s:${provider.password}`;
            }
            else if (provider.id === 'smart-x') {
                domain = 'smrt.x.proxiess.com:7000';
                format = `${provider.username}:${provider.password}-country-${this.state.cc2}-session-glob_%.*s`;
            }
            else if (provider.id === 'netnut') {
                domain = 'pa-Stellaproxies-US.ntnt.network:5959';
                format = `${provider.username}-res-cghu-sid-%d:${provider.password}`;
            }
            else if (provider.id === 'geosurf') {
                domain = `${this.state.cc2}-30m.geosurf.io:8000`;
                format = `${provider.username}+${this.state.cc2}+${provider.username}-%.*s:${provider.password}`;
            }
            else if (provider.id === 'iproyal') {
                domain = `proxy.iproyal.com:12323`;
                format = `${provider.username}:${provider.password}_country-${this.state.cc2}_session-%.*s`;
            }
            if (domain !== '') {
                if (idx == 0) {
                    dict['dawnDomain'] = domain;
                    dict['dawnFormat'] = 'dawn:' + format;
                }
                else if (idx == 1) {
                    dict['gangaDomain'] = domain;
                    dict['gangaFormat'] = 'ganga:' + format;
                }
                else if (idx == 2) {
                    dict['yamunaDomain'] = domain;
                    dict['yamunaFormat'] = 'yamuna:' + format;
                }
            }
        });

        let newData = {};
        console.log(dict);

        if (!this.isUpdate) {
            newData[this.state.myCountry] = dict;
        }

        this.allPools.forEach(x => {
            let dict1 = {
                'myCountry': x.myCountry,
                'continent': x.continent,
                'tag': x.tag,
                'cc3': x.cc3,
                'flag': x.flag,
                'nports': x.nports,
                'dawnDomain': x.dawnDomain || '',
                'dawnFormat': x.dawnFormat || '',
                'gangaDomain': x.gangaDomain || '',
                'gangaFormat': x.gangaFormat || '',
                'yamunaDomain': x.yamunaDomain || '',
                'yamunaFormat': x.yamunaFormat || ''
            }
            if (x.cc2) {
                dict1['cc2'] = x.cc2;
            }
            if (this.isUpdate && x.myCountry === this.props.myCountry) {
                newData[x.myCountry] = dict;
            }
            else {
                newData[x.myCountry] = dict1;
            }
        })

        const idToken = await this.props.firebase.auth.currentUser.getIdToken(true);
        const data1 = { idToken: idToken, pools: newData }

        this.props.setLoading(true);
        const poolsData = await this.createPool(data1);
        this.props.setLoading(false);

        if (poolsData !== null) {
            this.props.successCallback(poolsData);
            showToastAlert('success', 'Pools updated successfully');
            this.closeModal();
        }
    }

    createPool = async (data) => {
        return new Promise((resolve, reject) => {
            let options = {
                method: 'POST',
                url: `${process.env.REACT_APP_API_URL}/${this.planType}/createPool`,
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            };
            return request(options, (error, response, body) => {
                let resData = body && JSON.parse(body);
                if (error || response.statusCode !== 200) {
                    let errorMessage = error ? error : resData ? resData.message : 'Error occurred while creating pool!';
                    showToastAlert('Error', errorMessage);
                    return resolve({});
                } else {
                    return resolve(resData || {});
                }
            });
        });
    }

    render() {
        const { showModal } = this.state;
        return (
            <Modal
                isOpen={showModal}
                toggle={this.closeModal}
                onClosed={this.closeModal}
                className=""
                size="md"
                backdrop="static"
                centered
            >
                <div className="modal-body">
                    <div className="pop-bg-img">
                        <img src={require("assets/images/pop-bg.svg")} alt="" />
                    </div>
                    <button
                        type="button"
                        className="btn-close pop-close-btn"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        onClick={this.closeModal}
                    />
                    <div className="dash-head">
                        <img src={require("assets/images/proxy-gen.svg")} alt="" /> <h4 className="mb-0">{this.isUpdate ? 'Update Pool' : 'Create Pool'}</h4>
                    </div>

                    <div className="form-field px-2">
                        <div className="form-group row mb-3 d-flex align-items-center">
                            <label htmlFor="myCountry" className="col-sm-5 col-form-label py-0">Pool Name <span className='text-danger'>*</span></label>
                            <div className="col-sm-7">
                                <input type="text" className="form-control" id="myCountry" onChange={this.onChange} value={this.state.myCountry} placeholder='Enter pool name' required />
                            </div>
                        </div>
                        <div className="form-group row mb-3 d-flex align-items-center">
                            <label htmlFor="continent" className="col-sm-5 col-form-label py-0">Continent <span className='text-danger'>*</span></label>
                            <div className="col-sm-7">
                                <Select className="select" styles={customStyles} id="continent" options={this.continents} onChange={this.handleContinentChange} value={this.state.continent} placeholder="Select Continent" required />
                            </div>
                        </div>
                        <div className="form-group row mb-3 d-flex align-items-center">
                            <label htmlFor="tag" className="col-sm-5 col-form-label py-0">Tag <span className='text-danger'>*</span></label>
                            <div className="col-sm-7">
                                <Select className="select" styles={customStyles} id="tag" options={this.tags} onChange={this.handleTagChange} value={this.state.tag} placeholder="Select Tag" required />
                            </div>
                        </div>
                        <div className="form-group row mb-2 d-flex align-items-center">
                            <label htmlFor="providers" className="col-sm-5 col-form-label py-0">Providers <span className='text-danger'>*</span></label>
                            <div className="col-sm-7">
                                <Select className="select" styles={customStyles} id="providers" isMulti onChange={this.handleProvidersChange} value={this.state.selectedProviders} placeholder="Select provider"
                                    options={
                                        this.state.selectedProviders === null || this.state.selectedProviders.length < 3 ? this.providers : []
                                    }
                                    noOptionsMessage={() => {
                                        return this.state.selectedProviders === null ? false : this.state.selectedProviders.length < 3 ? 'No options' : 'Max 3 providers are allowed';
                                    }}
                                />
                            </div>
                        </div>
                        {
                            this.showCC4 &&
                            <div className="form-group row mb-3 d-flex align-items-center">
                                <label htmlFor="cc4" className="col-sm-5 col-form-label py-0">Subnet Subdomain <span className='text-danger'>*</span></label>
                                <div className="col-sm-7">
                                    <Select className="select" styles={customStyles} id="cc4" options={this.subnetCC4} onChange={this.handleCC4Change} value={this.state.cc4} placeholder="Select domain (cc4)" required />
                                </div>
                            </div>
                        }
                        {/* {
                            this.showCC4Noc &&
                            <div className="form-group row mb-3 d-flex align-items-center">
                                <label htmlFor="cc4Noc" className="col-sm-5 col-form-label py-0">Subnet Noc Subdomain <span className='text-danger'>*</span></label>
                                <div className="col-sm-7">
                                    <Select className="select" id="cc4Noc" options={this.subnetCC4Noc} onChange={this.handleNocCC4Change} value={this.state.cc4Noc} placeholder="Select domain (cc4)" required />
                                </div>
                            </div>
                        } */}
                        <div className="form-group row mb-3 d-flex align-items-center">
                            <label htmlFor="cc3" className="col-sm-5 col-form-label py-0">Subdomain <span className='text-danger'>*</span></label>
                            <div className="col-sm-7">
                                <input type="text" className="form-control" id="cc3" onChange={this.onChange} value={this.state.cc3} placeholder='Enter unique subdomain (cc3)' required />
                            </div>
                        </div>
                        <div className="form-group row mb-3 d-flex align-items-center">
                            <label htmlFor="cc2" className="col-sm-5 col-form-label py-0">Country code <span className='text-danger'>*</span></label>
                            <div className="col-sm-7">
                                <input type="text" className="form-control" id="cc2" onChange={this.onChange} value={this.state.cc2} placeholder='Enter 2 letter code (cc2)' required />
                            </div>
                        </div>
                        {/* <div className="form-group row mb-3 d-flex align-items-center">
                            <label htmlFor="flag" className="col-sm-5 col-form-label py-0">Flag <span className='text-danger'>*</span></label>
                            <div className="col-sm-7">
                                <input type="text" className="form-control" id="flag" onChange={this.onChange} value={this.state.flag} placeholder='Enter flag' required />
                            </div>
                        </div>
                        <div className="form-group row mb-2 d-flex align-items-center">
                            <label htmlFor="nports" className="col-sm-5 col-form-label py-0">No. of Ports <span className='text-danger'>*</span></label>
                            <div className="col-sm-7">
                                <input type="number" min={1} className="form-control" id="nports" onChange={this.onChange} value={this.state.nports} placeholder='Enter ports' required />
                            </div>
                        </div> */}
                        <div className="row">
                            <div className="col-sm-12 text-end">
                                <div className="d-inline-block mt-4 me-2">
                                    <a href="#" className="mb-3 px-5 cta-white" onClick={this.cancelClicked}>Cancel</a>
                                </div>
                                <div className="form-cta-btn d-inline-block mt-4">
                                    <a href="#" className="mb-3 px-5 text-light" style={{ 'boxShadow': '0px 6px 8px 3px #2c3d7926' }} onClick={this.saveClicked}>{this.isUpdate ? 'Update' : 'Create'}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </Modal>
        );
    }
}

export default withRouter(PoolModifyModal);
