import * as request from 'request';
import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import memoize from 'memoize-one';
import { FirebaseContext } from 'contexts/Firebase';
import { PageLoader, showToastAlert } from 'Services/Utility';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Navbar from 'components/Navbar';
import Swal from 'sweetalert2';
import AddPlanModal from 'Modals/AddPlanModal';
import AddSubPlanModal from 'Modals/AddSubPlanModal';


const columns = memoize((updateHandler, deleteHandler, planType) => [
    {
        name: 'Subplan Id',
        selector: row => row.subplanId || '',
        sortable: true,
    },
    {
        name: (planType === 'datacenter' || planType === 'isp' || planType === 'captchaProxies') ? 'No of Proxies' : 'Data Limit',
        selector: row => (planType === 'datacenter' || planType === 'isp' || planType === 'captchaProxies') ? (row.numberOfProxies ? row.numberOfProxies : '') : (row.dataLimitMB ? row.dataLimitMB + ' MB' : ''),
        sortable: false,
    },
    {
        name: 'Price',
        selector: row => row.price || '',
        sortable: false,
    },
    {
        name: 'Validity',
        selector: row => row.catName || '',
        sortable: false,
    },
    {
        name: 'Active',
        selector: row => row.active ? 'true' : 'false',
        sortable: false,
    },
    {
        name: 'Hidden',
        selector: row => row.hide ? 'true' : 'false',
        sortable: false,
    },
    {
        name: 'Action',
        cell: (row) => (<><button className='icon-btn add' onClick={e => updateHandler(row)}><EditIcon /></button> <button className='icon-btn delete' onClick={e => deleteHandler(row.planId, row.subplanId, row.planType)}><DeleteIcon /></button></>),
        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
        sortable: false,
    },
]);

const gmailColumns = memoize((updateHandler, deleteHandler) => [
    {
        name: 'Quantity',
        selector: row => row.subplanId || '',
        sortable: true,
    },
    {
        name: 'Price',
        selector: row => row.price || '',
        sortable: false,
    },
    {
        name: 'ISP PlanId',
        selector: row => row.isp ? row.isp.planId : '-',
        sortable: false,
    },
    {
        name: 'ISP SubPlanId',
        selector: row => row.isp ? row.isp.subPlanId : '-',
        sortable: false,
    },
    {
        name: 'Action',
        cell: (row) => (<><button className='icon-btn add' onClick={e => updateHandler(row)}><EditIcon /></button> <button className='icon-btn delete' onClick={e => deleteHandler(row.planId, row.subplanId, row.planType)}><DeleteIcon /></button></>),
        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
        sortable: false,
    },
]);


const Plans = (props) => {
    const { user, firebase } = useContext(FirebaseContext);
    const history = useHistory();
    const [resiPlans, setResiPlans] = useState([]);
    const [allPlans, setAllPlans] = useState({});
    const [flipdCode, setFlipdCode] = useState({});
    const [loading, setLoading] = useState(true);
    const [activePlanCheck, setActivePlanCheck] = useState({});
    const [hidePlanCheck, setHidePlanCheck] = useState({});
    const [activeTabKey, setActiveTabKey] = useState('residential');
    const [addPlanModals, setAddPlanModals] = useState([]);
    const [addSubPlanModals, setAddSubPlanModals] = useState([]);
    const [serverProducts, setServerProducts] = useState([]);

    const planNames = { 'residential': 'Residential', 'isp': 'ISP', 'datacenter': 'Datacenter', 'captchaProxies': 'Captcha', 'exec': 'Oxylabs', 'nifty': 'Netnut', 'pro': 'Subnet', 'smart': 'Smart', 'standard': 'Packet', 'gmail': 'Gmail', 'server': 'Server', 'nike': 'Nike' }

    useEffect(() => {
        document.body.classList.add('dash-bg');
        document.body.classList.add('plan-price-bg');
    }, []);

    const getServerProducts = (idToken) => {
        return new Promise((resolve, reject) => {
            let options = {
                method: 'POST',
                url: `${process.env.REACT_APP_API_URL}/server/getProducts`,
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    'idToken': idToken, 'platform': 'web'
                })
            };

            return request(options, (error, response, body) => {
                let resData = body && JSON.parse(body);
                if (error || response.statusCode !== 200) {
                    let errorMessage = error ? error : resData ? resData.message : 'A server error occurred while fetching ISP proxies!';
                    showToastAlert('Error', errorMessage);
                    resolve({});
                } else {
                    resolve(resData);
                }
            });
        });
    }

    const handleDeleteClick = (planId, subPlanId, plantype) => {

        Swal.fire({
            html: 'Are you sure you want to delete <strong>' + subPlanId + '</strong> ?',
            icon: 'question',
            color: '#103a8d',
            showCancelButton: true,
            confirmButtonText: 'Delete',
            reverseButtons: true
        }).then(async (result) => {
            if (result.value) {
                try {
                    const subPlanKey = plantype === 'gmail' || plantype === 'nike' ? 'quantity' : 'subPlans';
                    await firebase.db.ref(`plans/${plantype}/${planId}/${subPlanKey}/${subPlanId}`).set(null);
                    delete allPlans[plantype][planId][subPlanKey][subPlanId];
                    configurePlanData(allPlans[activeTabKey], activeTabKey);
                }
                catch (err) {
                    showToastAlert('Error', err.message ? err.message : err);
                }
                setLoading(false);
            }
        });
    };

    const deletePlanClick = (planId, plantype) => {

        Swal.fire({
            html: 'Are you sure you want to delete <strong>' + planId + '</strong> plan ?',
            icon: 'question',
            color: '#103a8d',
            showCancelButton: true,
            confirmButtonText: 'Delete',
            reverseButtons: true
        }).then(async (result) => {
            if (result.value) {
                try {
                    await firebase.db.ref(`plans/${plantype}/${planId}`).set(null);
                    delete allPlans[plantype][planId];
                    configurePlanData(allPlans[activeTabKey], activeTabKey);
                }
                catch (err) {
                    showToastAlert('Error', err.message ? err.message : err);
                }
                setLoading(false);
            }
        });
    };

    const handleUpdateClick = (subplanData) => {
        setAddSubPlanModals([]);
        setAddSubPlanModals([
            <AddSubPlanModal
                showModal={true}
                key={Math.random()}
                firebase={firebase}
                successCallback={planModifyCallback}
                categories={allPlans[activeTabKey]['categories'] || {}}
                planType={activeTabKey}
                subplanData={subplanData}
                allPlans={allPlans}
                isUpdate={true}
            />
        ]);
    };

    const handleAddSubPlanClick = (planId) => {
        setAddSubPlanModals([]);
        setAddSubPlanModals([
            <AddSubPlanModal
                showModal={true}
                key={Math.random()}
                firebase={firebase}
                successCallback={planModifyCallback}
                categories={allPlans[activeTabKey]['categories'] || {}}
                planType={activeTabKey}
                planId={planId}
                allPlans={allPlans}
                isUpdate={false}
            />
        ]);
    };

    const handleUpdatePlanDetailClick = (planId) => {
        setAddPlanModals([]);
        setAddPlanModals([
            <AddPlanModal
                showModal={true}
                key={Math.random()}
                firebase={firebase}
                successCallback={planModifyCallback}
                planData={allPlans[activeTabKey][planId]}
                planType={activeTabKey}
                planId={planId}
                isUpdate={true}
                serverProducts={serverProducts}
                categories={allPlans[activeTabKey].categories}
            />
        ]);
    };

    const planModifyCallback = async () => {
        const plans = (await firebase.db.ref('plans').once('value')).val();
        setAllPlans(plans);
        configurePlanData(plans[activeTabKey], activeTabKey);
    }

    const handlePlanActiveChange = async (e) => {
        e.persist();
        const planId = e.target.dataset.planid;
        const plantype = e.target.dataset.plantype;
        if (planId) {
            setActivePlanCheck(values => ({ ...values, [planId]: e.target.checked }));
            await firebase.db.ref(`plans/${plantype}/${planId}/active`).set(e.target.checked);
        }
        else {
            setActivePlanCheck(values => ({ ...values, [plantype]: e.target.checked }));
            await firebase.db.ref(`plans/${plantype}/active`).set(e.target.checked);
        }
    }

    const handleFlipCodeInput = (e) => {
        e.persist();
        const plantype = e.target.dataset.plantype;
        setFlipdCode(values => ({ ...values, [plantype]: e.target.value }));
    }

    const updateFlipdCode = async (e) => {
        e.persist();
        const code = document.getElementById('flipdCode').value;
        const plantype = e.target.dataset.plantype;
        await firebase.db.ref(`discount/flipd/${plantype}`).set(code);
        showToastAlert('Success', 'Updated');
    }

    const changeActiveTab = async (e) => {
        const planType = e.target.getAttribute('data-key');
        setActiveTabKey(planType);
        configurePlanData(allPlans[planType], planType);
    }

    const configurePlanData = (plans, planType) => {
        if (plans) {
            let arr = [], activeState = {}, hideState = {};
            const categories = plans.categories;
            activeState[planType] = plans.active;
            Object.keys(plans).forEach(function (key) {
                if (key === 'hide' || key === 'active' || key === 'categories') return;
                let subPlans = plans[key]['quantity'] || (plans[key]['subPlans'] || {});
                activeState[key] = plans[key].active || false;
                hideState[key] = plans[key].hide || false;
                let dict = { ...plans[key], 'planId': key, 'planType': planType }
                if (plans[key].subPlans) {
                    subPlans = Object.keys(subPlans).map(subKey => ({ 'catName': categories[subPlans[subKey].category]['name'], 'planId': key, 'planType': planType, 'subplanId': subKey, ...subPlans[subKey] }));
                    delete dict['subPlans'];
                    dict['subPlans'] = subPlans;
                }
                else if (plans[key].quantity) {
                    subPlans = Object.keys(subPlans).map(subKey => ({ 'planId': key, 'planType': planType, 'subplanId': subKey, 'qty': subKey, ...subPlans[subKey] }));
                    delete dict['quantity'];
                    dict['quantity'] = subPlans;
                }
                arr.push(dict);
            })
            setActivePlanCheck(activeState);
            setHidePlanCheck(hideState);
            setResiPlans(arr);
        }
    }

    const addNewPlan = (e) => {
        setAddPlanModals([]);
        setAddPlanModals([
            <AddPlanModal
                showModal={true}
                key={Math.random()}
                firebase={firebase}
                successCallback={planModifyCallback}
                planType={activeTabKey}
                planData={allPlans[activeTabKey]}
                isUpdate={false}
                serverProducts={serverProducts}
                categories={allPlans[activeTabKey].categories}
            />
        ]);
    }

    useEffect(() => {
        (async function () {
            firebase.auth.onAuthStateChanged(async (user) => {
                try {
                    if (!user) {
                        history.push('/login');
                        return;
                    }
                    const idToken = await firebase.auth.currentUser.getIdToken(true);

                    const flipdDiscount = (await firebase.db.ref('discount/flipd').once('value')).val() || {};
                    setFlipdCode(flipdDiscount);

                    const products = await getServerProducts(idToken);
                    if (products.data && products.data.data) setServerProducts(products.data.data);

                    const plans = (await firebase.db.ref('plans').once('value')).val();
                    setAllPlans(plans);
                    configurePlanData(plans[activeTabKey], activeTabKey);
                    setLoading(false);

                } catch (error) {
                    console.log(error);
                    showToastAlert('Error', error.message);
                    setLoading(false);
                }
            });
        })();
    }, [history, firebase.auth]);

    return (
        <>
            {loading && <PageLoader />}
            <div id="page-content-wrapper" className="extra-p">
                <Navbar toggleSideMenu={props.toggleSideMenu} />

                <div className="container mb-2">
                    <div className="row">
                        <div className="col-12 dash-head my-0">
                            <div className='plan-nav'>
                                <ul className="nav nav-pills mt-0 mb-5 px-0" id="pills-tab" role="tablist">
                                    {
                                        allPlans && Object.keys(allPlans).length > 0 && Object.keys(planNames).map(key => {
                                            return (
                                                <li className="nav-item" role="presentation" key={key}>
                                                    <button key={key + '-btn'} className={`nav-link px-4 ${activeTabKey === key ? 'active' : ''}`} onClick={changeActiveTab} data-key={key} id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">{planNames[key]}</button>
                                                </li>
                                            )
                                        })
                                    }
                                </ul>
                            </div>
                        </div>
                        <div className="col-12 mt-0 mb-2">
                            <div className="dash-box" id='flipdRow' style={{ 'paddingBottom': '10px' }}>
                                <div className="d-flex" style={{ 'gap': '20px', 'alignItems': 'center', 'justifyContent': 'space-between', flexWrap: 'wrap-reverse' }}>
                                    <div className="d-flex" style={{ 'gap': '20px', 'alignItems': 'center' }}>
                                        <div className="number-proxy">
                                            <div className="number-proxy-box"> <input id='flipdCode' style={{ 'border': '0', 'backgroundColor': 'transparent', 'textAlign': 'center' }} type="text" data-plantype={activeTabKey} onChange={handleFlipCodeInput} placeholder='Flipd Discount Code' value={flipdCode[activeTabKey] || ''} /></div>
                                        </div>
                                        <div className="form-cta-btn">
                                            <a href="#" className="px-4" data-plantype={activeTabKey} onClick={updateFlipdCode}>Update Flipd Code</a>
                                        </div>
                                    </div>
                                    <div className="d-flex" style={{ 'gap': '20px', 'alignItems': 'center', 'minWidth': 'fit-content' }}>
                                        <div className="togglebutton" style={{ 'minWidth': 'fit-content', 'marginTop': '5px' }}>
                                            <label>
                                                <input type="checkbox" data-plantype={activeTabKey} onChange={handlePlanActiveChange} checked={activePlanCheck[activeTabKey] || false} />
                                                <span className="toggle"></span> Active {planNames[activeTabKey]}
                                            </label>
                                        </div>
                                        <div className="form-cta-btn" style={{ 'minWidth': 'fit-content' }}>
                                            <a href="#" className="px-4" data-plantype={activeTabKey} onClick={addNewPlan}>Add Plan</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {
                            resiPlans.map(plan => {
                                return (
                                    <div className='col-12 mb-2' key={plan.planId}>
                                        <div className="dash-box">
                                            <div className="row" style={{ 'paddingTop': '30px' }}>
                                                <div className="col-12 dash-head mt-0 mb-2">
                                                    <div className='d-flex' style={{ 'gap': '20px' }}>
                                                        <h4 className="my-0" style={{ 'color': 'var(--main-blue)' }}>{`${plan.planId} - ${plan.name} ${plan.servername ? (' , Server Name - ' + plan.servername) : ''}`}</h4>
                                                        <div className='d-flex' style={{ 'gap': '8px' }}>
                                                            <button className='icon-btn add' onClick={e => handleUpdatePlanDetailClick(plan.planId)}><EditIcon /></button>
                                                            <button className='icon-btn delete' onClick={e => deletePlanClick(plan.planId, plan.planType)}><DeleteIcon /></button>
                                                        </div>
                                                    </div>
                                                    {
                                                        plan.planType !== 'server' &&
                                                        <div className="form-cta-btn" style={{ 'minWidth': 'fit-content' }}>
                                                            <a href="#" className="px-4" data-plantype={activeTabKey} onClick={e => handleAddSubPlanClick(plan.planId)}>Add Subplan</a>
                                                        </div>
                                                    }
                                                    {/* <div className='d-flex right-switch' style={{ 'gap': '25px' }}>
                                                        <div className="togglebutton">
                                                            <label>
                                                                <input type="checkbox" data-planid={plan.planId} data-plantype={activeTabKey} onChange={handlePlanActiveChange} checked={activePlanCheck[plan.planId]} />
                                                                <span className="toggle"></span> Active
                                                            </label>
                                                        </div>
                                                        <div className="togglebutton">
                                                            <label>
                                                                <input type="checkbox" data-planid={plan.planId} data-plantype={activeTabKey} onChange={handlePlanHiddenChange} checked={hidePlanCheck[plan.planId]} />
                                                                <span className="toggle"></span> Hidden
                                                            </label>
                                                        </div>
                                                    </div> */}
                                                </div>
                                                {
                                                    plan.planType !== 'server' &&
                                                    <div className="col-12 mt-3">
                                                        <div className="table-responsive">
                                                            {
                                                                (plan.planType === 'gmail' || plan.planType === 'nike') &&
                                                                <DataTable columns={gmailColumns(handleUpdateClick, handleDeleteClick)} data={plan.quantity} />
                                                            }
                                                            {
                                                                plan.planType !== 'gmail' && plan.planType !== 'nike' &&
                                                                <DataTable columns={columns(handleUpdateClick, handleDeleteClick, activeTabKey)} data={plan.subPlans} />
                                                            }
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>

            {addPlanModals.map(modal => (modal))}
            {addSubPlanModals.map(modal => (modal))}
        </>
    )
}

export default Plans;