import React from "react";
import DataTable from "react-data-table-component";

const ReportTable = ({ title, col, data }) => {
  return (
    <div className={col === "auto" ? "col-4 mx-auto" : "col-4 mt-3"}>
        <div className="dash-box position-relative mt-2">
            <div className="row" style={{ paddingTop: "30px" }}>
                <div className="col-12 dash-head my-0">
                    <div className="d-flex" style={{ gap: "20px" }}>
                        <h4 className="my-0" style={{ color: "var(--main-blue)" }}>
                            {title}
                        </h4>
                    </div>
                </div>
                <div className="table-responsive">
                    <DataTable
                        columns={[
                            {
                                name: "Description",
                                selector: (row) => row.description,
                            },
                            {
                                name: "Count",
                                selector: (row) => row.count,
                            },
                            {
                                name: "Total",
                                selector: (row) => `$${row.total}`,
                            },
                        ]}
                        data={data}
                        persistTableHead
                        progressPending={false}
                    />
                </div>
            </div>
        </div>
    </div>
  );
};

export default ReportTable;
