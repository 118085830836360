import React, { useState, useEffect, useContext } from 'react';
import * as request from 'request';
import { useHistory } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import { FirebaseContext } from 'contexts/Firebase';
import { PageLoader, showToastAlert } from 'Services/Utility';
import Navbar from 'components/Navbar';

const columns = [
    {
        name: 'Name',
        selector: row => row.displayName || '-',
        sortable: true,
    },
    {
        name: 'Email',
        selector: row => row.email || '-',
        sortable: true,
    },
    {
        name: 'User Id',
        selector: row => row.uid,
        sortable: true,
    },
    {
        name: 'Discord Id',
        selector: row => row.discordId || '-',
        sortable: true,
    },
];

const Users = (props) => {
    const { user, firebase } = useContext(FirebaseContext);
    const history = useHistory();
    const [filterText, setFilterText] = useState('');
    const [users, setUsers] = useState([]);
    const [pending, setPending] = useState(true);
    const [loading, setLoading] = useState(true);

    const filteredItems = users.filter(
        item => (item.displayName && item.displayName.toLowerCase().includes(filterText.toLowerCase())) || (item.email && item.email.toLowerCase().includes(filterText.toLowerCase())) || item.uid.includes(filterText) || `${item.discordId}`.includes(filterText)
    );

    useEffect(() => {
        document.body.classList.add('dash-bg');
        document.body.classList.add('plan-price-bg');
    }, []);

    const getUsers = (idToken) => {
        return new Promise((resolve, reject) => {
            let options = {
                method: 'POST',
                url: `${process.env.REACT_APP_API_URL}/users/getUsers`,
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    'idToken': idToken
                })
            };

            return request(options, (error, response, body) => {
                let resData = body && JSON.parse(body);
                if (error || response.statusCode !== 200) {
                    let errorMessage = error ? error : resData ? resData.message : 'A server error occurred while fetching server!';
                    showToastAlert('Error', errorMessage);
                    resolve([]);
                } else {
                    return resolve(resData.data || []);
                }
            });
        });
    }

    const refreshList = async () => {
        const idToken = await firebase.auth.currentUser.getIdToken(true);
        const users = await getUsers(idToken);
        setUsers(users || []);
    }

    useEffect(() => {
        (async function () {
            firebase.auth.onAuthStateChanged(async (user) => {
                try {
                    if (!user) {
                        history.push('/login');
                        return;
                    }
                    setLoading(false);
                    const idToken = await firebase.auth.currentUser.getIdToken(true);
                    const users = await getUsers(idToken);
                    setUsers(users || []);
                    setPending(false);
                } catch (error) {
                    console.log(error);
                    showToastAlert('Error', error.message);
                    setPending(false);
                    setLoading(false);
                }
            });
        })();
    }, [history, firebase.auth]);

    return (
        <>
            {loading && <PageLoader />}
            <div id="page-content-wrapper" className="extra-p">
                <Navbar toggleSideMenu={props.toggleSideMenu} refreshList={refreshList} />

                <div className="container">
                    <div className="row">
                        <div className="col-12 dash-head my-0">
                            <h3 className="my-0">Users</h3>
                            <div className="search-box"> <input className="search" type="text" placeholder='Search User...' value={filterText} onChange={e => setFilterText(e.target.value)} /><img src={require("assets/images/search.svg")} alt="" /></div>
                        </div>
                        <div className="col-12 mt-3">
                            <div className="dash-box position-relative mt-2">
                                <div className="table-responsive">
                                    {/* <DataTable columns={columns} data={filteredItems} persistTableHead pagination paginationRowsPerPageOptions={[10, 20, 50]} paginationComponentOptions={{ rowsPerPageText: 'Show entries', selectAllRowsItem: true }} progressPending={pending} /> */}
                                    <DataTable columns={columns} data={filteredItems} persistTableHead pagination paginationRowsPerPageOptions={[10, 20, 50, 100]} paginationComponentOptions={{ rowsPerPageText: 'Show entries' }} progressPending={pending} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}

export default Users;